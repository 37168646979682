import { DEIDENTIFICATION_FIELD_MAPPINGS_ORDERED, numberToOrdinal } from './constants';

export { deidentifySensitiveInfo };

// const { SyncRedactor } = require('redact-pii');

function getAllCombinationsOrderedByLength(str) {
    const words = str.split(' '); // Split the string into words
    let results = [];

    // Generate all contiguous combinations of words
    for (let start = 0; start < words.length; start++) {
        for (let end = start + 1; end <= words.length; end++) {
            // Create a substring from the start index to the end index
            const combination = words.slice(start, end).join(' ');
            results.push(combination);
        }
    }

    // Sort results by length, from longest to shortest
    results.sort((a, b) => b.length - a.length || a.localeCompare(b));

    return results;
};

// further formatting of data before deidentification
function formatData(data) {
  const clientFirstName = data["Client Personal Information"]['Client First Name']
  const clientLastName = data["Client Personal Information"]['Client Last Name']

  data["Client Personal Information"]["Client Full Name"] = clientFirstName + " " + clientLastName

  if (data["Family Information"]) {
    const motherFirstName = data["Family Information"]["Client Mother First Name"]
    const motherLastName = data["Family Information"]["Client Mother Last Name"]
    if (motherFirstName && motherLastName){
      data["Family Information"]["Client Mother Full Name"] = motherFirstName + " " + motherLastName
    }
    else if (motherFirstName) {
      data["Family Information"]["Client Mother Full Name"] = motherFirstName
    }
    else {
      data["Family Information"]["Client Mother Full Name"] = motherLastName || ''
    }

    const fatherFirstName = data["Family Information"]["Client Father First Name"]
    const fatherLastName = data["Family Information"]["Client Father Last Name"]

    if (fatherFirstName && fatherLastName){
      data["Family Information"]["Client Father Full Name"] = fatherFirstName + " " + fatherLastName
    }
    else if (fatherFirstName) {
      data["Family Information"]["Client Father Full Name"] = fatherFirstName
    }
    else {
      data["Family Information"]["Client Father Full Name"] = fatherLastName || ''
    }
  }

  if (data["Teachers Referenced"]){
    const teacherFirstName = data["Teachers Referenced"]["Client Teacher First Name"]
    const teacherLastName = data["Teachers Referenced"]["Client Teacher Last Name"]
    if (teacherFirstName && teacherLastName){
      data["Teachers Referenced"]["Client Teacher Full Name"] = teacherFirstName + " " + teacherLastName
    }
    else if (teacherFirstName){
      data["Teachers Referenced"]["Client Teacher Full Name"] = teacherFirstName
    }
    else {
      data["Teachers Referenced"]["Client Teacher Full Name"] = teacherLastName || ''
    }
  }

  if (data["Support Practitioners (Dr.s, psychologists, tutors, etc.)"]){
    const practFirstName = data["Support Practitioners (Dr.s, psychologists, tutors, etc.)"]["Client Practitioner First Name"]
    const practLastName = data["Support Practitioners (Dr.s, psychologists, tutors, etc.)"]["Client Practitioner Last Name"]
    if (practFirstName && practLastName){
      data["Support Practitioners (Dr.s, psychologists, tutors, etc.)"]["Client Practitioner Full Name"] = practFirstName + " " + practLastName
    }
    else if (practFirstName){
      data["Support Practitioners (Dr.s, psychologists, tutors, etc.)"]["Client Practitioner Full Name"] = practFirstName
    }
    else {
      data["Support Practitioners (Dr.s, psychologists, tutors, etc.)"]["Client Practitioner Full Name"] = practLastName || ''
    }
  }

  if (data["Additional Family Member"]) {
    data["Additional Family Member"]['fullNameValues'] = []
    const count = data["Additional Family Member"].count
    const firstNames = data["Additional Family Member"].firstNameValues
    const lastNames = data["Additional Family Member"].lastNameValues
    for (let i = 0; i < count; i++) {
      let fullName;
      const firstName = firstNames[i]
      const lastName = lastNames[i]

      if (!firstName && !lastName){
        fullName = ''
      }
      else if (firstName && lastName){
        fullName = firstName + " " + lastName
      }
      else if (firstName) {
        fullName = firstName
      }
      else {
        fullName = lastName
      }
      data["Additional Family Member"]['fullNameValues'].push(fullName)
    }
  }

  if (data["Additional Teacher"]) {
    data["Additional Teacher"]['fullNameValues'] = []
    const count = data["Additional Teacher"].count
    const firstNames = data["Additional Teacher"].firstNameValues
    const lastNames = data["Additional Teacher"].lastNameValues
    for (let i = 0; i < count; i++) {
      let fullName;
      const firstName = firstNames[i]
      const lastName = lastNames[i]
      if (!firstName && !lastName){
        fullName = ''
      }
      else if (firstName && lastName){
        fullName = firstName + " " + lastName
      }
      else if (firstName) {
        fullName = firstName
      }
      else {
        fullName = lastName
      }
      data["Additional Teacher"]['fullNameValues'].push(fullName)
    }
  }

  if (data["Additional Practitioner"]) {
    data["Additional Practitioner"]['fullNameValues'] = []
    const count = data["Additional Teacher"].count
    const firstNames = data["Additional Practitioner"].firstNameValues
    const lastNames = data["Additional Practitioner"].lastNameValues

    for (let i = 0; i < count; i++) {
      let fullName;
      const firstName = firstNames[i]
      const lastName = lastNames[i]
      if (!firstName && !lastName){
        fullName = ''
      }
      else if (firstName && lastName){
        fullName = firstName + " " + lastName
      }
      else if (firstName) {
        fullName = firstName
      }
      else {
        fullName = lastName
      }
      data["Additional Practitioner"]['fullNameValues'].push(fullName)
    }
  }
  return data;
};

function deidentifySensitiveInfo(data, fieldType, noteField=null) {
  let deidentifiedText

  if (noteField) {
    deidentifiedText = data[noteField][fieldType]
  }
  else {
    deidentifiedText = data[fieldType]
  }

  data = formatData(data.phiFields)

  // Function to replace sensitive info with labels
  const replaceSensitiveInfo = (phi, label) => {
    var phiString = phi
    if (phiString.includes(" ") === false){
      const regex = new RegExp(`\\b${phi}\\b`, 'gi');
      deidentifiedText = deidentifiedText.replace(regex, label);

      const trailingSRegex = new RegExp(`\\b${phi}s\\b`, 'gi');
      deidentifiedText = deidentifiedText.replace(trailingSRegex, label+"s");
    }
    else {
      const regex = new RegExp(phiString, 'gi'); // 'gi' for global and case-insensitive match
      deidentifiedText = deidentifiedText.replace(regex, label);
    }
  };

  const dob = data["Client Personal Information"]["Client Date of Birth"];

  if (dob) {
    const [year, month, day] = dob.split("-");
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const monthAbbreviations = monthNames.map(name => name.substr(0, 3));

    const dobVariations = [
      `${parseInt(month)}/${parseInt(day)}/${year}`, // 'm/d/yyyy'
      `${month}/${day}/${year}`, // 'mm/dd/yyyy'
      `${monthNames[parseInt(month) - 1]} ${day}, ${year}`, // 'MonthName dd, yyyy'
      `${monthNames[parseInt(month) - 1]} ${parseInt(day)}, ${year}`, // 'MonthName dd, yyyy'
      `${monthAbbreviations[parseInt(month) - 1]} ${day}, ${year}`, // 'Mon dd, yyyy'
      `${parseInt(month)} ${parseInt(day)} ${year}`,
      `${monthNames[parseInt(month) - 1]} ${parseInt(day)} ${year}`

    ];

    // Remove day and month, keep the year in the text
    dobVariations.forEach(variation => {
      const regex = new RegExp(variation.replace(year, '\\d{4}'), 'gi');
      deidentifiedText = deidentifiedText.replace(regex, year);
    });
    // delete data["Client Personal Information"]["Client Date of Birth"]
  }

  // Replace Client Personal Information
  Object.entries(DEIDENTIFICATION_FIELD_MAPPINGS_ORDERED['Client Personal Information']).forEach(([key, value]) => {
    if (data["Client Personal Information"][key]){
      replaceSensitiveInfo(data["Client Personal Information"][key], value); // key is like "Client Mother First Name"
    }
  });

  // Replace Family Information
  Object.entries(DEIDENTIFICATION_FIELD_MAPPINGS_ORDERED['Family Information']).forEach(([key, value]) => {
    if (data["Family Information"][key]) {
      replaceSensitiveInfo(data["Family Information"][key], value); // key is like "Client Mother First Name"
    }
  });

  // Replace Schools Attended
  if (data["Schools Attended"]){
    // Array of spelled out numbers (extend as needed)
    const spelledOutNumbers = ["one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten"]; // Extend this list as needed

    // Array of US state names
    const usStates = ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"];

    let schoolsAttended = data["Schools Attended"];
    if (data["Additional School"].schoolNames.length > 0) {
      data["Additional School"].schoolNames.forEach((schoolName, index) => {
        var ordinal = numberToOrdinal(data["Additional School"].initialCount + index + 1);
        ordinal += " School"
        schoolsAttended[ordinal] = schoolName;
      });
    }

    Object.entries(schoolsAttended).forEach(([key, value]) => {
      // if value is blank or empty space, don't replace
      if (value && value.trim()) {
        let label = '';

        if (key in DEIDENTIFICATION_FIELD_MAPPINGS_ORDERED['Schools Attended']) {
          label = DEIDENTIFICATION_FIELD_MAPPINGS_ORDERED['Schools Attended'][key];
        } else {
          label = "[Client " + key + "]";
        }

        // Generate potential variations of the school name
        const variations = getAllCombinationsOrderedByLength(value);

        // Replace each variation in the text
        variations.forEach(variation => {
          const words = variation.split(' ');
          const containsNoExemptWords = words.every(word => {
            const wordLower = word.toLowerCase();
            // Check if word is a number (spelled out or digit) or a US state name
            return !spelledOutNumbers.includes(wordLower) && !usStates.includes(word) && isNaN(parseInt(word));
          });

          if (containsNoExemptWords) {
            if (variation === value || variation.length > 3) {
              if (!['School', 'school',
                    'High', 'high',
                    'Middle', 'middle',
                    'Elementary', 'elementary',
                    'High School', 'high school',
                    'Middle School', 'middle school',
                    'Elementary School', 'elementary school',
                    'Preschool', 'preschool'].includes(variation)) {
                      const regex = new RegExp(`\\b${variation}\\b`, 'gi');
                      deidentifiedText = deidentifiedText.replace(regex, label);
              }
            }
          }
        });
      }
    });
  };

  // Replace Teachers Referenced
  Object.entries(DEIDENTIFICATION_FIELD_MAPPINGS_ORDERED['Teachers Referenced']).forEach(([key, value]) => {
    if (data["Teachers Referenced"][key]){
      replaceSensitiveInfo(data["Teachers Referenced"][key], value); // key is like "Client Mother First Name"
    }
  });

  // Replace Support Practitioners
  Object.entries(DEIDENTIFICATION_FIELD_MAPPINGS_ORDERED["Support Practitioners (Dr.s, psychologists, tutors, etc.)"]).forEach(([key, value]) => {
    if (data["Support Practitioners (Dr.s, psychologists, tutors, etc.)"][key]) {
      replaceSensitiveInfo(data["Support Practitioners (Dr.s, psychologists, tutors, etc.)"][key], value); // key is like "Client Mother First Name"
    }
  });

  // Additional replacements for dynamically added fields such as additional family members, teachers, or practitioners
  if (data["Additional Family Member"]) {
      for (let i = 0; i < data["Additional Family Member"].fullNameValues.length; i++) {
        if (data["Additional Family Member"].fullNameValues[i]){
          replaceSensitiveInfo(data["Additional Family Member"].fullNameValues[i], "[Additional Family Member " + (i+1).toString() + "]");
        }
      }
      for (let i = 0; i < data["Additional Family Member"].firstNameValues.length; i++) {
        if (data["Additional Family Member"].firstNameValues[i]){
          replaceSensitiveInfo(data["Additional Family Member"].firstNameValues[i], "[Additional Family Member " + (i+1).toString() + "]");
        }
      }
      for (let i = 0; i < data["Additional Family Member"].lastNameValues.length; i++) {
        if (data["Additional Family Member"].lastNameValues[i]){
          replaceSensitiveInfo(data["Additional Family Member"].lastNameValues[i], "[Additional Family Member " + (i+1).toString() + "]");
        }
      }
  }

  if (data["Additional Teacher"]) {
    for (let i = 0; i < data["Additional Teacher"].fullNameValues.length; i++) {
      if (data["Additional Teacher"].fullNameValues[i]){
        replaceSensitiveInfo(data["Additional Teacher"].fullNameValues[i], "[Client Teacher " + (i+2).toString() + "]");
      }
    }
    for (let i = 0; i < data["Additional Teacher"].lastNameValues.length; i++) {
      if (data["Additional Teacher"].lastNameValues[i]){
        replaceSensitiveInfo(data["Additional Teacher"].lastNameValues[i], "[Client Teacher " + (i+2).toString() + "]");
      }
    }
    for (let i = 0; i < data["Additional Teacher"].firstNameValues.length; i++) {
      if (data["Additional Teacher"].firstNameValues[i]){
        replaceSensitiveInfo(data["Additional Teacher"].firstNameValues[i], "[Client Teacher " + (i+2).toString() + "]");
      }
    }
  }

  if (data["Additional Practitioner"]) {
    for (let i = 0; i < data["Additional Practitioner"].fullNameValues.length; i++) {
      if(data["Additional Practitioner"].fullNameValues[i]){
        replaceSensitiveInfo(data["Additional Practitioner"].fullNameValues[i], "[Client Practitioner " + (i+2).toString() + "]");
      }
    }
    for (let i = 0; i < data["Additional Practitioner"].lastNameValues.length; i++) {
      if (data["Additional Practitioner"].lastNameValues[i]){
        replaceSensitiveInfo(data["Additional Practitioner"].lastNameValues[i], "[Client Practitioner " + (i+2).toString() + "]");
      }
    }
    for (let i = 0; i < data["Additional Practitioner"].firstNameValues.length; i++) {
      if(data["Additional Practitioner"].firstNameValues[i]){
        replaceSensitiveInfo(data["Additional Practitioner"].firstNameValues[i], "[Client Practitioner " + (i+2).toString() + "]");
      }
    }
  }

  // Regular expressions for emails and phone numbers
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
  const phoneRegex = /\(\d{3}\)\d{7}|\(\d{3}\)\s?\d{3}[-.\s]?\d{4}|\b\d{10}\b/g;

  // Replace emails and phone numbers with placeholders
  deidentifiedText = deidentifiedText.replace(emailRegex, '[Email]');
  deidentifiedText = deidentifiedText.replace(phoneRegex, '[Phone Number]');

  // const redactor = new SyncRedactor({
  //   builtInRedactors: {
  //     names: {
  //       replaceWith: '[Person]'
  //     },
  //     emailAddress: {
  //       replaceWith: '[Email Address]'
  //     },
  //     phoneNumber: {
  //       replaceWith: '[Phone Number]'
  //     },
  //     streetAddress: {
  //       replaceWith: '[Client Street Address]'
  //     },
  //     zipcode: {
  //       replaceWith: '[Client Zip Code]'
  //     }
  //   }
  // });
  //
  // deidentifiedText = redactor.redact(deidentifiedText);

  return deidentifiedText
};
