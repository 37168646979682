import React, { Component } from "react";

import { Paper, Table, CircularProgress, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Typography } from '@mui/material';

import { connect } from "react-redux";

import withNavigateHook from '../../shared/withNavigateHook';

import { appendixData, reportDownload, evaluationList, reportSection } from '../../actions';

import Layout from '../../shared/Layout'

import { createAppendix } from './formatAppendix'

import { createReport } from './formatReport'


const styles = ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    textAlign: 'center',
    overflow: 'hidden',
    marginBottom: '36px'
  },
  paper: {
    marginTop: 32,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px 24px 24px',
    width: '400px',
    marginLeft:'auto',
    marginRight:'auto'
  },
  headerText: {
    textAlign: 'center',
    marginLeft: '25%',
    marginRight: '25%',
    marginTop: '20px',
    marginBottom: '24px',
    fontFamily:'Comfortaa',
    fontSize: 48,
    fontWeight: 200
  },
  progressWrapper: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1400,
  },
  progress: {
    color: 'white',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1300,
  },
});



class ReportDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      readyToDownloadRepId: null, // This will track which report's appendix data is ready for download
      readyToDownloadAppId: null,
      dataLoading: false
    };
    this.handleDownloadAppendixClick = this.handleDownloadAppendixClick.bind(this);
    this.handleDownloadReportClick = this.handleDownloadReportClick.bind(this);
  }

  componentDidMount() {
    this.props.fetchEvaluationList()
    this.props.fetchSubtestsByReportSection()
  }

  componentDidUpdate(prevProps, prevState) {
    const { appendixData, appendixLoaded, reportData, reportLoaded } = this.props;
    const { readyToDownloadAppId, readyToDownloadRepId} = this.state;

    if (this.state.dataLoading===true){
      if (!prevProps.reportLoaded && reportLoaded){
        createReport(readyToDownloadRepId, reportData, this.props.subtestsBySection);
        this.setState({ readyToDownloadAppId: null, dataLoading:false }); // Reset after processing
      }
      if (!prevProps.appendixLoaded && appendixLoaded){
        createAppendix(readyToDownloadAppId, appendixData.assessments, appendixData.ratingScales, this.props.subtestsBySection);
        this.setState({ dataLoading:false })
      }
    }
  }

  handleDownloadAppendixClick = (evalId) => {
    this.props.fetchAppendixData(evalId); // Fetch the appendix data
    this.setState({ readyToDownloadAppId: evalId, dataLoading:true }); // Set the ID indicating readiness
  };

  handleDownloadReportClick = (evalId) => {
    this.props.fetchReportDownloadData(evalId); // Fetch report sections
    this.setState({ readyToDownloadRepId: evalId, dataLoading:true}); // Set the ID indicating readiness
  };

  render() {
    const { user, evalListLoading, evalList} = this.props
    if ((evalListLoading!==false) || (!user.isLoaded)){
      return (
        <Layout>
          <div style={{ padding: '40px', textAlign:'left'}}>
            <Typography sx={styles.subHeaderText}>
              Loading...
            </Typography>
          </div>
        </Layout>
      )
    }

    return (
      <Layout>
        {this.state.dataLoading && (
          <>
            <div style={styles.overlay}></div>
            <div style={styles.progressWrapper}>
              <CircularProgress style={styles.progress} size={100} />
            </div>
          </>
        )}
        <div style={styles.root}>
          <Typography sx={styles.headerText}>
            Report Dashboard
          </Typography>
          <TableContainer component={Paper} sx={{marginLeft:'5%', marginRight:'5%'}}>
            <Table aria-label="report table">
              <TableHead>
                <TableRow>
                  <TableCell>Report ID</TableCell>
                  <TableCell>Date Created</TableCell>
                  {(this.props.user.user.is_superuser) === true &&
                    <TableCell>Created By</TableCell>
                  }
                  {(this.props.user.user.is_superuser) === true &&
                    <TableCell>Organization</TableCell>
                  }
                  <TableCell>Download Report</TableCell>
                  <TableCell>Download Appendix</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {evalListLoading === false && evalList
                .sort((a, b) => a.id - b.id) // Sort evalList by evaluation.id in ascending order
                .map(evaluation => (
                  <TableRow key={evaluation.id}>
                    <TableCell>{evaluation.id}</TableCell>
                    <TableCell>{evaluation.date}</TableCell>
                    {(this.props.user.user.is_superuser) === true &&
                      <TableCell>{evaluation.created_by}</TableCell>
                    }
                    {(this.props.user.user.is_superuser) === true &&
                      <TableCell>{evaluation.org_name}</TableCell>
                    }
                    <TableCell>
                      <Button
                        variant="contained"
                        onClick={() => this.handleDownloadReportClick(evaluation.id)}
                      >
                      Download Report
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        onClick={() => this.handleDownloadAppendixClick(evaluation.id)}
                      >
                      Download Appendix
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Layout>
    );
  }
}


const mapStateToProps = state => {
  return {
    evalList: state.evaluationList.evals.evalList,
    evalListLoading: state.evaluationList.loading,
    appendixData: state.appendixData.appendix,
    appendixLoaded: state.appendixData.appendixLoaded,
    subtestsBySection: state.reportSection.reportSections,
    subtestsBySectionLoading: state.reportSection.loading,
    reportData: state.reportDownload.report,
    reportLoaded: state.reportDownload.reportLoaded,
    user: state.user
  };
}

const mapDispatchToProps = dispatch => {
  return {
    fetchEvaluationList: () => dispatch(evaluationList.fetchEvaluationList()),
    fetchAppendixData: (evalId) =>  dispatch(appendixData.fetchAppendixData(evalId)),
    fetchReportDownloadData: (evalId) => dispatch(reportDownload.fetchReportDownloadData(evalId)),
    fetchSubtestsByReportSection: () => dispatch(reportSection.fetchSubtestsByReportSection()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withNavigateHook(ReportDashboard));
