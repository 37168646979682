import React, { Component } from "react";
import { connect } from "react-redux";

import withNavigateHook from '../../shared/withNavigateHook';

import { reportUpload } from '../../actions';
import Layout from '../../shared/Layout'

import { PHI_FIELDS, REQUIRED_PHI_FIELDS, DATE_PHI_FIELDS } from '../../shared/constants'

import { numberToOrdinal } from '../../shared/constants'

import { deidentifySensitiveInfo } from '../../shared/deidentifier'

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';

import Add from "@mui/icons-material/Add";

const styles = ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    textAlign: 'center',
    overflow: 'hidden',
    marginBottom: '36px'
  },
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  paper: {
    marginTop: 32,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px 24px 24px',
    width: '400px',
    marginLeft:'auto',
    marginRight:'auto'
  },
  headerText: {
    textAlign: 'center',
    marginLeft: '25%',
    marginRight: '25%',
    marginTop: '20px',
    marginBottom: '24px',
    fontFamily:'Comfortaa',
    fontSize: 48,
    fontWeight: 200
  },
  formHeaderText: {
    fontFamily:'Comfortaa',
    fontSize: 16,
  },
  phiForms:{
    marginBottom: '1%',
    marginLeft: '20%',
    marginRight: '20%',
    width: '60%',
    '& .MuiTextField-root': { m:1, width: '50ch' }
  },
  logoutButton: {
    marginTop: '20px',
    marginLeft: '75%',
    background:'#E74C3C',
  },
  startButton: {
    background:'#E74C3C',
  },
  buttonText: {
    fontFamily:'Comfortaa',
      fontSize: 18,
      fontWeight: 400
  },
  startButtonDiv: {
    marginBottom: '18px',
  },
  reportTextFieldDiv: {
    marginBottom: '24px',
    // marginLeft: '10%',
    // marginRight: '10%',
  },
  reviewReminderText: {
    marginTop: '24px',
    marginBottom: '24px',
    fontFamily:'Comfortaa',
    fontSize: 16,
    fontStyle: 'italic'
  },
  toolTipText: {
    fontFamily:'Comfortaa',
    fontSize: 16,
    fontStyle: 'italic'
  },
  redirectText: {
    fontFamily:'Comfortaa',
    fontSize: 20,
    marginBottom: '30px'
  },
  redirectButton: {
    marginTop: 8,
    background:'#E74C3C',
    textTransform:'none'
  },
});


class ReportUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Objects to store values of other text fields
      'Client Personal Information': {},
      'Family Information': {},
      'Schools Attended': {},
      'Teachers Referenced': {},
      'Support Practitioners (Dr.s, psychologists, tutors, etc.)': {},

      // State to store values of dynamically generated text fields
      'Additional School': {'count': 5, 'initialCount':5, 'schoolNames':[]},
      'Additional Family Member': {'count': 0, 'firstNameValues':[], 'lastNameValues':[]},
      'Additional Teacher': {'count': 0, 'firstNameValues':[], 'lastNameValues':[]},
      'Additional Practitioner': {'count': 0, 'firstNameValues':[], 'lastNameValues':[]},

      reportText: "",
      evaluationNoteText: "",
      editing: true,
      shouldSaveFormData: true
    };
    this.handleAddFieldButtonClick = this.handleAddFieldButtonClick.bind(this);
  }

  componentDidMount() {
    const savedFormData = localStorage.getItem('reportUploadData');
    if (savedFormData) {
      this.setState(JSON.parse(savedFormData));
    }
  }

  componentDidUpdate() {
    if (this.state.shouldSaveFormData) {
      localStorage.setItem('reportUploadData', JSON.stringify(this.state));
    }
  }

  onLogout = e => {
    e.preventDefault();
    this.setState({ shouldSaveFormData: false }, () => {
      localStorage.removeItem('reportUploadData');
      this.props.logout();
    });
  };

  getPhiFieldType = (fieldName) => {
    if (DATE_PHI_FIELDS.includes(fieldName)){
      return "date"
    }
    return "text"
  };

  handleAddFieldButtonClick = (phiType) => {
    this.setState((prevState) => {
      var updatedPhi = {...prevState[phiType]}
      updatedPhi.count += 1
      return {
        [phiType]: updatedPhi
      };
    }, () => {
    });
  };

  handleEditButtonClick = (editing) => {
    this.setState(prevState => {
      return {'editing': editing}
    });
  };

  generateFields = (fieldType) => {
    let listOfFields = [];
    for (let i = 1; i <= this.state[fieldType].count; i++) {
      listOfFields.push(this.generateInputFields(fieldType, i));
    }
    return listOfFields;
  };

  generateInputFields = (additionalFieldType, additionalFieldNum) => {
    const nameValueKeys = {'First Name': 'firstNameValues', 'Last Name': 'lastNameValues'}
    return (
      ['First Name', 'Last Name'].map(name =>
          <TextField
            disabled={this.state.editing === false}
            variant="outlined"
            label={`${additionalFieldType} ${additionalFieldNum} ${name}`}
            value={this.state[additionalFieldType][nameValueKeys[name]][additionalFieldNum-1] || ""}
            onChange={(e) => this.handleDynamicFieldChange(additionalFieldType, nameValueKeys[name], additionalFieldNum-1, e.target.value)}
          />
      )
    );
  };

  handleDynamicFieldChange = (additionalFieldType, valueType, index, value) => {
    // Function to handle change in dynamically generated text fields
    this.setState(prevState => {
      const updatedValues = {...prevState[additionalFieldType]}; // Copy existing values

      updatedValues[valueType][index] = value; // Update value at specified index
      return { [additionalFieldType]: updatedValues }; // Update state
    });
  };

  generateSchoolFields = () => {
    const listOfFields = []
    const additionalSchoolCount = this.state['Additional School'].count
    const initialSchoolCount = this.state['Additional School'].initialCount
    if (additionalSchoolCount > initialSchoolCount){
      for (let i = initialSchoolCount+1; i <= additionalSchoolCount; i++) {
        listOfFields.push(this.generateSchoolInputFields(i));
      }
    }
    return listOfFields;
  };

  generateSchoolInputFields = (fieldNum) => {
    const fieldOrdinal = numberToOrdinal(fieldNum)
    return (
          <TextField
            disabled={this.state.editing === false}
            variant="outlined"
            label={`${fieldOrdinal} School` }
            value={this.state['Additional School']['schoolNames'][fieldNum-(this.state['Additional School'].initialCount+1)] || ""}
            onChange={(e) => this.handleDynamicSchoolFieldChange('Additional School', 'schoolNames', fieldNum-(this.state['Additional School'].initialCount+1), e.target.value)}
          />
      )
  };

  handleDynamicSchoolFieldChange = (fieldType, fieldName, index, value) => {
    // Function to handle change in dynamically generated text fields
    this.setState(prevState => {
      const updatedValues = {...prevState[fieldType]}; // Copy existing values

      updatedValues[fieldName][index] = value; // Update value at specified index
      return { [fieldType]: updatedValues }; // Update state
    });
  };

  handleTextFieldChange = (fieldType, fieldName, value) => {
    // Function to handle change in other text fields
    this.setState(prevState => {
      const updatedValues = {...prevState[fieldType]};
      updatedValues[fieldName] = value
      return { [fieldType]: updatedValues };
    });
  };

  handleEvaluationTextFieldChange = (fieldType, value) => {
    // Function to handle change in other text fields
    this.setState(prevState => {
      return {[fieldType]: value}
    });
  };

  handleDeidentifyData = (e) => {
    e.preventDefault()

    const deIdentifiedReportData = deidentifySensitiveInfo(this.state, 'reportText')
    const deidentifiedEvalNoteData = deidentifySensitiveInfo(this.state, 'evaluationNoteText')

    // Function to handle change in other text fields
    this.setState(prevState => {
      return {'reportText': deIdentifiedReportData, 'evaluationNoteText': deidentifiedEvalNoteData, 'editing': false}
    });
  };

  handleSubmitForm = e => {
    e.preventDefault();
    this.props.uploadReport({'Full Report': this.state.reportText, 'evaluation_notes': this.state.evaluationNoteText});
    this.setState({ shouldSaveFormData: false }, () => {
      localStorage.removeItem('reportUploadData');
    });
  };

  redirectToHome = () => {
    this.props.navigation('/home');
  }

  reportUploadComplete = () =>
    (this.props.uploading === false) &&
    (this.props.uploaded === true) &&
    (this.state.shouldSaveFormData === false)


  render() {
    const formIsValid = (this.state['Client Personal Information']['Client First Name'] && this.state['Client Personal Information']['Client First Name'] !== '') && (this.state['Client Personal Information']['Client Last Name'] && this.state['Client Personal Information']['Client Last Name'] !== '') && (this.state.reportText && this.state.reportText !== '');

    if (this.reportUploadComplete()) {
      return(
        <Layout>
          <div style={styles.main}>
            <Paper sx={styles.paper}>
              <Typography sx={styles.redirectText}>Report Upload Complete!</Typography>
                <div style={styles.redirectButton}>
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    onClick={this.redirectToHome}
                    styles={styles.redirectButton}
                  >
                    <Typography>
                      Return to homepage
                    </Typography>
                  </Button>
                </div>
            </Paper>
          </div>
        </Layout>
      )
    }

    return(
      <Layout>
        <div style={styles.root}>
          <div>
            <Typography sx={styles.headerText}>
              Upload Your Report
            </Typography>
            <Box
              component="form"
              sx={styles.phiForms}
              noValidate
              autoComplete="off"
            >
              <div style={{marginBottom: '36px'}}>
                <Typography sx={styles.formHeaderText}>
                  Client Personal Information
                </Typography>
                <div style={{marginBottom:'4px'}}>
                  {PHI_FIELDS['Client Personal Information'].map(phiField => (
                    DATE_PHI_FIELDS.includes(phiField) ?
                    <TextField
                      required={REQUIRED_PHI_FIELDS.includes(phiField)}
                      disabled={this.state.editing === false}
                      id={phiField}
                      label={phiField}
                      type = {this.getPhiFieldType(phiField)}
                      InputLabelProps={{ shrink: DATE_PHI_FIELDS.includes(phiField) }}
                      value={this.state['Client Personal Information'][phiField] || ""}
                      onChange={(e) => this.handleTextFieldChange('Client Personal Information', phiField, e.target.value)}
                    />:
                    <TextField
                      required={REQUIRED_PHI_FIELDS.includes(phiField)}
                      disabled={this.state.editing === false}
                      id={phiField}
                      label={phiField}
                      type = {this.getPhiFieldType(phiField)}
                      value={this.state['Client Personal Information'][phiField] || ""}
                      onChange={(e) => this.handleTextFieldChange('Client Personal Information', phiField, e.target.value)}
                    />
                  ))}
                  <TextField disabled sx={{visibility: 'hidden', height:'1px'}}/>
                </div>
              </div>

              <div style={{marginBottom: '36px'}}>
                <Typography sx={styles.formHeaderText}>
                  Family Information
                </Typography>
                <div style={{marginBottom:'5px'}}>
                  {PHI_FIELDS['Family Information'].map(phiField => (
                    <TextField
                      required={REQUIRED_PHI_FIELDS.includes(phiField)}
                      disabled={this.state.editing === false}
                      id={phiField}
                      label={phiField}
                      type = {this.getPhiFieldType(phiField)}
                      value={this.state['Family Information'][phiField] || ""}
                      onChange={(e) => this.handleTextFieldChange('Family Information', phiField, e.target.value)}
                    />
                  ))}
                {this.generateFields('Additional Family Member')}
                </div>
                  {
                    <Button
                      disabled={this.state.editing === false}
                      variant="contained"
                      color="primary"
                      onClick={() => this.handleAddFieldButtonClick('Additional Family Member')}
                    >
                      <Add />
                    </Button>
                  }
              </div>

              <div style={{marginBottom: '36px'}}>
                <Typography sx={styles.formHeaderText}>
                  Schools Attended
                </Typography>
                <div style={{marginBottom:'5px'}}>
                  {PHI_FIELDS['Schools Attended'].map(phiField => (

                    <TextField
                      disabled={this.state.editing === false}
                      id={phiField}
                      label={phiField}
                      value={this.state['Schools Attended'][phiField] || ""}
                      onChange={(e) => this.handleTextFieldChange('Schools Attended', phiField, e.target.value)}
                    />

                  ))}
                {this.generateSchoolFields('Additional School')}
                </div>
                  {
                    <Button
                      disabled={this.state.editing === false}
                      variant="contained"
                      color="primary"
                      onClick={() => this.handleAddFieldButtonClick('Additional School')}
                    >
                      <Add />
                    </Button>
                  }
              </div>

              <div style={{marginBottom: '36px'}}>
                <Typography sx={styles.formHeaderText}>
                  Teachers Referenced
                </Typography>
                <div style={{marginBottom:'5px'}}>
                  {PHI_FIELDS['Teachers Referenced'].map(phiField => (

                    <TextField
                      disabled={this.state.editing === false}
                      id={phiField}
                      label={phiField}
                      value={this.state['Teachers Referenced'][phiField] || ""}
                      onChange={(e) => this.handleTextFieldChange('Teachers Referenced', phiField, e.target.value)}
                    />

                  ))}
                {this.generateFields('Additional Teacher')}
                </div>
                {
                  <Button
                    disabled={this.state.editing === false}
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleAddFieldButtonClick('Additional Teacher')}
                  >
                    <Add />
                  </Button>
                }
              </div>

              <div style={{marginBottom: '36px'}}>
                <Typography sx={styles.formHeaderText}>
                  Support Practitioners (Dr.s, psychologists, tutors, etc.)
                </Typography>
                <div style={{marginBottom:'5px'}}>
                  {PHI_FIELDS['Support Practitioners (Dr.s, psychologists, tutors, etc.)'].map(phiField => (

                    <TextField
                      disabled={this.state.editing === false}
                      id={phiField}
                      label={phiField}
                      value={this.state['Support Practitioners (Dr.s, psychologists, tutors, etc.)'][phiField] || ""}
                      onChange={(e) => this.handleTextFieldChange('Support Practitioners (Dr.s, psychologists, tutors, etc.)', phiField, e.target.value)}
                    />

                  ))}
                {this.generateFields('Additional Practitioner')}
                </div>
                  {
                    <Button
                      disabled={this.state.editing === false}
                      variant="contained"
                      color="primary"
                      onClick={() => this.handleAddFieldButtonClick('Additional Practitioner')}
                    >
                      <Add />
                    </Button>
                  }
              </div>

              {this.state.editing === false &&
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleEditButtonClick(!this.state.editing)}
                  >
                    Edit PHI Fields
                  </Button>
                  <div style={styles.reviewReminderText}>
                      Please review your de-identified evaluation information below. Make sure there is no identifying information present before submitting. Edit the information below directly, or click the 'Edit' button above to make changes to the identifiable information fields and rerun deidentification. Once all identifying information has been removed, click Submit.
                  </div>
                </div>
              }

              <div style={styles.reportTextFieldDiv}>
                <Typography sx={styles.formHeaderText}>
                  Add Your Evaluation Notes
                </Typography>
                <TextField
                  required
                  style={{width: '100%'}}
                  multiline={true}
                  rows={10}
                  label="Add your evaluation notes"
                  id="evaluationNoteText"
                  value= {this.state.evaluationNoteText || ""}
                  onChange={(e) => this.handleEvaluationTextFieldChange("evaluationNoteText", e.target.value)}
                />
              </div>

              <div style={styles.reportTextFieldDiv}>
                <Typography sx={styles.formHeaderText}>
                  Add Your Report
                </Typography>
                <TextField
                  required
                  style={{width: '100%'}}
                  multiline={true}
                  rows={10}
                  label="Add your report"
                  id="reportText"
                  value= {this.state.reportText || ""}
                  onChange={(e) => this.handleEvaluationTextFieldChange("reportText", e.target.value)}
                />
              </div>

              <div style={styles.startButtonDiv}>
                <Tooltip
                  title= {<Typography sx={styles.toolTipText}>Please fill in all required* fields</Typography>}
                  disableHoverListener={formIsValid}
                >
                  <span>
                    <Button
                      type="submit"
                      size="large"
                      variant="contained"
                      sx={styles.startButton}
                      disabled={(!formIsValid) || (this.state.editing===false)}
                      onClick={(e) => this.handleDeidentifyData(e)}

                    >
                      <Typography sx={styles.buttonText}>
                        De-Identify
                      </Typography>
                    </Button>
                  </span>
                </Tooltip>
              </div>
              {
                this.state.editing === false &&
                <div style={styles.startButtonDiv}>
                  <Tooltip
                    title= {<Typography sx={styles.toolTipText}>Please fill in all required* fields</Typography>}
                    disableHoverListener={formIsValid}
                  >
                    <span>
                      <Button
                        type="submit"
                        size="large"
                        variant="contained"
                        sx={styles.startButton}
                        disabled={(!formIsValid)}
                        onClick={this.handleSubmitForm}
                      >
                        <Typography sx={styles.buttonText}>
                          {this.props.uploading ?
                            "Uploading...":
                            "Submit"
                          }
                        </Typography>
                      </Button>
                    </span>
                  </Tooltip>
                </div>
              }
            </Box>
          </div>
        </div>
      </Layout>
    );
  }
};

const mapStateToProps = state => {
  return {
    uploading: state.reportUpload.uploading,
    uploaded: state.reportUpload.uploaded,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    uploadReport: report => dispatch(reportUpload.uploadReport(report)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withNavigateHook(ReportUploader));
