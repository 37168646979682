import { getDefaultHeaders } from './util';

export const resetPassword = (uidb64, token, new_password) => {
  return (dispatch, getState) => {
    dispatch({type: 'PASSWORD_RESET_LOADING' });
    let headers = {"Content-Type": "application/json"};
    let body = JSON.stringify({'uidb64':uidb64, 'token':token, 'new_password':new_password});

    return fetch("/api/reset_password", {headers, body, method: "POST"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          console.log("Server Error!");
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'PASSWORD_RESET_SUCCESS'});
        } else if (res.status === 400) {
          dispatch({type: "TOKEN_EXPIRED_ERROR", error: res.data.error});
        }
      })
  }
}

export const sendFirstLoginResetPasswordEmail = () => {
  return async (dispatch, getState) => {
    dispatch({type: 'SEND_FIRST_LOGIN_PASSWORD_RESET_EMAIL_LOADING' });
    let url = '/api/send_first_login_reset_password_email';
    try {
      const res = await fetch(url, {
        headers: getDefaultHeaders(),
        method: "POST"
      })

      if (res.status === 200) {
        const data = await res.json();
        dispatch({ type: 'SEND_FIRST_LOGIN_PASSWORD_RESET_EMAIL_SUCCESS', message: data.message });
      }
    } catch (error) {
      dispatch({ type: 'SEND_FIRST_LOGIN_PASSWORD_RESET_EMAIL_ERROR', error: 'Network error' });
      return Promise.reject('Network error');
    }
  }
}

export const sendResetPasswordEmail = (email) => {
  return async (dispatch, getState) => {
    dispatch({type: 'SEND_PASSWORD_RESET_EMAIL_LOADING' });
    let url = '/api/send_reset_password_email';
    let body = JSON.stringify({email: email})
    try {
      const res = await fetch(url, {
        headers: {"Content-Type": "application/json"},
        method: "POST",
        body
      })

      if (res.status === 200) {
        const data = await res.json();
        dispatch({ type: 'SEND_PASSWORD_RESET_EMAIL_SUCCESS', message: data.message });
      } else if (res.status === 400) {
        const data = await res.json();
        dispatch({type: "EMAIL_DOES_NOT_EXIST_ERROR", error: data.error});
      }
    } catch (error) {
      dispatch({ type: 'SEND_PASSWORD_RESET_EMAIL_ERROR', error: 'Network error' });
      return Promise.reject('Network error');
    }
  }
}
