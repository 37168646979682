const initialState = {
  isLoading: false,
  resetSuccessful: false,
  resetEmailSuccessful: false,
  message: "",
  error: null,
};


export default function resetPassword(state=initialState, action) {

  switch (action.type) {
    case 'PASSWORD_RESET_LOADING':
      return {...state, isLoading: true, message: ""};
    case 'PASSWORD_RESET_SUCCESS':
      return {
        ...state,
        resetSuccessful: true,
        isLoading: false,
      };
    case 'TOKEN_EXPIRED_ERROR':
      return {
        ...state,
        resetSuccessful: false,
        isLoading: false,
        error: action.error
      };

    case 'SEND_FIRST_LOGIN_PASSWORD_RESET_EMAIL_LOADING':
      return {...state, isLoading: true, message: ""};
    case 'SEND_FIRST_LOGIN_PASSWORD_RESET_EMAIL_SUCCESS':
      return {
        ...state,
        resetEmailSuccessful: true,
        isLoading: false,
        message: action.message
      };

    case 'SEND_PASSWORD_RESET_EMAIL_LOADING':
      return {...state, isLoading: true, message: "", error: null};
    case 'SEND_PASSWORD_RESET_EMAIL_SUCCESS':
    console.log(action)
      return {
        ...state,
        resetEmailSuccessful: true,
        isLoading: false,
        error: null,
        message: action.message
      };
    case 'EMAIL_DOES_NOT_EXIST_ERROR':
      return {
        ...state,
        resetEmailSuccessful: false,
        isLoading: false,
        error: action.error,
        message: null
      };
    default:
      return state;
  }
}
