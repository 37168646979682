const initialState = {
  reportLoaded: false,
  report: {}
};

export default function reportDownload(state=initialState, action) {
  switch (action.type) {
    case 'FETCH_REPORT_DOWNLOAD_START':
      return {
        reportLoaded: false,
        report: {},
      };

    case 'FETCH_REPORT_DOWNLOAD_SUCCESS':
      return {
        reportLoaded: true,
        report: action.data,
      };

    default:
      return state;
  }
}
