import { getDefaultHeaders } from './util';

// fetch data for an appendix
export const fetchAppendixData= evalId => {
  return async (dispatch, getState) => {
    dispatch({type: 'FETCH_APPENDIX_START'});
    let url = '/api/appendix';
    if (evalId !== undefined) {
      url += `?eval_id=${evalId}`;
    }
    try {
      const res = await fetch(url, {
        headers: getDefaultHeaders(),
        method: "GET",
      });
      if (res.status === 200) {
        const data = await res.json();
        dispatch({ type: 'FETCH_APPENDIX_SUCCESS', data });
      } else if (res.status === 401) {
        dispatch({ type: 'FETCH_APPENDIX_ERROR', error: 'Authentication error' });
        return Promise.reject('Authentication error');
      } else if (res.status === 400) {
        dispatch({ type: 'FETCH_APPENDIX_ERROR', error: 'Server error' });
        return Promise.reject('Server error');
      } else {
        dispatch({ type: 'FETCH_APPENDIX_ERROR', error: 'Unknown error' });
        return Promise.reject('Unknown error');
      }
    } catch (error) {
      dispatch({ type: 'FETCH_APPENDIX_ERROR', error: 'Network error' });
      return Promise.reject('Network error');
    }
    }
    }
