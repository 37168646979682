import React, { Component, createRef } from "react";
import { connect } from "react-redux";

import withNavigateHook from '../../shared/withNavigateHook';

import Layout from '../../shared/Layout';

import { RATING_SCALE_FORM_TYPES, RATER_FORM_TYPE_MAPPINGS } from '../../shared/constants'

import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { Alert, Autocomplete, Button, Box, ClickAwayListener, Dialog, DialogActions, DialogContent, IconButton, MenuItem, TextField, Typography } from '@mui/material';


const styles = ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    textAlign: 'center',
    overflow: 'hidden',
    marginBottom: '36px',
    marginLeft: '400px'
  },
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  assessmentMenuDiv: {
    marginTop: '30px',
    display: 'flex',
    alignItems: 'left',
    width: '100%',
    marginBottom: '10px'
  },
  subtestMenuDiv: {
    display: 'flex',
    alignText: 'center',
    marginBottom: '10px',
    marginTop: '10px'
  },
  paper: {
    marginTop:'40px',
    marginLeft:'5%',
    marginRight:'5%',
    width: '70%',
    alignItems: 'center',
    display: 'inline-block'
  },
  assessmentTitle: {
    textAlign: 'center',
    marginBottom: '15px',
    marginTop: '15px',
    fontFamily:'Raleway',
    fontSize: 20,
    marginLeft: 10
  },
  assessmentsAddedTitle: {
    textAlign: 'center',
    marginBottom: '15px',
    marginTop: '5px',
    fontFamily:'Raleway',
    fontSize: 24,
  },
  assessmentsAddedAssessment: {
    fontFamily:'Raleway',
    fontSize: 16,
  },
  assessmentsAddedSubtest: {
    fontFamily:'Raleway',
    fontSize: 14,
  },
  subtestTitle: {
    fontFamily:'Raleway',
    fontSize: 16,
    // marginRight: '10px'
  },
  subtestRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10
  },
  addSubtestIcon: {
    marginLeft: '20px'
  },
  subtestDeleteButton: {
    marginLeft: 2
  },
  headerText: {
    textAlign: 'left',
    width:'100%',
    marginTop: '20px',
    marginBottom: '24px',
    fontFamily: 'Comfortaa',
    fontSize: 40,
    fontWeight: 200
  },
  gridContainer: {
    width: '90%',
    margin: '20px auto',
  },
  customHeader: {
    backgroundColor: '#f5f5f5',
    height: '60px',
    padding: '10px',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '16px'
  },
  columnHeader: {
    fontWeight: 'bold',
  },
  cellBorder: {
    border: '.5px solid rgba(224, 224, 224, 1)', // You can customize the border color and style as needed
  },
  assessmentListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    marginTop:'20px'
  },
  assessmentListOverlay: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 0, 0, 0.1)', /* Light pink with transparency */
    padding: '5px 10px',
    margin: '8px 0',
    width:'60%',
    borderRadius: '8px' /* Rounded corners */
  },
  centeredAlert: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1400,
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1400,
  }
});

const encodeString = (str) => str.replace(/[^a-zA-Z0-9]/g, '-');

class RatingScaleUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRatingScale: null,
      selectedRatingScaleData: null,
      ratingScales: [],
      validationErrors: [],
      onFormReview: null,
      loading: true,
      openScale: false,
      deleteConfirmationOpen: false
    };
    this.dialogContentRef = createRef();
  }

  componentDidMount() {
    const { ratingScaleData, ratingScaleDirectory } = this.props;

    let ratingScales = this.getRatingScaleState(ratingScaleData, ratingScaleDirectory)

    let selectedRatingScale = localStorage.getItem('selectedRatingScale')
    let openScale = localStorage.getItem('openScale')
    let selectedRatingScaleData = localStorage.getItem('selectedRatingScaleData');

    if (selectedRatingScale && openScale && selectedRatingScaleData){
      selectedRatingScale = JSON.parse(selectedRatingScale)
      openScale = JSON.parse(openScale)
      selectedRatingScaleData = JSON.parse(selectedRatingScaleData)
      if (ratingScales.filter(ratingScale => ratingScale.name === selectedRatingScale).length === 0){
        ratingScales = [selectedRatingScaleData, ...ratingScaleData]
      }
    }
    this.setState({ratingScales: ratingScales, selectedRatingScale: selectedRatingScale || null, openScale:openScale || null, selectedRatingScaleData: selectedRatingScaleData || null })
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    // Check if loaded
    if (user.isLoaded && this.state.loading) {
      this.setState({ loading: false });
    }
  }

  // returns the formatted ratingScale state. For each added rating scale and form, all directory data (scales/composites),
  // score, percentile and outcome valaues are left blank in cases where no data has been saved, and otherwise are updated with the corresponding saved values
  getRatingScaleState(ratingScaleData, ratingScaleDirectory){
    const ratingScales = ratingScaleData.map(ratingScale => {
      const ratingScaleName = ratingScale.name;
      const dirRatingScale = ratingScaleDirectory[ratingScaleName];

      const findForm = (formType) => {
        return dirRatingScale[formType] || {};
      };

      const findMeasure = (measureName, form) => {
        return (form.measures || []).find(m => m.name === measureName) || {};
      };

      const newRatingScale = {
        name: ratingScaleName,
        forms: ratingScale.forms.map(form => {
          const dirFormData = findForm(form.form_type);
          return {
            rater: form.rater,
            form_type: form.form_type,
            measures: dirFormData.map(measure => {
              const measureData = findMeasure(measure.measureName, form)
              return {
                name: measure.measureName || null,
                tScore: measureData.tScore || null,
                percentile: measureData.percentile || null
              };
            })
          };
        }),
      };
      return newRatingScale;
    });
    return ratingScales
  }

  saveRatingScalesToLocalStorage = () => {
    const { ratingScales, selectedRatingScale } = this.state;
    localStorage.setItem('openScale', JSON.stringify(true));
    localStorage.setItem('selectedRatingScale', JSON.stringify(selectedRatingScale));
    // only update open assessment data in local storage if it wasnt added previously (don't want to erroneously overwrite existing saved data with )
    if (this.props.ratingScaleData.filter(ratingScale => ratingScale.name===selectedRatingScale).length === 0){
      localStorage.setItem('selectedRatingScaleData', JSON.stringify(ratingScales.find(ratingScale => ratingScale.name===selectedRatingScale)))
    }
  }

  getAvailableRatingScales = () => {
    const { ratingScales, selectedRatingScale } = this.state;
    const allRatingScales = Object.keys(this.props.ratingScaleDirectory);
    const addedRatingScales = ratingScales.filter(r => r.name !== selectedRatingScale).map(ratingScale  => ratingScale.name); // Get names of added assessments

    // Filter out assessments that have already been added
    const availableRatingScales = allRatingScales.filter(ratingScale => !addedRatingScales.includes(ratingScale));
    const availableScalesDict = {}
    availableRatingScales.sort((a, b) => a.localeCompare(b)).forEach(scale => {
      const scaleDirObj = this.props.ratingScaleDirectory[scale]
      const scaleDict = { label: scale, name: scale, abbreviation: '' };
      if (scaleDirObj.rating_scale_abbreviation) {
        scaleDict.label = `${scale} (${scaleDirObj.rating_scale_abbreviation})`;
        scaleDict.abbreviation = scaleDirObj.rating_scale_abbreviation;
      }
      availableScalesDict[scale] = scaleDict
    })

    return availableScalesDict
  }

  getFormOptions = (ratingScaleName) => {
    const formOptions = []
    RATING_SCALE_FORM_TYPES.forEach((formType) => {
      if (this.props.ratingScaleDirectory[ratingScaleName][formType]){
        Object.keys(RATER_FORM_TYPE_MAPPINGS).forEach(rater => {
          if (RATER_FORM_TYPE_MAPPINGS[rater] === formType){
            formOptions.push(rater)
          }
        })
      }
    })
    return formOptions
  }

  getAvailableForms = (ratingScaleName) => {
    if (!ratingScaleName || !this.props.ratingScaleDirectory[ratingScaleName]){
      return []
    }
    const ratingScale = this.state.ratingScales.find(item => item.name === ratingScaleName);
    const addedForms = ratingScale ? ratingScale.forms.map(form => form.rater) : [];

    const formOptions = this.getFormOptions(ratingScaleName)
    const options = formOptions.filter(form => !addedForms.includes(form));
    return options;
  };

  getEmptyMeasuresList = (ratingScaleName, formType) => {
    const measuresList = []
    const measures = this.props.ratingScaleDirectory[ratingScaleName][formType]
    measures.forEach((measure) => {
      const measureDict = {name: measure['measureName'], percentile: null, tScore: null, scoreType: measure['scoreType'] || null}
      measuresList.push(measureDict)
    })

    return measuresList
  };

  handleRatingScaleChange = (newValue) => {
    if (newValue) {
      this.setState({ selectedRatingScale: newValue.name }, this.handleAddRatingScale(newValue));
    } else {
      this.setState({ selectedRatingScale: null });
    }
  };

  handleAddRatingScale = (newValue) => {
    const { ratingScales } = this.state;
    const selectedRatingScale = newValue.name
    if (selectedRatingScale) {
      const newRatingScale = {
        name: selectedRatingScale,
        forms: [],
        selectedForm: null // Initialize selectedSubtest within the assessment
      };
      this.setState({
        ratingScales: [newRatingScale, ...ratingScales],
        openScale: true
      }, this.saveRatingScalesToLocalStorage);
    }
  };

  handleAddForm = (ratingScaleName, rater) => {
    const updatedRatingScales = this.state.ratingScales.map(ratingScale => {
      if (ratingScale.name === ratingScaleName) {
        const raterToAdd = rater;
        const formType = RATER_FORM_TYPE_MAPPINGS[rater]

        if (raterToAdd) {
          const measures = this.getEmptyMeasuresList(ratingScaleName, formType)

          const form = {form_type: formType, rater: rater, measures:measures};
          return {
            ...ratingScale,
            forms: [form, ...ratingScale.forms],
            selectedForm: null, // Clear the selectedSubtest after adding
          };
        }
      }
      return ratingScale;
    });
    this.setState({ ratingScales: updatedRatingScales }, this.saveRatingScalesToLocalStorage);
  };

  handleDeleteForm = (ratingScaleName, rater) => {
    const updatedRatingScales = this.state.ratingScales.map(ratingScale => {
      if (ratingScale.name === ratingScaleName) {
        return {
          ...ratingScale,
          forms: ratingScale.forms.filter(form => form.rater !== rater)
        };
      }
      return ratingScale;
    });
    this.setState({ ratingScales: updatedRatingScales, validationErrors: [] }, this.saveRatingScalesToLocalStorage);
  };

  handleDeleteRatingScale = () => {
    const { selectedRatingScale } = this.state
    const { deleteRatingScale } = this.props

    deleteRatingScale(selectedRatingScale)
    this.setState({ ratingScales: this.state.ratingScales.filter(r => r.name !== selectedRatingScale), selectedRatingScale: null });
  };

  validateRatingScale = (selectedRatingScale) => {
    const { ratingScales } = this.state;
    let validationErrors = []; // To store validation errors
    const ratingScale = ratingScales.find(r => r.name === selectedRatingScale)
    if (ratingScale.forms.length === 0) {
      // If an assessment has no subtests added, log an error
      validationErrors.push(`Rating Scale "${selectedRatingScale}" has no forms added. Please add at least one form or remove the Rating Scale.`);
    }

    ratingScale.forms.forEach(form => {
      var formFilled = false
      form.measures.forEach(measure => {
        var percentileIsValid = false
        // validate percentile value
        if (measure.percentile !== null && measure.percentile !== '') {
          formFilled = true
          if (isNaN(measure.percentile) || measure.percentile < 0 || measure.percentile >= 100) {
            validationErrors.push(`Rating Scale "${ratingScale.name}" ("${form.rater}") has an invalid percentile value. Percentile must be a greater than or equal to 0 and less than 100.`);
          }
          else {
            percentileIsValid = true
          }
        }

        //validate t-score value
        if (measure.tScore !== null && measure.tScore !== '') {
          formFilled = true
          if (isNaN(measure.tScore)) {
            validationErrors.push(`Rating Scale "${ratingScale.name}" ("${form.rater}"), measure "${measure.name}" has an invalid T-Score value. T-Score must be a numeric value.`);
          }
        }

        // add error if percentile exists but no t-score
        if (percentileIsValid===true && (measure.tScore === null || measure.tScore === '')){
          validationErrors.push(`Rating Scale "${ratingScale.name}" ("${form.rater}"), measure "${measure.name}" needs a T-Score value.`);
        }
      })
      if (formFilled===false){
        validationErrors.push(`Rating Scale "${ratingScale.name}" ("${form.rater}") is empty. Please fill in the T-Score values for at least one item or remove the form.`);
      }
    });

    return validationErrors;
  };

  handleCancel = () => {
    const { selectedRatingScale } = this.state
    const { ratingScaleData, ratingScaleDirectory } = this.props

    localStorage.removeItem('openScale');
    localStorage.removeItem('selectedRatingScale');
    localStorage.removeItem('selectedRatingScaleData');

    // if the assessment data is already stored in report data/backend, cancel leaves the data unchanged.
    // otherwise, updates state removing the new assessment data without saving
    if (ratingScaleData.some(r => r.name === selectedRatingScale)){
      this.setState({ selectedRatingScale: null, ratingScales: this.getRatingScaleState(ratingScaleData, ratingScaleDirectory), validationErrors: [] }, this.handleCloseRatingScale);
    } else {
      this.setState({ selectedRatingScale: null, ratingScales: this.state.ratingScales.filter(r => r.name!==selectedRatingScale), validationErrors: [] }, this.handleCloseRatingScale);
    }
  };

  // return only rating scale forms/measure with new/updated values
  formatDataForSave = (selectedRatingScale) => {
    const ratingScale = this.state.ratingScales.find(r => r.name === selectedRatingScale)
    const existingRatingScaleData = this.props.ratingScaleData.find(r => r.name === selectedRatingScale)
    const formattedForms = []
    // reverse order to ensure that the first assessment submitted has the lowest id when created-> correct ordering is maintained when loaded into state
    ratingScale.forms.reverse().forEach(form => {
      const existingFormData = existingRatingScaleData ? existingRatingScaleData.forms.find(f => f.rater === form.rater): null
      const formattedMeasures = []
      form.measures.forEach(measure => {
        const existingMeasureData = existingFormData ? existingFormData.measures.find(m => m.name === measure.name): null
        if (measure.percentile || measure.tScore || (existingMeasureData && (JSON.stringify(existingMeasureData) !== JSON.stringify(measure)))){
          formattedMeasures.push(measure)
        }
      })
      if (formattedMeasures.length > 0){
        formattedForms.push({...form, measures: formattedMeasures})
      }
    })

    // if there are any forms in the existing rating scale data that are deleted, we include them so they can be removed when sent to server
    if (existingRatingScaleData){
      existingRatingScaleData.forms.forEach(form => {
        const updatedForm = ratingScale.forms.find(f => f.rater === form.rater)
        if (!updatedForm) {
          formattedForms.push({...form, measures: form.measures.map(m => {return { ...m, percentile: null, tScore: null }})})
        }
      })
    }

    ratingScale['forms'] = formattedForms

    return ratingScale
  }

  handleSave = () => {
    const { selectedRatingScale } = this.state
    const { updateRatingScaleData } = this.props

    const validationErrors = this.validateRatingScale(selectedRatingScale);
    if (validationErrors.length > 0) {
      this.setState({ validationErrors }, () => {
        if (this.dialogContentRef.current) {
          this.dialogContentRef.current.scrollTop = this.dialogContentRef.current.scrollHeight;
        }
      });
      return;
    }

    const formattedData = this.formatDataForSave(selectedRatingScale)

    // send only the data for the new assessment to be added
    updateRatingScaleData({ratingScale: formattedData})

    localStorage.removeItem('openScale');
    localStorage.removeItem('selectedRatingScale');
    localStorage.removeItem('selectedRatingScaleData');
    this.setState({ selectedRatingScale: null }, this.handleCloseRatingScale);
  };

  handleCloseRatingScale = () => {
    this.setState({ openScale: false });
  }

  openScaleFromList = (ratingScale) => {
    this.setState({selectedRatingScale: ratingScale.name, openScale:true}, this.saveRatingScalesToLocalStorage)
  }

  formIsValid = () => {
    const validationErrors = this.validateRatingScales();
    if (validationErrors.length === 0) {
      return true
    }

    return false
  }

  // convert numeric values to float and convert empty strings to nulls before storing row data in state
  formatRowUpdate = (newRow) => {
    if (!newRow.percentile){
      newRow.percentile = null
    } else if (!isNaN(newRow.percentile)) {
      newRow.percentile = parseFloat(newRow.percentile)
    }

    if (!newRow.score){
      newRow.score = null
    } else if (!isNaN(newRow.score)) {
      newRow.score = parseFloat(newRow.score)
    }
    return newRow
  }

  processRowUpdate = (newRow, oldRow, name, rater) => {
    const formattedRow = this.formatRowUpdate(newRow)
    this.setState(prevState => {
      const updatedRatingScales = [...prevState.ratingScales];
      const updatedRatingScaleIndex = updatedRatingScales.findIndex(ratingScale =>
        ratingScale.name===name && (ratingScale.forms.some(form => form.rater === rater && form.measures.some(measure => measure.name === oldRow.testName)))
      );

      if (updatedRatingScaleIndex !== -1) {
        const updatedRatingScale = updatedRatingScales[updatedRatingScaleIndex];
        const formIndex = updatedRatingScale.forms.findIndex(form => form.rater === rater);

        if (formIndex !== -1) {
          const measureIndex = updatedRatingScale.forms[formIndex].measures.findIndex(measure => measure.name === oldRow.testName);
          if (measureIndex !== -1){

            // Update measure
            updatedRatingScale.forms[formIndex].measures[measureIndex] = {
              ...updatedRatingScale.forms[formIndex].measures[measureIndex],
              percentile: formattedRow.percentile,
              tScore: formattedRow.score,
            };
            updatedRatingScales[updatedRatingScaleIndex] = updatedRatingScale;
          }
        }
        return { ratingScales: updatedRatingScales };
      }}, this.saveRatingScalesToLocalStorage)
    return formattedRow;
  };

  getGridRowsByForm = (ratingScaleName) => {
    var selectedRatingScale = this.state.ratingScales.find(r => r.name === ratingScaleName);
    if (!selectedRatingScale) {
      if (Object.keys(this.props.ratingScaleDirectory).includes(ratingScaleName)) {
        selectedRatingScale = this.props.ratingScaleDirectory[ratingScaleName]
      }
      else {
        return []
      }
    }

    let rowsByForm = [];
    if (selectedRatingScale.forms.length === 0){
      return rowsByForm
    }

    selectedRatingScale.forms.forEach(form => {
      const formMeasureList = []
      form.measures.forEach(measure => {
        formMeasureList.push({
          id: `${ratingScaleName}-${form.rater}-${measure['name']}`,
          testName: measure['name'],
          score: measure['tScore'] || null,
          percentile: measure['percentile'] || null,
        })
      })
      rowsByForm.push(formMeasureList)
    })
    return rowsByForm;
  }

  handleCellKeyDown = (params, event, rows, columns) => {
    if (event.key === 'Tab') {
      event.preventDefault();

      const { id, field } = params;
      const columnIndex = columns.findIndex((col) => col.field === field);
      const rowIndex = rows.findIndex((row) => row.id === id);

      let nextCellParams;

      if (event.shiftKey) {
        // Shift+Tab: Move to the previous cell
        if (columnIndex > 0) {
          nextCellParams = { id, field: columns[columnIndex - 1].field, rowIndex, columnIndex: columnIndex - 1 };
        } else if (rowIndex > 0) {
          const previousRowId = rows[rowIndex - 1].id;
          const lastColumnField = columns[columns.length - 1].field;
          nextCellParams = { id: previousRowId, field: lastColumnField, rowIndex: rowIndex - 1, columnIndex: columns.length - 1 };
        }
      } else {
        // Tab: Move to the next cell
        if (columnIndex < columns.length - 1) {
          nextCellParams = { id, field: columns[columnIndex + 1].field, rowIndex, columnIndex: columnIndex + 1 };
        } else {
          const nextRowId = rows[rowIndex + 1]?.id;
          if (nextRowId !== undefined) {
            const firstColumnField = columns[0].field;
            nextCellParams = { id: nextRowId, field: firstColumnField, rowIndex: rowIndex + 1, columnIndex: 0 };
          }
        }
      }

      if (nextCellParams) {
        const encodedId = encodeString(nextCellParams.id);
        const encodedField = encodeString(nextCellParams.field);
        const cellSelector = `.cell-${encodedField}-${encodedId}`;
        const cellElement = document.querySelector(cellSelector);
        if (cellElement) {
          cellElement.focus();
        }
      }
    }
  };

  handleClickAway = () => {
    this.setState({ deleteConfirmationOpen: false });
  };

  renderRatingScalesList = () => {
    const { ratingScales } = this.state;

    if (ratingScales.length === 0) {
      return null; // Don't render the section if there are no assessments
    }

    return (
      <div style={styles.assessmentListContainer}>
        {this.state.deleteConfirmationOpen && <div style={styles.overlay}></div>}
        {ratingScales.map((ratingScale, index) => (
          <Box style={styles.assessmentListOverlay}>
            <Typography variant="body2" >
              {ratingScale.name}
            </Typography>
            <div>
              <Button onClick={() => this.openScaleFromList(ratingScale)} color="primary"><Typography variant="body2" style={{color:'#3b3b3b'}}>Edit</Typography></Button>
              <IconButton
                aria-label="delete rating scale"
                onClick={() => this.setState({deleteConfirmationOpen: true, selectedRatingScale: ratingScale.name})}
                sx={styles.deleteButton}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </Box>
        ))}
        {this.state.deleteConfirmationOpen &&
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <Alert
              onClose={() => this.setState({ deleteConfirmationOpen: false })}
              severity="warning"
              style={styles.centeredAlert}  // Align contents horizontally
              action={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button size="small" color="inherit" variant="outlined" style={{ margin: '0 5px' }} onClick={() => this.handleDeleteRatingScale()}>
                    DELETE
                  </Button>
                  <Button variant="outlined" color="inherit" size="small" style={{ margin: '0 5px' }} onClick={() => this.setState({ deleteConfirmationOpen: false })}>
                    BACK
                  </Button>
                </div>
              }
            >
              <p style={{ margin: '0 10px' }}>Are you sure you want to delete {this.state.selectedRatingScale}?</p>
            </Alert>
          </ClickAwayListener>
        }
      </div>
    );
  };

  ratingScalesUpdated = (selectedRatingScale, ratingScales, existingRatingScaleData) => {
    const ratingScaleData = ratingScales.find(r => r.name === selectedRatingScale);
    const existingRatingScale = existingRatingScaleData.find(r => r.name === selectedRatingScale);

    // If there's no existing data, consider the scale as updated if it has any forms with measure data
    if (!existingRatingScale) {
      return ratingScaleData.forms.some(form => {return form.measures.some(m => m.percentile || m.tScore)});
    }

    return (
      ratingScaleData.forms.some(form => {
        const existingForm = existingRatingScale.forms.find(f => f.rater === form.rater);

        return (!existingForm && form.measures.some(m => m.percentile || m.tScore)) ||
               (existingForm && form.measures.some(measure => {
                 const existingFormMeasure = existingForm.measures.find(m => m.name === measure.name)
                 return ((!existingFormMeasure) && (measure.percentile || measure.tScore)) ||
                   (existingFormMeasure && (
                     (existingFormMeasure.percentile !== measure.percentile) ||
                     (existingFormMeasure.tScore !== measure.tScore))
                   )
               }))
      }): false) ||
      (existingRatingScale.forms.some(form => {
        return !ratingScaleData.forms.find(f => f.rater === form.rater)
      }))
  };

  renderRatingScaleGrid = (ratingScale) => {
    const { validationErrors } = this.state
    const { name } = ratingScale;
    const formRows = this.getGridRowsByForm(name);

    const headerRowHeight = 56;
    const rowHeight = 35; // Define the height of each row
    const gridHeights = []
    formRows.forEach(form => {
      const gridHeight = rowHeight*form.length + headerRowHeight
      gridHeights.push(gridHeight)
    })

    const columns = [
      {
        field: 'testName',
        headerName: 'Measure/Index',
        flex: 2,
      },
      { field: 'score', headerName: 'T-Score', editable: true, flex: 1 },
      { field: 'percentile', headerName: 'Percentile', editable: true, flex: 1 },
    ];

    return (
      <Dialog
        style={{marginTop:'50px'}}
        open={this.state.openScale}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: '60%', // Set the dialog to take up 50% of the screen width
            maxWidth: 'none', // Disable the default maxWidth
            overflow: 'hidden', // Ensure no overflow issues on the parent container
          },
        }}
      >
        <DialogContent
          ref={this.dialogContentRef}
          style={{
            width: '100%',
            maxWidth: '100%', // Ensure content does not exceed the dialog width
            overflow: 'auto', // Handle any overflow within the dialog content
            padding: '20px', // Add some padding if needed
            boxSizing: 'border-box', // Include padding in the element's width and height
            maxHeight:'90%'
          }}
        >
          <div style={styles.gridContainer}>
            <div style={styles.customHeader}>
              <Typography sx={styles.assessmentTitle}>
                {name}
              </Typography>
            </div>
            <div style={styles.subtestMenuDiv}>
              <Autocomplete
                id="ratingScale-dropdown"
                options={this.getAvailableForms(name) || []}
                value={ratingScale.selectedForm} // Use selectedRatingScale from the assessment
                onChange={(event, value) => this.handleAddForm(name, value)}
                renderInput={(params) => (
                  <TextField {...params} label="Add a form" variant="outlined" fullWidth/>
                )}
                style={{ width: '100%' }}
              />
            </div>
            {ratingScale.forms.map((form, index) => (
              <div key={form.rater}>
                <Typography sx={styles.assessmentTitle}>
                  {form.form_type} Form {form.rater !== 'Self' && `(${form.rater})`}
                  <IconButton
                    aria-label="delete Form"
                    onClick={() => this.handleDeleteForm(ratingScale.name, form.rater)}
                    sx={styles.deleteButton}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Typography>

                <div style={{ height: gridHeights[index], width: '100%' }}>
                  <DataGrid
                    columns={columns}
                    rows={formRows[index]}
                    onCellKeyDown={(params, event) => this.handleCellKeyDown(params, event, formRows[index], columns)}
                    getCellClassName={(params) => `cell-${encodeString(params.field)}-${encodeString(params.id)}`}
                    processRowUpdate={(newRow, oldRow) => this.processRowUpdate(newRow, oldRow, name, form.rater)}
                    hideFooter
                    disableRowSelectionOnClick
                    disableColumnMenu
                    rowHeight={rowHeight}
                    sx={{
                      '& .MuiDataGrid-columnHeaderTitle': styles.columnHeader,
                      '& .MuiDataGrid-cell': styles.cellBorder,
                      '& .MuiDataGrid-row': {
                        borderBottom: '.5px solid rgba(224, 224, 224, 1)', // Add bottom border to each row
                      }
                    }}
                    componentsProps={{
                      cell: {
                        disableColumnMenu: true
                      },
                      columnMenu: null,
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
          <div style={{ width: '100%' }}>
            {validationErrors.length > 0 && (
              <div style={{ color: 'red', marginTop: '30px', marginLeft: '15%', marginRight: '15%' }}>
                Please fix the following errors before continuing:
                <ul>
                  {validationErrors.map((error, index) => (
                    <li style={{ textAlign: 'left', marginBottom: '10px', fontSize: 14 }} key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions
          style={{
            position: 'sticky',
            bottom: 0,
            backgroundColor: 'white', // Ensure the background is white so it covers content when sticky
            padding: '0px 24px', // Add padding to match default MUI button styling
            boxSizing: 'border-box', // Ensure padding is accounted for
          }}
        >
          <Button
            aria-label="cancel update"
            variant="text"
            onClick={this.handleCancel}
            style={{ marginRight: 'auto' }}
          >
            <p style={{color: "black"}}>Cancel</p>
          </Button>
          <Button
            onClick={this.handleSave}
            color="primary"
            autoFocus
            disabled={!this.ratingScalesUpdated(this.state.selectedRatingScale, this.state.ratingScales, this.props.ratingScaleData)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderRatingScaleDropDown = () => {
    const availableRatingScales = this.getAvailableRatingScales()
    return (
        <Autocomplete
          id="ratingscale-dropdown"
          options={Object.values(availableRatingScales) || []}
          getOptionLabel={(option) => option.label}
          value={undefined}
          onChange={(event, newValue) => this.handleRatingScaleChange(newValue)}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          renderInput={(params) => (
            <TextField {...params} label="Add a Rating Scale" variant="outlined" fullWidth/>
          )}
          style={{ flex: 1, maxWidth:'60%' }}
          renderOption={(props, option) => <MenuItem {...props} key={option.name}>{option.label}</MenuItem>}
        />
    )
  }

  render() {

    const { ratingScales, selectedRatingScale } = this.state

    const { user } = this.props;

    // Step 1: Handle the loading state
    if (this.state.loading===true || !user.isLoaded || !user.user.email) {
      return (
        <Layout>
          <div style={{ padding: '40px', textAlign: 'left' }}>
            <Typography sx={styles.subHeaderText}>
              Loading...
            </Typography>
          </div>
        </Layout>
      );
    }

    return (
      <Layout>
        <div style={styles.root}>
            <Typography sx={styles.headerText}>
              Add your Rating Scales
            </Typography>

            <div style={styles.assessmentMenuDiv}>
              {this.renderRatingScaleDropDown()}
            </div>

            {selectedRatingScale && ratingScales.filter(ratingScale => ratingScale.name===selectedRatingScale).map(ratingScale => this.renderRatingScaleGrid(ratingScale))}

            {this.renderRatingScalesList()}
          </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNavigateHook(RatingScaleUploader));
