import React, { Component } from "react";
import { connect } from "react-redux";
import { Navigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { user, auth } from '../actions';

const styles = ({
  globalLoad: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    zIndex: 9999,
    color: 'white',
  },
  progressWrapper: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    height: '100px',
  },
  progress: {
    color: 'white',
  },
});

class Layout extends Component {
  componentDidMount() {
    this.checkUserStatus();
  }

  componentDidUpdate(prevProps) {
    if ((this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated) && !this.props.skipLogout) {
      this.checkUserStatus();
    }
  }

  checkUserStatus = () => {
    const { auth, user, loadUser, logout } = this.props;

    if (auth.isAuthenticated) {
      if (!user.isLoaded && !user.isLoading) {
        loadUser();
      }
    } else {
      logout();
    }
  };

  renderLoading = () => {
    return (
      <div className={styles.globalLoad}>
        <div className={styles.progressWrapper}>
          <CircularProgress
            style={styles.progress}
            size={100}
          />
        </div>
      </div>
    );
  };

  render() {
    const { auth, user, children } = this.props;

    if ((!auth.isAuthenticated) && !this.props.skipLogout) {
      return <Navigate to='/login' />;
    }

    if ((user.isLoading || !user.isLoaded) && !this.props.skipLogout) {
      return this.renderLoading();
    }

    return <div>{children}</div>;
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  loadUser: () => dispatch(user.loadUser()),
  logout: () => dispatch(auth.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
