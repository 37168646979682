const initialState = {
  isAuthenticated: false,
  isLoading: false,
  errors: {},
};


export default function auth(state=initialState, action) {

  switch (action.type) {
    case 'AUTHENTICATION_CACHED':
      return { ...state, isAuthenticated: action.data };

    case 'LOGIN_LOADING':
      return {...state, isLoading: true};

    case 'LOGIN_SUCCESSFUL':
      return {
        ...state,
        ...action.data,
        isLoading: false,
        isAuthenticated: true,
        errors: {}
      };

    case 'AUTHENTICATION_ERROR':
      return {
        errors: action.data,
        isAuthenticated: false,
        isLoading: false
      };
    case 'LOGIN_FAILED':
      return {
        errors: action.data,
        isAuthenticated: false,
        isLoading: false
      };
    case 'LOGOUT_START':
    case 'LOGOUT_SUCCESSFUL':
      return initialState;
    default:
      return state;
  }
}
