const initialState = {
  loading: null,
  loaded: false,
  subtests: {}
};

export default function subtestDirectory(state=initialState, action) {
  switch (action.type) {
    case 'FETCH_SUBTEST_DIRECTORY_START':
      return {
        loading: true,
        subtests: {},
      };

    case 'FETCH_SUBTEST_DIRECTORY':
      return {
        loading: false,
        loaded:true,
        subtests: action.data,
      };

    default:
      return state;
  }
}
