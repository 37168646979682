const initialState = {
  appendixLoaded: false,
  appendix: {}
};

export default function appendixData(state=initialState, action) {
  switch (action.type) {
    case 'FETCH_APPENDIX_START':
      return {
        appendixLoaded: false,
        appendix: {},
      };

    case 'FETCH_APPENDIX_SUCCESS':
      return {
        appendixLoaded: true,
        appendix: action.data,
      };

    default:
      return state;
  }
}
