import { Document, Packer, Paragraph, TextRun, Table, TableRow, TableCell, WidthType,  ShadingType, TableLayoutType, AlignmentType} from 'docx';

import { saveAs } from 'file-saver';

import { REPORT_SECTION_APPENDIX_HEADER_MAPPINGS, ALTERNATIVE_REPORT_SECTION_NAME_MAPPINGS } from '../../shared/constants'

const pageWidthTwips = 8000;

const columnWidths = [3000, 2000, 2000];
const cellWidths = {
  column1: 3000,
  column2: 2000,
  column3: 2000
};


const hasValidAssociatedComposite = (assessmentDetails) => {
  if (assessmentDetails.composites && assessmentDetails.composites.length > 0){
    return assessmentDetails.subtests.some(subtest => {
      const assocCompositeName = subtest.compositeName;
      const compositeNameExists = assessmentDetails.composites.some(composite => composite.compositeName === assocCompositeName);
      return assocCompositeName !== null && assocCompositeName !== '' && assocCompositeName !== undefined && compositeNameExists;
    });
  }
  return false;
}

const getCompositeTableRows = (assessmentDetails, column1Header, column2Header, column3Header) => {
  const rows = []
  rows.push(new TableRow({
    children: [
      new TableCell({
        children: [new Paragraph({
          children: [new TextRun({
            text: column1Header,
            font: 'Arial',
            bold: true
          })],
        })],
        width: {
          size: cellWidths.column1,
          type: WidthType.DXA,
        }
      }),
      new TableCell({
        children: [new Paragraph({
          children: [new TextRun({
            text: column2Header,
            font: 'Arial',
            bold: true
          })],
          alignment: AlignmentType.CENTER,
        })],
        width: {
          size: cellWidths.column2,
          type: WidthType.DXA,
        }
      }),
      new TableCell({
        children: [new Paragraph({
          children: [new TextRun({
            text: column3Header,
            font: 'Arial',
            bold: true
          })],
          alignment: AlignmentType.CENTER,
        })],
        width: {
          size: cellWidths.column3,
          type: WidthType.DXA,
        }
      }),
    ],
    cantSplit: true,
  }));
  assessmentDetails['composites'].forEach(composite => {
    rows.push(new TableRow({
      children: [
        new TableCell({
          children: [new Paragraph({
            text: composite['compositeName'] || '',
            style: "tableContents"
          })],
          width: {
            size: cellWidths.column1,
            type: WidthType.DXA,
          },
          shading: {
            type: ShadingType.CLEAR,
            color: "auto",
            fill: "FFFFFF", // For white background; change as needed
          },
        }),

        new TableCell({
          children: [new Paragraph({
            text: composite['tScore'] !== null ? String(composite['tScore']) : '',
            style: "tableContents",
            alignment: AlignmentType.CENTER,
          })],
          width: {
            size: cellWidths.column2,
            type: WidthType.DXA,
          },
          shading: {
            type: ShadingType.CLEAR,
            color: "auto",
            fill: "FFFFFF", // For white background; change as needed
          },
        }),

        new TableCell({
          children: [new Paragraph({
            text: composite['percentile'] !== null ? String(composite['percentile']) : (composite.outcome ? composite.outcome: ''),
            style: "tableContents",
            alignment: AlignmentType.CENTER,
          })],
          width: {
            size: cellWidths.column3,
            type: WidthType.DXA,
          },
          shading: {
            type: ShadingType.CLEAR,
            color: "auto",
            fill: "FFFFFF", // For white background; change as needed
          },
        }),
      ],
      cantSplit: true
    }));
  })
  return rows
}

export const getCognitiveTestingResults = (appendixData, subtestsByReportSection) => {
  var assessmentName = Object.keys(appendixData)
  if (assessmentName.length > 0){
    assessmentName = assessmentName[0]
  }
  else {
    return '';
  }

  const assessmentDetails = appendixData[assessmentName]
  const tableList = []

  var tableHeader = assessmentName;
  const assessmentAbbreviation = assessmentDetails.assessment_abbreviation || '';
  if (assessmentAbbreviation) {
    tableHeader += " (" + assessmentAbbreviation +")";
  }

  // First row: assessment name and abbreviation
  const assessmentHeaderRow = new TableRow({
    children:[
      new TableCell({
        children: [new Paragraph({
          children: [new TextRun({
            text: tableHeader,
            font: 'Arial',
            bold: true
          })],
        })],
        columnSpan: 3
      }),
    ],
  });

  const column1Header = 'Subtests'
  let column2Header = 'Standard Score'
  const column3Header= 'Percentile'

  if ('composites' in assessmentDetails){
    // Define table rows
    const compositeTableRows = [];
    compositeTableRows.push(assessmentHeaderRow)

    const compositeHeader = 'Composites'
    const compositeRows = getCompositeTableRows(assessmentDetails, compositeHeader, column2Header, column3Header)
    if (compositeRows.length > 0){
      compositeRows.forEach(compositeRow => {
        compositeTableRows.push(compositeRow)
      })
      const compositeTable = new Table({
        columnWidths: columnWidths,
        rows: compositeTableRows,
        layout: TableLayoutType.FIXED, // Ensure the table layout is fixed
        width: {
          size: pageWidthTwips,
          type: WidthType.DXA,
        },
      });
      tableList.push(compositeTable)
    }
  }

  // If we have the subtests associated with each composite, we break out the composites into tables, with each composite table listing scores
  // for its associated subtests. otherwise, we just display all subtests.
  if (hasValidAssociatedComposite(assessmentDetails)){
    column2Header = 'Scaled Score'
    const compositeNames = assessmentDetails.composites.map(composite => composite.compositeName)
    compositeNames.forEach(compositeName => {
      const compositeSubtests = assessmentDetails['subtests'].filter(subtest => subtest.compositeName === compositeName)
      if (compositeSubtests.length === 0){
        return;
      }

      let rows = [];

      // First row: composite name
      const headerRow = new TableRow({
        children:[
          new TableCell({
            children: [new Paragraph({
              children: [new TextRun({
                text: compositeName,
                font: 'Arial',
                bold: true
              })],
            })],
            columnSpan: 3
          }),
        ],
      });

      rows.push(headerRow)

      rows.push(new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph({
              children: [new TextRun({
                text: column1Header,
                font: 'Arial',
                bold: true
              })],
            })],
            width: {
              size: cellWidths.column1,
              type: WidthType.DXA,
            }
          }),
          new TableCell({
            children: [new Paragraph({
              children: [new TextRun({
                text: column2Header,
                font: 'Arial',
                bold: true
              })],
              alignment: AlignmentType.CENTER,
            })],
            width: {
              size: cellWidths.column2,
              type: WidthType.DXA,
            }
          }),
          new TableCell({
            children: [new Paragraph({
              children: [new TextRun({
                text: column3Header,
                font: 'Arial',
                bold: true
              })],
              alignment: AlignmentType.CENTER,
            })],
            width: {
              size: cellWidths.column3,
              type: WidthType.DXA,
            }
          }),
        ],
        cantSplit: true,
      }));

      compositeSubtests.forEach(subtest => {
        rows.push(new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph({
                text: subtest['subtest_name'] || '',
                style: "tableContents",
              })],
              width: {
                size: cellWidths.column1,
                type: WidthType.DXA,
              },
              shading: {
                type: ShadingType.CLEAR,
                color: "auto",
                fill: "FFFFFF", // For white background; change as needed
              },
            }),

            new TableCell({
              children: [new Paragraph({
                text: subtest['tScore'] !== null ? String(subtest['tScore']) : '',
                style: "tableContents",
                alignment: AlignmentType.CENTER,
              })],
              width: {
                size: cellWidths.column2,
                type: WidthType.DXA,
              },
              shading: {
                type: ShadingType.CLEAR,
                color: "auto",
                fill: "FFFFFF", // For white background; change as needed
              },
            }),

            new TableCell({
              children: [new Paragraph({
                text: subtest['percentile'] !== null ? String(subtest['percentile']) : '',
                style: "tableContents",
                alignment: AlignmentType.CENTER,
              })],
              width: {
                size: cellWidths.column3,
                type: WidthType.DXA,
              },
              shading: {
                type: ShadingType.CLEAR,
                color: "auto",
                fill: "FFFFFF", // For white background; change as needed
              },
            }),
          ],
          cantSplit: true
        }));
      })

      if (rows.length > 0){
        const subtestTable = new Table({
          columnWidths: columnWidths,
          rows: rows,
          layout: TableLayoutType.FIXED, // Ensure the table layout is fixed
          width: {
            size: pageWidthTwips,
            type: WidthType.DXA,
          },
        });
        tableList.push(subtestTable)
      }
    })
  }
  else {
    let rows = [];
    rows.push(assessmentHeaderRow)
    rows.push(new TableRow({
      children: [
        new TableCell({
          children: [new Paragraph({
            children: [new TextRun({
              text: 'Subtests',
              font: 'Arial',
              bold: true
            })],
          })],
          width: {
            size: cellWidths.column1,
            type: WidthType.DXA,
          }
        }),
        new TableCell({
          children: [new Paragraph({
            children: [new TextRun({
              text: 'Scaled Score',
              font: 'Arial',
              bold: true
            })],
            alignment: AlignmentType.CENTER,
          })],
          width: {
            size: cellWidths.column2,
            type: WidthType.DXA,
          }
        }),
        new TableCell({
          children: [new Paragraph({
            children: [new TextRun({
              text: 'Percentile',
              font: 'Arial',
              bold: true
            })],
            alignment: AlignmentType.CENTER,
          })],
          width: {
            size: cellWidths.column3,
            type: WidthType.DXA,
          }
        }),
      ],
      cantSplit: true,
    }));

    assessmentDetails.subtests.forEach(subtest => {
      rows.push(new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph({
              text: subtest['subtest_name'] || '',
              style: "tableContents",
            })],
            width: {
              size: cellWidths.column1,
              type: WidthType.DXA,
            },
            shading: {
              type: ShadingType.CLEAR,
              color: "auto",
              fill: "FFFFFF", // For white background; change as needed
            },
          }),

          new TableCell({
            children: [new Paragraph({
              text: subtest['tScore'] !== null ? String(subtest['tScore']) : '',
              style: "tableContents",
              alignment: AlignmentType.CENTER,
            })],
            width: {
              size: cellWidths.column2,
              type: WidthType.DXA,
            },
            shading: {
              type: ShadingType.CLEAR,
              color: "auto",
              fill: "FFFFFF", // For white background; change as needed
            },
          }),

          new TableCell({
            children: [new Paragraph({
              text: subtest['percentile'] !== null ? String(subtest['percentile']) : (subtest.outcome ? subtest.outcome: ''),
              style: "tableContents",
              alignment: AlignmentType.CENTER,
            })],
            width: {
              size: cellWidths.column3,
              type: WidthType.DXA,
            },
            shading: {
              type: ShadingType.CLEAR,
              color: "auto",
              fill: "FFFFFF", // For white background; change as needed
            },
          }),
        ],
        cantSplit: true
      }));
    });

    if (rows.length > 0){
      const subtestTable = new Table({
        columnWidths: columnWidths,
        rows: rows,
        layout: TableLayoutType.FIXED, // Ensure the table layout is fixed
        width: {
          size: pageWidthTwips,
          type: WidthType.DXA,
        },
      });
      tableList.push(subtestTable)
    }
  }
  return tableList
}

const getRatingScaleTables = (evalId, ratingScalesData) => {
  const tables = {}

  var completedRatingScales = Object.keys(ratingScalesData)
  const sectionHeader = 'RATING SCALES/QUESTIONNAIRES'

  const tableList = []

  completedRatingScales.forEach(ratingScale => {
    const ratingScaleData = ratingScalesData[ratingScale]
    ratingScaleData['forms'].forEach(form => {
      const formHeader = form.form_type + ' Form- ' + form.rater
      let rows = [];

      const headerRow = new TableRow({
        children:[
          new TableCell({
            children: [new Paragraph({
              children: [
                new TextRun({
                  text: ratingScale,
                  font: 'Arial',
                  bold: true
                })],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: formHeader,
                    font: 'Arial',
                    bold: true
                })],
              })
            ],
            columnSpan: 3
          }),
        ],
      });

      rows.push(headerRow)
      const column1Header = 'Scale/Index/Composite'
      const column2Header = 'T-Score'
      const column3Header= 'Percentile'

      rows.push(new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph({
              children: [new TextRun({
                text: column1Header,
                font: 'Arial',
                bold: true
              })],
            })],
            width: {
              size: cellWidths.column1,
              type: WidthType.DXA,
            }
          }),
          new TableCell({
            children: [new Paragraph({
              children: [new TextRun({
                text: column2Header,
                font: 'Arial',
                bold: true
              })],
              alignment: AlignmentType.CENTER,
            })],
            width: {
              size: cellWidths.column2,
              type: WidthType.DXA,
            }
          }),
          new TableCell({
            children: [new Paragraph({
              children: [new TextRun({
                text: column3Header,
                font: 'Arial',
                bold: true
              })],
              alignment: AlignmentType.CENTER,
            })],
            width: {
              size: cellWidths.column3,
              type: WidthType.DXA,
            }
          }),
        ],
        cantSplit: true,
      }));

      // Subsequent rows: subtest data values
      form.measures.forEach(measure => {
        rows.push(new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph({
                text: measure['measure_name'] || '',
                style: "tableContents",
              })],
              width: {
                size: cellWidths.column1,
                type: WidthType.DXA,
              },
              shading: {
                type: ShadingType.CLEAR,
                color: "auto",
                fill: "FFFFFF", // For white background; change as needed
              },
            }),

            new TableCell({
              children: [new Paragraph({
                text: measure['tScore'] !== null ? String(measure['tScore']) : '',
                style: "tableContents",
                alignment: AlignmentType.CENTER,
              })],
              width: {
                size: cellWidths.column2,
                type: WidthType.DXA,
              },
              shading: {
                type: ShadingType.CLEAR,
                color: "auto",
                fill: "FFFFFF", // For white background; change as needed
              },
            }),

            new TableCell({
              children: [new Paragraph({
                text: measure['percentile'] !== null ? String(measure['percentile']) : '',
                style: "tableContents",
                alignment: AlignmentType.CENTER,
              })],
              width: {
                size: cellWidths.column3,
                type: WidthType.DXA,
              },
              shading: {
                type: ShadingType.CLEAR,
                color: "auto",
                fill: "FFFFFF", // For white background; change as needed
              },
            }),
          ],
          cantSplit: true
        }));

      });

      if (rows.length>0){
        const table = new Table({
          columnWidths: columnWidths,
          rows: rows,
          layout: TableLayoutType.FIXED, // Ensure the table layout is fixed
          width: {
            size: pageWidthTwips,
            type: WidthType.DXA,
          },
        });
        tableList.push(table)
      }
    })
  })
  if (tableList.length > 0){
    tables[sectionHeader] = tableList
  }

  return tables
}

const getTestResultTables = (evalId, appendixData, subtestsByReportSection) => {
  const assessmentCompositesAddedList = []
  const tables = {}
  Object.keys(REPORT_SECTION_APPENDIX_HEADER_MAPPINGS).forEach(reportSection => {
    if (reportSection in ALTERNATIVE_REPORT_SECTION_NAME_MAPPINGS){
      reportSection = ALTERNATIVE_REPORT_SECTION_NAME_MAPPINGS[reportSection]
    }

    if (reportSection=== 'General Cognitive Abilities'){
      const sectionHeader = REPORT_SECTION_APPENDIX_HEADER_MAPPINGS[reportSection]
      var assessmentName = Object.keys(appendixData).filter(key => appendixData[key]['is_cognitive_ability_test'] === true);
      if (assessmentName.length > 0){
        assessmentName = assessmentName[0]
      }
      else {
        return;
      }

      const assessmentData = {}
      assessmentData[assessmentName] = appendixData[assessmentName]

      const tableList = getCognitiveTestingResults(assessmentData, subtestsByReportSection)

      if (tableList.length > 0){
        tables[sectionHeader] = tableList
      }
    }
    else {
      const sectionAssessments = Object.keys(subtestsByReportSection[reportSection])
      var completedSectionAssessments = Object.keys(appendixData).filter(key => appendixData[key]['is_cognitive_ability_test'] === false);
      completedSectionAssessments = completedSectionAssessments.filter(assessment => sectionAssessments.includes(assessment))
      const sectionHeader = REPORT_SECTION_APPENDIX_HEADER_MAPPINGS[reportSection]

      const tableList = []

      completedSectionAssessments.forEach(sectionAssessment => {
        let compositesIncluded = false;
        const assessmentSectionSubtests = subtestsByReportSection[reportSection][sectionAssessment]
        var sectionAssessmentData = appendixData[sectionAssessment]
        const completedSubtests = sectionAssessmentData.subtests.filter(subtest => assessmentSectionSubtests.includes(subtest.subtest_name))

        if (completedSubtests.length===0){
          return;
        }

        var tableHeader = sectionAssessment;
        const assessmentAbbreviation = sectionAssessmentData.assessment_abbreviation || '';
        if (assessmentAbbreviation){
          tableHeader += " (" + assessmentAbbreviation + ")"
        }

        // Define table rows
        let rows = [];

        // First row: assessment name and abbreviation
        const headerRow = new TableRow({
          children:[
            new TableCell({
              children: [new Paragraph({
                children: [new TextRun({
                  text: tableHeader,
                  font: 'Arial',
                  bold: true
                })],
              })],
              columnSpan: 3
            }),
          ],
        });

        rows.push(headerRow)

        if (('composites' in sectionAssessmentData) && !assessmentCompositesAddedList.includes(sectionAssessment)){
          if (sectionAssessmentData['composites'].length > 0){
            const compositeRows = getCompositeTableRows(sectionAssessmentData, 'Composite/Subtest', 'Standard/Scaled Score', 'Percentile')
            compositeRows.forEach(compositeRow => {
              rows.push(compositeRow)
            })
            assessmentCompositesAddedList.push(sectionAssessment)
            compositesIncluded = true
          }
        }
        if (completedSubtests.filter(subtest => subtest.has_measures===true).length > 0){
          completedSubtests.forEach((subtest, index) => {
            if (subtest.has_measures === false) {
              rows.push(new TableRow({
                children:[
                  new TableCell({
                    children: [new Paragraph({
                      children: [new TextRun({
                        text: subtest['subtest_name'] || '',
                        font: 'Arial',
                        bold: true
                      })],
                    })],
                    columnSpan: 3,
                    shading: {
                      fill: "#dbdcdc",
                    }
                  }),
                ],
              }));
              rows.push(
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          text: subtest.subtest_name || '',
                          style: "tableContents",
                        }),
                      ],
                      width: {
                        size: cellWidths.column1,
                        type: WidthType.DXA,
                      },
                      shading: {
                        type: ShadingType.CLEAR,
                        color: "auto",
                        fill: "FFFFFF", // For white background; change as needed
                      },
                    }),

                    new TableCell({
                      children: [
                        new Paragraph({
                          text: subtest.tScore !== null ? String(subtest.tScore) : '',
                          style: "tableContents",
                          alignment: AlignmentType.CENTER,
                        }),
                      ],
                      width: {
                        size: cellWidths.column2,
                        type: WidthType.DXA,
                      },
                      shading: {
                        type: ShadingType.CLEAR,
                        color: "auto",
                        fill: "FFFFFF", // For white background; change as needed
                      },
                    }),

                    new TableCell({
                      children: [
                        new Paragraph({
                          text: subtest.percentile !== null ? String(subtest.percentile) : '',
                          style: "tableContents",
                          alignment: AlignmentType.CENTER,
                        }),
                      ],
                      width: {
                        size: cellWidths.column3,
                        type: WidthType.DXA,
                      },
                      shading: {
                        type: ShadingType.CLEAR,
                        color: "auto",
                        fill: "FFFFFF", // For white background; change as needed
                      },
                    }),
                  ],
                })
              );
            }
            else {
              if (subtest['subtest_name'] !== sectionAssessment){
                rows.push(new TableRow({
                  children:[
                    new TableCell({
                      children: [new Paragraph({
                        children: [new TextRun({
                          text: subtest['subtest_name'] || '',
                          font: 'Arial',
                          bold: true
                        })],
                      })],
                      columnSpan: 3,
                      shading: {
                        fill: "#dbdcdc",
                      }
                    }),
                  ],
                }));
              }

              var measureTScores = subtest.measures.map(measure => measure.tScore)
              measureTScores  = measureTScores.filter(tScore => { return tScore!==null })

              var measurePercentiles = subtest.measures.map(measure => measure.percentile)
              measurePercentiles = measurePercentiles.filter(percentile => { return percentile!==null })
              if (measureTScores.length===0 && measurePercentiles.length===0) {
                subtest.measures.forEach(measure => {
                  if (measure.outcome !== null){
                    rows.push(new TableRow({
                      children: [
                        new TableCell({
                          children: [new Paragraph({ text: measure.measure_name, style: "tableContents"})],
                          columnSpan: 1
                        }),
                        new TableCell({
                          children: [new Paragraph({ text: measure.outcome, style: "tableContents"})],
                          columnSpan: 2
                        })
                      ]
                    }))
                  }
                  else {
                    return
                  }
                })
              }

              else {
                const column1Header = 'Measure'
                const column2Header = 'Standard/Scaled Score'
                const column3Header = 'Percentile'

                rows.push(new TableRow({
                  children: [
                    new TableCell({
                      children: [new Paragraph({
                        children: [new TextRun({
                          text: column1Header,
                          font: 'Arial',
                          bold: true
                        })],
                      })],
                      width: {
                        size: cellWidths.column1,
                        type: WidthType.DXA,
                      }
                    }),
                    new TableCell({
                      children: [new Paragraph({
                        children: [new TextRun({
                          text: column2Header,
                          font: 'Arial',
                          bold: true
                        })],
                        alignment: AlignmentType.CENTER,
                      })],
                      width: {
                        size: cellWidths.column2,
                        type: WidthType.DXA,
                      }
                    }),
                    new TableCell({
                      children: [new Paragraph({
                        children: [new TextRun({
                          text: column3Header,
                          font: 'Arial',
                          bold: true
                        })],
                        alignment: AlignmentType.CENTER,
                      })],
                      width: {
                        size: cellWidths.column3,
                        type: WidthType.DXA,
                      }
                    }),
                  ],
                  cantSplit: true,
                }));
                subtest.measures.forEach(measure => {
                  if ((measure.percentile !== null) || (measure.tScore !== null) || (measure.outcome !== null)){
                    rows.push(new TableRow({
                      children: [
                        new TableCell({
                          children: [new Paragraph({
                            text: measure.measure_name || '',
                            style: "tableContents",
                          })],
                          width: {
                            size: cellWidths.column1,
                            type: WidthType.DXA,
                          },
                          shading: {
                            type: ShadingType.CLEAR,
                            color: "auto",
                            fill: "FFFFFF", // For white background; change as needed
                          },
                        }),

                        new TableCell({
                          children: [new Paragraph({
                            text: measure.tScore !== null ? String(measure.tScore) : '',
                            style: "tableContents",
                            alignment: AlignmentType.CENTER,
                          })],
                          width: {
                            size: cellWidths.column2,
                            type: WidthType.DXA,
                          },
                          shading: {
                            type: ShadingType.CLEAR,
                            color: "auto",
                            fill: "FFFFFF", // For white background; change as needed
                          },
                        }),

                        new TableCell({
                          children: [new Paragraph({
                            text: measure.percentile !== null ? String(measure.percentile) : (measure.outcome ? measure.outcome : ''),
                            style: "tableContents",
                            alignment: AlignmentType.CENTER,
                          })],
                          width: {
                            size: cellWidths.column3,
                            type: WidthType.DXA,
                          },
                          shading: {
                            type: ShadingType.CLEAR,
                            color: "auto",
                            fill: "FFFFFF", // For white background; change as needed
                          },
                        }),
                      ],
                      cantSplit: true
                    }));
                  }
                })
              }
            }}
          )
          if (rows.length>0){
            const table = new Table({
              columnWidths: columnWidths,
              rows: rows,
              layout: TableLayoutType.FIXED, // Ensure the table layout is fixed
              width: {
                size: pageWidthTwips,
                type: WidthType.DXA,
              },
            });
            tableList.push(table)
          }
        }


        else {
          var tScores = completedSubtests.map(subtest => subtest.tScore)
          tScores = tScores.filter(tScore => { return tScore!==null })

          var percentiles = completedSubtests.map(subtest => subtest.percentile)
          percentiles = percentiles.filter(percentile => { return percentile!==null })
          if (tScores.length===0 && percentiles.length===0){
            if (completedSubtests.length > 1){
              completedSubtests.forEach(subtest => {
                rows.push(new TableRow({
                  children: [
                    new TableCell({
                      children: [new Paragraph({ text: subtest.subtest_name, style: "tableContents"})],
                      columnSpan: 1
                    }),
                    new TableCell({
                      children: [new Paragraph({ text: subtest.outcome, style: "tableContents"})],
                      columnSpan: 2
                    }),
                  ],
                }))
              })
            }
            else {
              const outcome = completedSubtests[0].outcome
              rows.push(new TableRow({
                children: [
                  new TableCell({
                    children: [new Paragraph({ text: outcome, style: "tableContents"})],
                    columnSpan: 3
                  }),
                ],
              }))
            }
          }
          else {
            if (!compositesIncluded){
              const column1Header = 'Subtests'
              const column2Header = 'Standard/Scaled Score'
              const column3Header= 'Percentile'

              rows.push(new TableRow({
                children: [
                  new TableCell({
                    children: [new Paragraph({
                      children: [new TextRun({
                        text: column1Header,
                        font: 'Arial',
                        bold: true
                      })],
                    })],
                    width: {
                      size: cellWidths.column1,
                      type: WidthType.DXA,
                    }
                  }),
                  new TableCell({
                    children: [new Paragraph({
                      children: [new TextRun({
                        text: column2Header,
                        font: 'Arial',
                        bold: true
                      })],
                      alignment: AlignmentType.CENTER,
                    })],
                    width: {
                      size: cellWidths.column2,
                      type: WidthType.DXA,
                    }
                  }),
                  new TableCell({
                    children: [new Paragraph({
                      children: [new TextRun({
                        text: column3Header,
                        font: 'Arial',
                        bold: true
                      })],
                      alignment: AlignmentType.CENTER,
                    })],
                    width: {
                      size: cellWidths.column3,
                      type: WidthType.DXA,
                    }
                  }),
                ],
                cantSplit: true,
              }));
            }

            // Subsequent rows: subtest data values
            completedSubtests.forEach(subtest => {
              rows.push(new TableRow({
                children: [
                  new TableCell({
                    children: [new Paragraph({
                      text: subtest['subtest_name'] || '',
                      style: "tableContents",
                    })],
                    width: {
                      size: cellWidths.column1,
                      type: WidthType.DXA,
                    },
                    shading: {
                      type: ShadingType.CLEAR,
                      color: "auto",
                      fill: "FFFFFF", // For white background; change as needed
                    },
                  }),

                  new TableCell({
                    children: [new Paragraph({
                      text: subtest['tScore'] !== null ? String(subtest['tScore']) : '',
                      style: "tableContents",
                      alignment: AlignmentType.CENTER,
                    })],
                    width: {
                      size: cellWidths.column2,
                      type: WidthType.DXA,
                    },
                    shading: {
                      type: ShadingType.CLEAR,
                      color: "auto",
                      fill: "FFFFFF", // For white background; change as needed
                    },
                  }),

                  new TableCell({
                    children: [new Paragraph({
                      text: subtest['percentile'] !== null ? String(subtest['percentile']) : (subtest.outcome ? subtest.outcome: ''),
                      style: "tableContents",
                      alignment: AlignmentType.CENTER,
                    })],
                    width: {
                      size: cellWidths.column3,
                      type: WidthType.DXA,
                    },
                    shading: {
                      type: ShadingType.CLEAR,
                      color: "auto",
                      fill: "FFFFFF", // For white background; change as needed
                    },
                  }),
                ],
                cantSplit: true
              }));
            });
          }

          if (rows.length>0){
            const table = new Table({
              columnWidths: columnWidths,
              rows: rows,
              layout: TableLayoutType.FIXED, // Ensure the table layout is fixed
              width: {
                size: pageWidthTwips,
                type: WidthType.DXA,
              },
            });
            tableList.push(table)
          }
        }
      })
      if (tableList.length > 0){
        tables[sectionHeader] = tableList
      }
    }
  })
  return tables
}

export const createAppendix = (evalId, appendixData, ratingScalesData, subtestsByReportSection) => {
  const docContent = []

  const testResultsTables = getTestResultTables(evalId, appendixData, subtestsByReportSection)

  let ratingScaleTables = [];
  if (Object.keys(ratingScalesData).length > 0) {
    ratingScaleTables = getRatingScaleTables(evalId, ratingScalesData, subtestsByReportSection)
  }

  Object.keys(testResultsTables).forEach(section => {
    const sectionContent = new Paragraph({
      children: [
        new TextRun({
          text: section,
          bold: true,
          font: 'Arial',
          break: 1,

        }),
      ],
    })
    docContent.push(sectionContent)
    testResultsTables[section].forEach(table => {
      docContent.push(new Paragraph({}))
      docContent.push(table)
    })
  })


  if (Object.keys(ratingScaleTables).length > 0) {
    Object.keys(ratingScaleTables).forEach(section => {
      const sectionContent = new Paragraph({
        children: [
          new TextRun({
            text: section,
            font: 'Arial',
            bold: true,
            break: 1,

          }),
        ],
      })
      docContent.push(sectionContent)
      ratingScaleTables[section].forEach(table => {
        docContent.push(new Paragraph({}))
        docContent.push(table)
      })
    })
  }

  const doc = new Document({
      styles: {
        paragraphStyles: [
            {
                id: "tableContents",
                name: "tableContents",
                run: {
                    font: "Arial",
                    size:"10pt",
                },
            },
        ]
      },
      sections: [
          {
            children: [
                ...docContent
            ],
          },
      ],
  });

  const filename = "neuroaide_eval_" + evalId.toString() + "_appendix.docx"

  Packer.toBlob(doc).then(blob => {
    saveAs(blob, filename);
  }).catch(error => {
    console.error("Error creating document:", error);
  });
};
