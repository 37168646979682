import React, { Component } from "react";
import { connect } from "react-redux";

import withNavigateHook from '../../shared/withNavigateHook';
import { subtestDirectory, compositeDirectory } from '../../actions';

import Layout from '../../shared/Layout';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';

import Papa from 'papaparse';

const styles = ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    textAlign: 'center',
    overflow: 'hidden',
    marginBottom: '36px'
  },
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  assessmentMenuDiv: {
    marginTop: '30px',
    display: 'flex',
    alignItems: 'center',
    width: '70%',
    marginLeft: '5%',
    marginRight: '5%',
    marginBottom: '10px'
  },
  paper: {
    marginTop: '40px',
    marginLeft: '5%',
    marginRight: '5%',
    width: '70%',
    alignItems: 'center',
    display: 'inline-block'
  },
  assessmentTitle: {
    textAlign: 'center',
    marginBottom: '15px',
    marginTop: '15px',
    fontFamily: 'Raleway',
    fontSize: 20,
  },
  assessmentsAddedTitle: {
    textAlign: 'center',
    marginBottom: '15px',
    marginTop: '5px',
    fontFamily: 'Raleway',
    fontSize: 24,
  },
  assessmentsAddedAssessment: {
    fontFamily: 'Raleway',
    fontSize: 16,
  },
  assessmentsAddedSubtest: {
    fontFamily: 'Raleway',
    fontSize: 14,
  },
  headerText: {
    textAlign: 'center',
    marginLeft: '25%',
    marginRight: '25%',
    marginTop: '20px',
    marginBottom: '24px',
    fontFamily: 'Comfortaa',
    fontSize: 48,
    fontWeight: 200
  },
  gridContainer: {
    width: '80%',
    margin: '20px auto',
    overflow: 'hidden'
  },
  customHeader: {
    backgroundColor: '#f5f5f5',
    height: '60px',
    padding: '10px',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '16px'
  },
  columnHeader: {
    fontWeight: 'bold',
  },
  cellBorder: {
    border: '.5px solid rgba(224, 224, 224, 1)', // You can customize the border color and style as needed
  }
});

class AdminAssessmentUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAssessment: null,
      assessments: [],
      validationErrors: [],
      onFormReview: null,
      loading: true,
      dataLoaded: false,
    };
    this.handleFileUpload = this.handleFileUpload.bind(this);
  }


  componentDidMount() {
    // Fetch directories
    this.props.fetchSubtestDirectory();
    this.props.fetchCompositeDirectory();

    const { user } = this.props;

    // Load data from localStorage if not a demo account
    const savedAssessments = localStorage.getItem('appendixData');
    if (savedAssessments) {
      this.setState({ assessments: JSON.parse(savedAssessments) });
    }

    let onFormReview = localStorage.getItem('onFormReview');
    if (onFormReview) {
      onFormReview = JSON.parse(onFormReview);
      this.setState({ onFormReview: onFormReview });
    }

    // Check if user is loaded and if the user is a demo account
    if (user && user.isLoaded && user.user.email) {
      this.setState({ loading: false });
    }
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    // Check if user prop has changed
    if (prevProps.user !== user && user.isLoaded) {
      this.setState({ loading: false });
    }
  }

  handleFileUpload(event) {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const data = results.data;
          this.setState({ data }, () => {
            const parsedData = this.parseUploadData(data);
            this.setState({
              assessments: parsedData,
              selectedAssessment: null,
              dataLoaded: true,
            }, this.saveAssessmentsToLocalStorage);
          })
        },
        skipEmptyLines: true,
      });
    }
  }

  parseUploadData(data) {
    const assessmentDataParsed = data.reduce((acc, row) => {
      const assessmentName = row.assessment_name;
      if (!assessmentName){
        return acc
      }
      let subtest = {};
      if (this.props.subtestDirectory[assessmentName]) {
        subtest = this.props.subtestDirectory[assessmentName].subtests.filter(s => s.subtest_name === row.subtest_name);
      }

      let hasMeasures = false;
      if (subtest.length > 0) {
        hasMeasures = subtest[0].has_measures;
      }

      // If there are multiple rows with the same subtest name, consider it as having measures
      if (data.filter(r => r.subtest_name === row.subtest_name).length > 1) {
        hasMeasures = true;
      }

      if (!acc[assessmentName]) {
        acc[assessmentName] = {
          name: assessmentName,
          subtests: []
        };
      }

      if (this.hasComposites(assessmentName)) {
        if (!acc[assessmentName].composites){
          acc[assessmentName].composites = []
          acc[assessmentName].hasComposites = true
        }
        if (this.props.compositeDirectory[assessmentName]?.includes(row.subtest_name)) {
          const compositeDict = {
            name: row.subtest_name,
            percentile: row.percentile || null,
            tScore: row.t_score || null,
            outcome: row.assessment_result || null
          };
          acc[assessmentName].composites.push(compositeDict);
        } else {
          const subtestIndex = acc[assessmentName].subtests.findIndex(s => s.name === row.subtest_name);
          if (subtestIndex === -1) {
            acc[assessmentName].subtests.push({
              name: row.subtest_name,
              has_measures: hasMeasures,
              measures: hasMeasures ? [{
                measureName: row.measure_name,
                percentile: row.percentile || null,
                tScore: row.t_score || null,
                outcome: row.assessment_result || null
              }] : [],
              percentile: hasMeasures ? null : row.percentile || null,
              tScore: hasMeasures ? null : row.t_score || null,
              outcome: hasMeasures ? null : row.assessment_result || null
            });
          } else {
            acc[assessmentName].subtests[subtestIndex].percentile = row.percentile || null;
            acc[assessmentName].subtests[subtestIndex].tScore = row.t_score || null;
            acc[assessmentName].subtests[subtestIndex].outcome = row.assessment_result || null;
          }
        }
      } else {
        const subtestIndex = acc[assessmentName].subtests.findIndex(s => s.name === row.subtest_name);
        if (subtestIndex === -1) {
          acc[assessmentName].subtests.push({
            name: row.subtest_name,
            has_measures: hasMeasures,
            measures: hasMeasures ? [{
              measureName: row.measure_name,
              percentile: row.percentile || null,
              tScore: row.t_score || null,
              outcome: row.assessment_result || null
            }] : [],
            percentile: hasMeasures ? null : row.percentile || null,
            tScore: hasMeasures ? null : row.t_score || null,
            outcome: hasMeasures ? null : row.assessment_result || null
          });
        } else {
          if (hasMeasures) {
            acc[assessmentName].subtests[subtestIndex].measures.push({
              measureName: row.measure_name,
              percentile: row.percentile || null,
              tScore: row.t_score || null,
              outcome: row.assessment_result || null
            });
          } else {
            acc[assessmentName].subtests[subtestIndex].percentile = row.percentile || null;
            acc[assessmentName].subtests[subtestIndex].tScore = row.t_score || null;
            acc[assessmentName].subtests[subtestIndex].outcome = row.assessment_result || null;
          }
        }
      }

      return acc;
    }, {});

    const assessmentList = Object.values(assessmentDataParsed);
    return assessmentList
  }

  saveAssessmentsToLocalStorage = () => {
    const { assessments } = this.state;
    localStorage.setItem('appendixDataValidated', JSON.stringify(this.formIsValid()));
    localStorage.setItem('appendixData', JSON.stringify(assessments));
  }

  getGridRows = (assessmentName, assessmentIndex) => {
    var selectedAssessment = this.state.assessments[assessmentIndex];

    let rows = [];

    let boldSubtest = false
    if (  selectedAssessment.subtests.filter(subtest => subtest.has_measures===true).length > 0){
      boldSubtest = true
    }

    selectedAssessment.subtests.forEach(subtest => {
      var isEditable = !(subtest.has_measures===true)
      rows.push({
        id: `${assessmentName}-${subtest['name']}`,
        testName: subtest['name'],
        score: subtest.tScore || null,
        percentile: subtest.percentile || null,
        outcome: subtest.outcome || null,
        isBold: boldSubtest,
        isEditable: isEditable
      });

      if (subtest.has_measures) {
        subtest.measures.forEach(measure => {
          rows.push({
            id: `${subtest.name}-${measure.measureName}`,
            testName: measure.measureName,
            score: measure.tScore || null,
            percentile: measure.percentile || null,
            outcome: measure.outcome || null,
            indentLevel: 1,
            isEditable: true
          });
        });
      }
    });

    return rows;
  }

  getCompositeGridRows = (assessmentName, assessmentIndex) => {
    var selectedAssessment = this.state.assessments[assessmentIndex];

    let rows = [];

    selectedAssessment.composites.forEach(composite => {
      rows.push({
        id: `${assessmentName}-${composite.name}`,
        testName: composite.name,
        score: composite.tScore || null,
        percentile: composite.percentile || null,
        outcome: composite.outcome || null
      });
    });

    return rows;
  }

  handleAssessmentChange = (newValue) => {
    if (newValue) {
      this.setState({ selectedAssessment: newValue.name });
    } else {
      this.setState({ selectedAssessment: null });
    }
  }

  processRowUpdate = (newRow, oldRow, name) => {
    this.setState(prevState => {
      const updatedAssessments = [...prevState.assessments];
      const updatedAssessmentIndex = updatedAssessments.findIndex(assessment =>
        assessment.name===name && (assessment.subtests.some(subtest => subtest.name === oldRow.testName || subtest.measures.some(measure => measure.measureName === oldRow.testName)))
      );

      if (updatedAssessmentIndex !== -1) {
        const updatedAssessment = updatedAssessments[updatedAssessmentIndex];
        const subtestIndex = updatedAssessment.subtests.findIndex(subtest => subtest.name === oldRow.testName);

        if (subtestIndex !== -1) {
          // Update subtest
          updatedAssessment.subtests[subtestIndex] = {
            ...updatedAssessment.subtests[subtestIndex],
            name: newRow.testName,
            percentile: newRow.percentile,
            tScore: newRow.score,
            outcome: newRow.outcome
          };
        } else {
          // Update measure
          updatedAssessment.subtests.forEach(subtest => {
            const measureIndex = subtest.measures.findIndex(measure => measure.measureName === oldRow.testName);
            if (measureIndex !== -1) {
              subtest.measures[measureIndex] = {
                measureName: newRow.testName,
                percentile: newRow.percentile,
                tScore: newRow.score,
                outcome: newRow.outcome
              };
            }
          });
        }
        updatedAssessments[updatedAssessmentIndex] = updatedAssessment;
      }
      return { assessments: updatedAssessments };
    }, this.saveAssessmentsToLocalStorage);
    return newRow;
  };

  processCompositeRowUpdate = (newRow, oldRow, name) => {
    this.setState(prevState => {
      const updatedAssessments = [...prevState.assessments];
      const updatedAssessmentIndex = updatedAssessments.findIndex(assessment =>
        assessment.name===name && assessment.composites.some(composite => composite.name === oldRow.testName)
      );

      if (updatedAssessmentIndex !== -1) {
        const updatedAssessment = updatedAssessments[updatedAssessmentIndex];
        const compositeIndex = updatedAssessment.composites.findIndex(composite => composite.name === oldRow.testName);

        if (compositeIndex !== -1) {
          // Update composite
          updatedAssessment.composites[compositeIndex] = {
            ...updatedAssessment.composites[compositeIndex],
            percentile: newRow.percentile,
            tScore: newRow.score,
            outcome: newRow.outcome
          };
        }
        updatedAssessments[updatedAssessmentIndex] = updatedAssessment;
      }
      return { assessments: updatedAssessments };
    }, this.saveAssessmentsToLocalStorage);
    return newRow;
  };

  validateAssessments = () => {
    const { assessments } = this.state;

    let validationErrors = []; // To store validation errors

    if (assessments.length===0){
      validationErrors.push('No assessments have been added to the appendix. Please add all assessments that were administered.');
    }
    assessments.forEach((assessment) => {
      // Check for valid assessment name
      if(!this.props.subtestDirectory[assessment.name]){
        validationErrors.push(`Invalid assessment name: ${assessment.name}`);
      }
      // check for empty assessment (no scores)
      var emptyAssessment = true
      assessment.subtests.forEach(subtest => {
        if (subtest.percentile || subtest.outcome){
          emptyAssessment = false
        }
        if (subtest.has_measures === true){
          subtest.measures.forEach(measure => {
            if (measure.percentile || measure.outcome){
              emptyAssessment = false
            }
          })
        }
      })
      if (emptyAssessment === true) {
        // If an assessment has no subtests added, log an error
        validationErrors.push(`Assessment "${assessment.name}" has no subtests with a percentile or result added. Please add a value for at least one subtest or remove the assessment.`);
      }

      if ((this.hasComposites(assessment.name)===true) && (assessment.composites.length>0)) {
        assessment.composites.forEach(composite => {

          // Validate composite percentile value if it exists
          if (composite.percentile !== null && composite.percentile !== '') {
            const percentile = parseFloat(composite.percentile);
            if (isNaN(percentile) || percentile < 0 || percentile >= 100) {
              validationErrors.push(`Assessment "${assessment.name}", composite "${composite.name}" has an invalid percentile value. Percentile must be a greater than or equal to 0 and less than 100.`);
            }
          }

          // Validate composite tScore value if it exists
          if (composite.tScore !== null && composite.tScore !== '') {
            const tScore = parseFloat(composite.tScore);
            if (isNaN(tScore)) {
              validationErrors.push(`Assessment "${assessment.name}", composite "${composite.name}" has an invalid Standard Score value. Standard Score must be a numeric value.`);
            }
          }

          // Validate composite has percentile or result value if it exists
          if ((composite.tScore !== null && composite.tScore!== '') && ((!composite.percentile || composite.percentile === '') && (!composite.outcome || composite.percentile === ''))) {
            validationErrors.push(`Assessment "${assessment.name}", composite "${composite.name}" needs a percentile or an outcome and is missing both.`);
          }
        })
      }

      const duplicateSubtests = []
      // subtests without outcomes measures
      assessment.subtests.filter(subtest => subtest.has_measures===false).forEach(subtest => {
        // check for duplicate subtest names
        if (assessment.subtests.filter(s => s.name===subtest.name).length > 1){
          if (!duplicateSubtests.includes(subtest.name)){
            duplicateSubtests.push(subtest.name)
            validationErrors.push(`DUPLICATE SUBTEST: Assessment "${assessment.name}", subtest "${subtest.name}"`);
          }
        }
        // Check for invalid subtest name
        if (!this.props.subtestDirectory[assessment.name].subtests.filter(s => s.subtest_name === subtest.name).length > 0){
          validationErrors.push(`INVALID SUBTEST NAME: Assessment "${assessment.name}", subtest "${subtest.name}"`);
        }
        if (subtest.tScore && !subtest.percentile && !subtest.outcome) {
          // If both percentile and outcome are missing, log an error
          validationErrors.push(`Assessment "${assessment.name}", subtest "${subtest.name}" needs a percentile or an outcome and is missing both.`);
        }

        // Validate percentile value if it exists
        if (subtest.percentile !== null && subtest.percentile !== '') {
          const percentile = parseFloat(subtest.percentile);
          if (isNaN(percentile) || percentile < 0 || percentile >= 100) {
            validationErrors.push(`Assessment "${assessment.name}", Subtest "${subtest.name}" has an invalid percentile value. Percentile must be a number greater than or equal to 0 and less than 100.`);
          }
        }
        // Validate tScore value if it exists
        if (subtest.tScore !== null && subtest.tScore !== '') {
          const tScore = parseFloat(subtest.tScore);
          if (isNaN(tScore)) {
            validationErrors.push(`Assessment "${assessment.name}", Subtest "${subtest.name}" has an invalid Score value. Score must be a numeric value.`);
          }
        }
      });

      //subtests with measures
      assessment.subtests.filter(subtest => subtest.has_measures===true).forEach(subtest => {
        // Check for invalid subtest name
        const subtestIndex = this.props.subtestDirectory[assessment.name].subtests.findIndex(s => s.subtest_name === subtest.name)
        if (subtestIndex === -1){
          validationErrors.push(`INVALID SUBTEST NAME: Assessment "${assessment.name}", subtest "${subtest.name}"`);
        }
        const duplicateMeasures = []
        subtest.measures.forEach(measure => {
          // Check for invalid measure name
          if (subtestIndex !== -1){
            if (!this.props.subtestDirectory[assessment.name].subtests[subtestIndex].measures.includes(measure.measureName)){
              validationErrors.push(`INVALID MEASURE NAME: Assessment "${assessment.name}", subtest "${subtest.name}": ${measure.measureName}`);
            }
          }

          // check for duplicate measure names
          if (subtest.measures.filter(m => m.measureName===measure.measureName).length > 1){
            if (!duplicateMeasures.includes(measure.measureName)){
              duplicateMeasures.push(measure.measureName)
              validationErrors.push(`DUPLICATE MEASURE: Assessment "${assessment.name}", subtest "${subtest.name}": ${measure.measureName}`);
            }
          }

          if (!measure.percentile && !measure.outcome && measure.tScore) {
            // If both percentile and outcome are missing, log an error
            validationErrors.push(`${subtest.name}" (${assessment.name}), measure "${measure.measureName}" has a score value, but needs a percentile or an outcome and is missing both. Add a percentile or outcome, or remove the score to exclude the measure.`);
          }

          // Validate percentile value if it exists
          if (measure.percentile !== null && measure.percentile !== '') {
            const percentile = parseFloat(measure.percentile);
            if (isNaN(percentile) || percentile < 0 || percentile >= 100) {
              validationErrors.push(`Subtest "${subtest.name}" (${assessment.name}), measure "${measure.measureName}" has an invalid percentile value. Percentile must be a number greater than or equal to 0 and less than 100.`);
            }
          }
          // Validate tScore value if it exists
          if (measure.tScore !== null && measure.tScore !== '') {
            const tScore = parseFloat(measure.tScore);
            if (isNaN(tScore)) {
              validationErrors.push(`Subtest "${subtest.name}" (${assessment.name}), measure "${measure.measureName}" has an invalid Score value. Score must be a numeric value.`);
            }
          }
        })
      })
    });

    return validationErrors;
  };

  handleDeleteAssessment = (assessmentName) => {
    const updatedAssessments = this.state.assessments.filter(assessment => assessment.name !== assessmentName);
    this.setState({ assessments: updatedAssessments }, this.saveAssessmentsToLocalStorage);
  };

  hasComposites = (assessmentName) => {
    if (assessmentName in this.props.compositeDirectory){
      return true;
    }
    return false;
  };

  formIsValid = () => {
    const validationErrors = this.validateAssessments();
    return validationErrors.length === 0;
  }

  handleSubmit = () => {
    const validationErrors = this.validateAssessments();
    if (validationErrors.length > 0) {
      this.setState({ validationErrors });
      return;
    }
    if (validationErrors.length === 0) {
      localStorage.setItem('appendixDataValidated', JSON.stringify(this.formIsValid()));
    }

    this.setState({ validationErrors: [] }, () => {
      if (this.state.onFormReview === true) {
        return this.props.navigation('/generatereport/review');
      }
      this.props.navigation('/adminratingscaleuploader');
    });
  };

  renderAssessmentsList = () => {
    const { assessments } = this.state;

    if (assessments.length === 0) {
      return null; // Don't render the section if there are no assessments
    }

    return (
      <div>
        <Paper elevation={0} variant="outlined" style={{ padding: '20px', marginTop: '30px' }}>
          <Typography style={styles.assessmentTitle}>
            Assessments Added
          </Typography>
          {assessments.map((assessment, index) => (
            <div key={index} style={{ textAlign: 'left', marginBottom: '10px' }}>
              <Typography style={styles.assessmentsAddedAssessment}>{assessment.name}</Typography>
              <ul style={{ margin: 0 }}>
                {assessment.subtests.filter(subtest => subtest.percentile || subtest.outcome).map((subtest, subIndex) => (
                  <li key={subIndex} style={styles.assessmentsAddedSubtest}>{subtest.name}</li>
                ))}
              </ul>
            </div>
          ))}
        </Paper>
      </div>
    );
  };

  renderAssessmentGrid = (assessment, assessmentIndex) => {
    const { name } = assessment;
    const rows = this.getGridRows(name, assessmentIndex);

    const headerRowHeight = 56;
    const rowHeight = 40; // Define the height of each row
    var gridHeight = rowHeight * rows.length + headerRowHeight

    let compositeGridHeight = null;
    if (this.hasComposites(name)) {
      compositeGridHeight = this.getCompositeGridRows(name, assessmentIndex).length * rowHeight + headerRowHeight
    }

    let hideFooter = false;
    if (rows.length < 100){
      hideFooter = true
    }

    const columns = [
      {
        field: 'testName',
        headerName: 'Subtest/Measure',
        editable: true,
        flex: 2,
        renderCell: (params) => {
          const indentLevel = params.row.indentLevel || 0; // Adjust the property name as needed
          const isBold = params.row.isBold || false; // Add a property to conditionally bold text
          return (
            <div style={{
              paddingLeft: indentLevel * 20,
              fontWeight: isBold ? 'bold' : 'normal' // Conditionally set font weight
            }}>
              {params.value}
            </div>
          );
        },
      },
      { field: 'score', headerName: 'Score (T, standard, scaled, Z)', editable: true, flex: 1 },
      { field: 'percentile', headerName: 'Percentile', editable: true, flex: 1 },
      { field: 'outcome', headerName: 'Interpretation/Other Result (if applicable)', editable: true, flex: 2 }
    ];

    const compositeColumns = [
      { field: 'testName', headerName: 'Index/Composite', editable: false, flex: 2},
      { field: 'score', headerName: 'Standard Score', editable: true, flex: 1 },
      { field: 'percentile', headerName: 'Percentile', editable: true, flex: 1 },
      { field: 'outcome', headerName: 'Interpretation/Other Result (if applicable)', editable: true, flex: 2 }
    ];

    return (
      <div style={styles.gridContainer}>
        <div style={styles.customHeader}>
          <Typography sx={styles.assessmentTitle}>
            {name}
            <IconButton
              aria-label="delete assessment"
              onClick={() => this.handleDeleteAssessment(assessment.name)}
              sx={styles.deleteButton}
            >
              <DeleteIcon />
            </IconButton>
          </Typography>
        </div>
        <div>
          {(this.hasComposites(name) && assessment.composites.length>0) &&
            <div>
              <DataGrid
                columns={compositeColumns}
                rows={this.getCompositeGridRows(name, assessmentIndex)}
                style={{ height: compositeGridHeight }} // Set the grid height dynamically
                processRowUpdate={(oldRow, newRow) => this.processCompositeRowUpdate(oldRow, newRow, name)}
                hideFooter={hideFooter}
                disableRowSelectionOnClick
                disableColumnMenu
                rowHeight={rowHeight}
                sx={{
                   '& .MuiDataGrid-columnHeaderTitle': styles.columnHeader,
                   '& .MuiDataGrid-cell': styles.cellBorder,
                   '& .MuiDataGrid-row': {
                     borderBottom: '.5px solid rgba(224, 224, 224, 1)', // Add bottom border to each row
                   }
                 }}
                componentsProps={{
                  columnHeaders: {
                    style: styles.columnHeader,
                  },
                  cell: {
                    disableColumnMenu: true
                  },
                  columnMenu: null,
                }}
              />
            </div>
          }
          <DataGrid
            columns={columns}
            rows={rows}
            style={{ height: gridHeight, maxHeight:'1000px' }} // Set the grid height dynamically
            processRowUpdate={(oldRow, newRow) => this.processRowUpdate(oldRow, newRow, name)}
            hideFooter={hideFooter}
            disableRowSelectionOnClick
            disableColumnMenu
            rowHeight={rowHeight}
            isCellEditable={(params) => true}
            sx={{
               '& .MuiDataGrid-columnHeaderTitle': styles.columnHeader,
               '& .MuiDataGrid-cell': styles.cellBorder,
               '& .MuiDataGrid-row': {
                 borderBottom: '.5px solid rgba(224, 224, 224, 1)', // Add bottom border to each row
               }
             }}
            componentsProps={{
              cell: {
                disableColumnMenu: true
              },
              columnMenu: null,
            }}
          />
        </div>
      </div>
    );
  };

  render() {
    const { assessments, loading, dataLoaded, validationErrors } = this.state;
    const { user } = this.props;

    // Step 1: Handle the loading state
    if (loading || !user.isLoaded || !user.user.email) {
      return (
        <Layout>
          <div style={{ padding: '40px', textAlign: 'left' }}>
            <Typography sx={styles.subHeaderText}>
              Loading...
            </Typography>
          </div>
        </Layout>
      );
    }

    // Step 2: Main rendering logic
    return (
      <Layout>
        <div style={styles.root}>
          <Typography sx={styles.headerText}>
            Upload Test Results
          </Typography>
          <div style={{textAlign:'center', margin: '10%'}}>
            <input
              type="file"
              accept=".csv"
              onChange={this.handleFileUpload}
            />
          </div>

          {/* Step 4: Render the assessments grid */}
          {dataLoaded && assessments.map((assessment, index) => this.renderAssessmentGrid(assessment, index))}

          {/* Step 5: Display validation errors, if any */}
          <div style={{ width: '100%' }}>
            {validationErrors.length > 0 && (
              <div style={{ color: 'red', marginTop: '30px', marginLeft: '15%', marginRight: '15%' }}>
                Please fix the following errors before continuing:
                <ul>
                  {validationErrors.map((error, index) => (
                    <li style={{ textAlign: 'left', marginBottom: '10px', fontSize: 14 }} key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          {/* Step 6: Render the assessments list */}
          {dataLoaded && this.renderAssessmentsList()}

          {/* Step 7: Render the submit button */}
          <div style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>
            <Button onClick={this.handleSubmit} variant="contained" color="primary" size="large">
              {this.state.onFormReview === true ? "Finish reviewing" : "Continue"}
            </Button>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    subtestDirectory: state.subtestDirectory.subtests,
    subtestDirectoryLoading: state.subtestDirectory.loading,
    compositeDirectory: state.compositeDirectory.composites,
    compositeDirectoryLoading: state.compositeDirectory.loading,
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchSubtestDirectory: () => {
      return dispatch(subtestDirectory.fetchSubtestDirectory());
    },
    fetchCompositeDirectory: () => {
      return dispatch(compositeDirectory.fetchCompositeDirectory());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNavigateHook(AdminAssessmentUploader));
