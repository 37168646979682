import React, {Component} from "react";
import {connect} from "react-redux";
import { resetPassword } from '../../actions';

import { Navigate } from "react-router-dom";

import Layout from '../../shared/Layout'

import { Button, Typography, Paper } from "@mui/material";

const styles = ({
  resetPasswordButton: {
    textTransform: 'none',
    background:'#F8444F',
    minWidth: '100px',
    width: '350px'
  },
  resetPasswordTextDiv: {
    textAlign: 'center',
    marginBottom: '50px'
  },
  resetPasswordButtonText: {
    fontFamily:'Raleway',
    fontSize: 18,
  },
});

class FirstLoginPasswordResetLandingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      redirect: false
    };
  }

  handleResendResetPassword = async e => {
    e.preventDefault();

    // if not loaded or token is expired, set redirect to true to redirect to login
    if (!this.props.user.isLoaded || !localStorage.getItem('token')){
      this.setState({redirect: true})
    }
    else {
      try {
         await this.props.sendResetPasswordEmail();
         this.setState({message: this.props.message})
      } catch(error) {
        this.setState({message: 'There was an error sending the email. Please try again.'})
      }
    }
  }

  render() {
    if (this.state.redirect){
      return <Navigate to='/login' />
    }

    return (
      <Layout>
        {this.props.user && this.props.user.isLoaded &&
          <Paper sx={{width: '700px', maxWidth:'80%', padding:'25px', marginTop:'10%', marginLeft: 'auto', marginRight:'auto'}}>
            <div style={styles.resetPasswordTextDiv}>
              {this.state.message ? `${this.state.message}`: `An email has been sent to ${this.props.user.user.email} with a link to reset your password. Check your inbox or spam folder for the link to reset your password and log back in.`}
            </div>
            <div style= {{marginBottom:'20px', textAlign:'center'}}>
              If you don't see an email in your inbox within the next few minutes, click the link below to resend.
            </div>
            <div style={{textAlign:'center'}}>
              <Button
                type="submit"
                variant="contained"
                sx={styles.resetPasswordButton}
                onClick={this.handleResendResetPassword}
              >
                <Typography style={styles.resetPasswordButtonText}>
                  Resend password reset link
                </Typography>
              </Button>
            </div>
          </Paper>
        }
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    sending: state.resetPassword.isLoading,
    message: state.resetPassword.message,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    sendResetPasswordEmail: () => dispatch(resetPassword.sendFirstLoginResetPasswordEmail()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstLoginPasswordResetLandingPage);
