import React, { Component, createRef } from "react";
import { connect } from "react-redux";

import withNavigateHook from '../../shared/withNavigateHook';

import { SCORE_DISTRIBUTION_PARAMETERS, calulatePercentileFromScore, roundPercentile } from '../../shared/constants'

import Layout from '../../shared/Layout';

import { Box, ClickAwayListener, Dialog, DialogActions, DialogContent, Typography, TextField, Button, IconButton, Autocomplete, Alert } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid } from '@mui/x-data-grid';

import { MenuItem } from '@mui/material';

const styles = ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    textAlign: 'center',
    overflow: 'hidden',
    marginBottom: '36px',
    marginLeft: '400px'
  },
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  assessmentMenuDiv: {
    marginTop: '30px',
    display: 'flex',
    alignItems: 'left',
    width: '100%',
    marginBottom: '10px'
  },
  paper: {
    marginTop: '40px',
    marginLeft: '5%',
    marginRight: '5%',
    width: '70%',
    alignItems: 'center',
    display: 'inline-block'
  },
  assessmentTitle: {
    textAlign: 'center',
    marginBottom: '15px',
    marginTop: '15px',
    fontFamily: 'Raleway',
    fontSize: 20,
  },
  assessmentsAddedTitle: {
    textAlign: 'center',
    marginBottom: '15px',
    marginTop: '5px',
    fontFamily: 'Raleway',
    fontSize: 24,
  },
  assessmentsAddedAssessment: {
    fontFamily: 'Raleway',
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '60%',
    padding:'10px 20px',
    cursor: 'pointer',
    transition: 'background-color 0.3s, box-shadow 0.3s',
  },
  assessmentsAddedSubtest: {
    fontFamily: 'Raleway',
    fontSize: 14,
  },
  headerText: {
    textAlign: 'left',
    width:'100%',
    marginTop: '20px',
    marginBottom: '24px',
    fontFamily: 'Comfortaa',
    fontSize: 40,
    fontWeight: 200
  },
  gridContainer: {
    width: '95%',
    margin: '20px auto',
    overflow: 'hidden'
  },
  customHeader: {
    backgroundColor: '#f5f5f5',
    // height: '60px',
    padding: '5px',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '16px'
  },
  columnHeader: {
    fontWeight: 'bold',
  },
  cellBorder: {
    border: '.5px solid rgba(224, 224, 224, 1)', // You can customize the border color and style as needed
  },
  assessmentListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    marginTop:'20px'
  },
  assessmentListOverlay: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 0, 0, 0.1)', /* Light pink with transparency */
    padding: '5px 10px',
    margin: '8px 0',
    width:'60%',
    borderRadius: '8px' /* Rounded corners */
  },
  centeredAlert: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1500,
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1500,
  }
});

const encodeString = (str) => str.replace(/[^a-zA-Z0-9]/g, '-');

class AssessmentGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAssessment: null,
      selectedAssessmentData: null,
      assessments: [],
      validationErrors: [],
      onFormReview: null,
      loading: true,
      openAssessment: false,
      deleteConfirmationOpen: false
    };
    this.dialogContentRef = createRef();
    this.gridRef = React.createRef(); // Create a ref for the grid
  }

  componentDidMount() {

    const { assessmentData, subtestDirectory, compositeDirectory } = this.props;

    let assessments = this.getAssessmentState(assessmentData, subtestDirectory, compositeDirectory )

    let selectedAssessment = localStorage.getItem('selectedAssessment')
    let openAssessment = localStorage.getItem('openAssessment')
    let selectedAssessmentData = localStorage.getItem('selectedAssessmentData');

    if (selectedAssessment && openAssessment && selectedAssessmentData){
      selectedAssessment = JSON.parse(selectedAssessment)
      openAssessment = JSON.parse(openAssessment)
      selectedAssessmentData = JSON.parse(selectedAssessmentData)
      if (assessments.filter(assessment => assessment.name === selectedAssessment).length === 0){
        assessments = [selectedAssessmentData, ...assessmentData]
      }
    }
    this.setState({assessments: assessments, selectedAssessment: selectedAssessment || null, openAssessment:openAssessment || null, selectedAssessmentData: selectedAssessmentData || null })

    // Attach the scroll event listener when the component mounts
    if (this.gridRef.current) {
      this.gridRef.current.addEventListener('wheel', this.handleScroll, { passive: false });
    }
  }

  componentWillUnmount() {
    // Remove the event listener when the component unmounts
    if (this.gridRef.current) {
      this.gridRef.current.removeEventListener('wheel', this.handleScroll);
    }
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    // Check if loaded
    if (user.isLoaded && this.state.loading) {
      this.setState({ loading: false });
    }
  }

  handleScroll = (e) => {
    const grid = this.gridRef.current;
    if (grid) {
      const { scrollTop, scrollHeight, clientHeight } = grid;

      const atTop = scrollTop === 0;
      const atBottom = scrollTop + clientHeight >= scrollHeight;

      // If at the top or bottom, let the scroll event bubble up to allow page scrolling
      if ((atTop && e.deltaY < 0) || (atBottom && e.deltaY > 0)) {
        e.preventDefault(); // Prevent the grid from capturing the scroll
      }
    }
  };

  // returns the formatted assessment state-- for each added assessment, all directory data (subtests/measures/composites)
  // score, percentile and outcome valaues are left blank in cases where no data has been saved, and otherwise are updated with the corresponding saved values
  getAssessmentState(evalAssessments, subtestDirectory, compositeDirectory){
    const assessments = evalAssessments.map(assessment => {
      const assessmentName = assessment.name;
      const dirAssessment = subtestDirectory[assessmentName];

      const findSubtest = (subtestName) => {
        return assessment.subtests.find(sub => sub.name === subtestName) || {};
      };

      const findMeasure = (subtestName, measure) => {
        const subtest = findSubtest(subtestName);
        return (subtest.measures || []).find(m => m.measureName === measure) || {};
      };

      const newAssessment = {
        name: assessmentName,
        subtests: dirAssessment.subtests.map(subtest => {
          const subtestData = findSubtest(subtest.subtest_name);
          return {
            name: subtest.subtest_name,
            percentile: subtestData.percentile || null,
            tScore: subtestData.tScore || null,
            outcome: subtestData.outcome || null,
            scoreType: subtest.scoreType || null,
            has_measures: subtest.has_measures,
            measures: subtest.has_measures ? subtest.measures.map(measure => {
              const measureData = findMeasure(subtest.subtest_name, measure);
              return {
                measureName: measure,
                percentile: measureData.percentile || null,
                tScore: measureData.tScore || null,
                outcome: measureData.outcome || null
              };
            }) : []
          };
        }),
      };

      if (assessmentName in compositeDirectory) {
        newAssessment.has_composites = true;
        newAssessment.composites = compositeDirectory[assessmentName].map(composite => {
          const compositeData = (assessment.composites || []).find(comp => comp.name === composite.composite_name) || {};
          return {
            name: composite.composite_name,
            percentile: compositeData.percentile || null,
            tScore: compositeData.tScore || null,
            outcome: compositeData.outcome || null,
            scoreType: composite.scoreType || null
          };
        });
      }
      return newAssessment;
    });
    return assessments
  }

  saveAssessmentsToLocalStorage = () => {
    localStorage.setItem('openAssessment', JSON.stringify(true));
    localStorage.setItem('selectedAssessment', JSON.stringify(this.state.selectedAssessment));
    // only update open assessment data in local storage if it wasnt added previously (don't want to erroneously overwrite existing saved data with )
    if (this.props.assessmentData.filter(assessment => assessment.name===this.state.selectedAssessment).length === 0){
      localStorage.setItem('selectedAssessmentData', JSON.stringify(this.state.assessments.find(assessment => assessment.name===this.state.selectedAssessment)))
    }
  }

  handleCellKeyDown = (params, event, rows, columns) => {
    if (event.key === 'Tab') {
      event.preventDefault();

      const { id, field } = params;
      const columnIndex = columns.findIndex((col) => col.field === field);
      const rowIndex = rows.findIndex((row) => row.id === id);

      let nextCellParams;

      if (event.shiftKey) {
        // Shift+Tab: Move to the previous cell
        if (columnIndex > 0) {
          nextCellParams = { id, field: columns[columnIndex - 1].field, rowIndex, columnIndex: columnIndex - 1 };
        } else if (rowIndex > 0) {
          const previousRowId = rows[rowIndex - 1].id;
          const lastColumnField = columns[columns.length - 1].field;
          nextCellParams = { id: previousRowId, field: lastColumnField, rowIndex: rowIndex - 1, columnIndex: columns.length - 1 };
        }
      } else {
        // Tab: Move to the next cell
        if (columnIndex < columns.length - 1) {
          nextCellParams = { id, field: columns[columnIndex + 1].field, rowIndex, columnIndex: columnIndex + 1 };
        } else {
          const nextRowId = rows[rowIndex + 1]?.id;
          if (nextRowId !== undefined) {
            const firstColumnField = columns[0].field;
            nextCellParams = { id: nextRowId, field: firstColumnField, rowIndex: rowIndex + 1, columnIndex: 0 };
          }
        }
      }

      if (nextCellParams) {
        const encodedId = encodeString(nextCellParams.id);
        const encodedField = encodeString(nextCellParams.field);
        const cellSelector = `.cell-${encodedField}-${encodedId}`;
        const cellElement = document.querySelector(cellSelector);
        if (cellElement) {
          cellElement.focus();
        }
      }
    }
  };

  getGridRows = (assessmentName) => {
    var selectedAssessment = this.state.assessments.find(assessment => assessment.name===assessmentName);
    if (!selectedAssessment) {
      if (Object.keys(this.props.subtestDirectory).includes(assessmentName)) {
        selectedAssessment = this.props.subtestDirectory[assessmentName]
      }
      else {
        return []
      }
    }

    let rows = [];

    let boldSubtest = false
    if (  selectedAssessment.subtests.filter(subtest => subtest.has_measures===true).length > 0){
      boldSubtest = true
    }

    selectedAssessment.subtests.forEach(subtest => {
      var isEditable = !(subtest.has_measures===true)
      rows.push({
        id: `${assessmentName}-${subtest['name']}`,
        testName: subtest['name'],
        score: subtest.tScore || null,
        percentile: subtest.percentile || null,
        outcome: subtest.outcome || null,
        isBold: boldSubtest,
        isEditable: isEditable
      });

      if (subtest.has_measures) {
        subtest.measures.forEach(measure => {
          rows.push({
            id: `${subtest.name}-${measure.measureName}`,
            testName: measure.measureName,
            score: measure.tScore || null,
            percentile: measure.percentile || null,
            outcome: measure.outcome || null,
            indentLevel: 1,
            isEditable: true
          });
        });
      }
    });

    return rows;
  }

  getCompositeGridRows = (assessmentName) => {
    var selectedAssessment = this.state.assessments.find(assessment => assessment.name === assessmentName);
    if (!selectedAssessment) {
      if (Object.keys(this.props.subtestDirectory).includes(assessmentName)) {
        selectedAssessment = this.props.subtestDirectory[assessmentName]
      }
      else {
        return []
      }
    }

    let rows = [];

    selectedAssessment.composites.forEach(composite => {
      rows.push({
        id: `${assessmentName}-${composite.name}`,
        testName: composite.name,
        score: composite.tScore || null,
        percentile: composite.percentile || null,
        outcome: composite.outcome || null
      });
    });

    return rows;
  }

  getAvailableAssessments = () => {
    const { assessments } = this.state;
    const allAssessments = Object.keys(this.props.subtestDirectory);
    const addedAssessments = assessments.filter(assessment => assessment.name !== this.state.selectedAssessment).map(assessment => assessment.name);
    const assessment_domain_mapping = this.props.subtestDirectory['assessment_domain_mapping']

    const availableAssessments = allAssessments.filter(assessment => !addedAssessments.includes(assessment));
    const domainMapping = {};

    availableAssessments.forEach(assessment => {
      Object.keys(assessment_domain_mapping).forEach(domain => {
        if (assessment_domain_mapping[domain].includes(assessment)) {
          if (!domainMapping[domain]) {
            domainMapping[domain] = [];
          }
          const assessmentDirObj = this.props.subtestDirectory[assessment];
          const assessmentDict = { label: assessment, name: assessment, abbreviation: '' };
          if (assessmentDirObj.assessment_abbreviation) {
            assessmentDict.label = `${assessment} (${assessmentDirObj.assessment_abbreviation})`;
            assessmentDict.abbreviation = assessmentDirObj.assessment_abbreviation;
          }
          domainMapping[domain].push(assessmentDict);
        }
      });
    });

    const sortedDomains = Object.keys(domainMapping).sort();
    const sortedDomainMapping = {};

    sortedDomains.forEach(domain => {
      sortedDomainMapping[domain] = domainMapping[domain].sort((a, b) => a.label.localeCompare(b.label));
    });

    return sortedDomainMapping;
  }

  handleAssessmentChange = (newValue) => {
    if (newValue) {
      this.setState({ selectedAssessment: newValue.name }, this.handleAddAssessment(newValue));
    } else {
      this.setState({ selectedAssessment: null });
    }
  }

  handleAddAssessment = (assessment) => {
    const selectedAssessment = assessment.name
    const { assessments } = this.state;
    if (selectedAssessment) {
      const newAssessment = {
        name: selectedAssessment,
        subtests: this.props.subtestDirectory[selectedAssessment].subtests.map(subtest => ({
          name: subtest.subtest_name,
          percentile: null,
          tScore: null,
          outcome: null,
          scoreType: subtest.scoreType || null,
          has_measures: subtest.has_measures,
          measures: subtest.has_measures ? subtest.measures.map(measure => ({
            measureName: measure,
            percentile: null,
            tScore: null,
            outcome: null
          })) : []
        })),
      };

      if (this.hasComposites(selectedAssessment)){
        newAssessment['has_composites'] = true
        newAssessment['composites'] = []
        this.props.compositeDirectory[selectedAssessment].forEach(composite => {
          const compositeDict = {name: composite.composite_name, percentile: null, tScore: null, outcome: null, scoreType: composite.scoreType || null}
          newAssessment['composites'].push(compositeDict)
        })
      }

      this.setState({
        assessments: [newAssessment, ...assessments],
        openAssessment: true,
      }, this.saveAssessmentsToLocalStorage);
    }
  }

  // convert numeric values to float and convert empty strings to nulls before storing row data in state
  formatRowUpdate = (newRow) => {
    if (!newRow.percentile){
      newRow.percentile = null
    } else if (!isNaN(newRow.percentile)) {
      newRow.percentile = parseFloat(newRow.percentile)
    }

    if (!newRow.score){
      newRow.score = null
    } else if (!isNaN(newRow.score)) {
      newRow.score = parseFloat(newRow.score)
    }

    if (!newRow.outcome){
      newRow.outcome = null
    }
    return newRow
  }

  processRowUpdate = (newRow, oldRow, name) => {
    const formattedRow = this.formatRowUpdate(newRow)
    this.setState(prevState => {
      const updatedAssessments = [...prevState.assessments];
      const updatedAssessmentIndex = updatedAssessments.findIndex(assessment =>
        assessment.name===name && (assessment.subtests.some(subtest => subtest.name === oldRow.testName || subtest.measures.some(measure => measure.measureName === oldRow.testName)))
      );

      if (updatedAssessmentIndex !== -1) {
        const updatedAssessment = updatedAssessments[updatedAssessmentIndex];
        const subtestIndex = updatedAssessment.subtests.findIndex(subtest => subtest.name === oldRow.testName);

        if (subtestIndex !== -1) {
          const subtest = updatedAssessment.subtests[subtestIndex]
          if (formattedRow.score !== subtest.tScore){
            if (subtest.scoreType && Object.keys(SCORE_DISTRIBUTION_PARAMETERS).includes(subtest.scoreType)){
              const scoreParams = SCORE_DISTRIBUTION_PARAMETERS[subtest.scoreType]
              if (formattedRow.score){
                const percentile = calulatePercentileFromScore(scoreParams.mean, scoreParams.sd, newRow.score)
                formattedRow.percentile =  roundPercentile(percentile)
              }
              else {
                formattedRow.percentile = null
              }
            }
          }
          // Update subtest
          updatedAssessment.subtests[subtestIndex] = {
            ...updatedAssessment.subtests[subtestIndex],
            percentile: formattedRow.percentile || null,
            tScore: formattedRow.score || null,
            outcome: formattedRow.outcome || null
          };
        } else {
          // Update measure
          updatedAssessment.subtests.forEach(subtest => {
            const measureIndex = subtest.measures.findIndex(measure => measure.measureName === oldRow.testName);
            if (measureIndex !== -1) {
              subtest.measures[measureIndex] = {
                ...subtest.measures[measureIndex],
                percentile: formattedRow.percentile,
                tScore: formattedRow.score,
                outcome: formattedRow.outcome
              };
            }
          });
        }
        updatedAssessments[updatedAssessmentIndex] = updatedAssessment;
      }
      return { assessments: updatedAssessments };
    }, this.saveAssessmentsToLocalStorage);
    return formattedRow;
  };

  processCompositeRowUpdate = (newRow, oldRow, name) => {
    const formattedRow = this.formatRowUpdate(newRow)
    this.setState(prevState => {
      const updatedAssessments = [...prevState.assessments];
      const updatedAssessmentIndex = updatedAssessments.findIndex(assessment =>
        assessment.name===name && assessment.composites.some(composite => composite.name === oldRow.testName)
      );

      if (updatedAssessmentIndex !== -1) {
        const updatedAssessment = updatedAssessments[updatedAssessmentIndex];
        const compositeIndex = updatedAssessment.composites.findIndex(composite => composite.name === oldRow.testName);

        if (compositeIndex !== -1) {
          const composite = updatedAssessment.composites[compositeIndex]
          if (formattedRow.score !== composite.tScore){
            if (composite.scoreType && Object.keys(SCORE_DISTRIBUTION_PARAMETERS).includes(composite.scoreType)){
              const scoreParams = SCORE_DISTRIBUTION_PARAMETERS[composite.scoreType]
              if (formattedRow.score){
                const percentile = calulatePercentileFromScore(scoreParams.mean, scoreParams.sd, newRow.score)
                formattedRow.percentile = roundPercentile(percentile)
              }
              else {
                formattedRow.percentile = null
              }
            }
          }
          // Update composite
          updatedAssessment.composites[compositeIndex] = {
            ...updatedAssessment.composites[compositeIndex],
            percentile: formattedRow.percentile,
            tScore: formattedRow.score,
            outcome: formattedRow.outcome
          };
        }
        updatedAssessments[updatedAssessmentIndex] = updatedAssessment;
      }
      return { assessments: updatedAssessments };
    }, this.saveAssessmentsToLocalStorage);
    return formattedRow;
  };

  validateAssessment = (selectedAssessment) => {
    const { assessments } = this.state;

    let validationErrors = []; // To store validation errors
    if (assessments.length===0){
      validationErrors.push('No assessments have been added to the appendix. Please add all assessments that were administered.');
    }

    const assessment = assessments.find(assmnt => assmnt.name === selectedAssessment)
    var emptyAssessment = true
    assessment.subtests.forEach(subtest => {
      if (subtest.percentile || subtest.outcome){
        emptyAssessment = false
      }
      if (subtest.has_measures === true){
        subtest.measures.forEach(measure => {
          if (measure.percentile || measure.outcome){
            emptyAssessment = false
          }
        })
      }
    })

    if (emptyAssessment === true) {
      // If an assessment has no subtests added, log an error
      validationErrors.push(`Assessment "${selectedAssessment}" has no subtests with a percentile or result added. Please add a value for at least one subtest or remove the assessment.`);
      return validationErrors
    }

    if (this.hasComposites(assessment.name)===true) {
      assessment.composites.forEach(composite => {

        // Validate composite percentile value if it exists
        if (composite.percentile !== null && composite.percentile !== '') {
          if (isNaN(composite.percentile) || composite.percentile < 0 || composite.percentile >= 100) {
            validationErrors.push(`Assessment "${assessment.name}", composite "${composite.name}" has an invalid percentile value. Percentile must be a greater than or equal to 0 and less than 100.`);
          }
        }

        // Validate composite tScore value if it exists
        if (composite.tScore !== null && composite.tScore !== '') {
          if (isNaN(composite.tScore)) {
            validationErrors.push(`Assessment "${assessment.name}", composite "${composite.name}" has an invalid Standard Score value. Standard Score must be a numeric value.`);
          }
        }

        // Ensure percentile value -- composite must have percentile
        if ((composite.tScore || composite.outcome) && !composite.percentile) {
          validationErrors.push(`Assessment "${assessment.name}", composite "${composite.name}" needs a percentile value.`);
        }
      })
    }

    // subtests without outcomes measures
    assessment.subtests.filter(subtest => subtest.has_measures===false).forEach(subtest => {
      if (subtest.tScore && !subtest.percentile && !subtest.outcome) {
        // If both percentile and outcome are missing, log an error
        validationErrors.push(`Assessment "${assessment.name}", subtest "${subtest.name}" needs a percentile or an outcome and is missing both.`);
      }

      // Validate percentile value if it exists
      if (subtest.percentile !== null && subtest.percentile !== '') {
        if (String(subtest.percentile).includes('>') || String(subtest.percentile).includes('<')){
          validationErrors.push(`Assessment "${assessment.name}", Subtest "${subtest.name}": Percentiles reported as "<" or ">" (<16%, >99 etc.) must be put in the "Other Result" column, and "Percentile" can be left blank.`);
        }
        else {
          if (isNaN(subtest.percentile) || subtest.percentile < 0 || subtest.percentile >= 100) {
            validationErrors.push(`Assessment "${assessment.name}", Subtest "${subtest.name}" has an invalid percentile value. Percentile must be a number greater than or equal to 0 and less than 100.`);
          }
        }
      }
      // Validate tScore value if it exists
      if (subtest.tScore !== null && subtest.tScore !== '') {
        if (isNaN(subtest.tScore)) {
          validationErrors.push(`Assessment "${assessment.name}", Subtest "${subtest.name}" has an invalid Score value. Score must be a numeric value.`);
        }
      }
    });

    //subtests with measures
    assessment.subtests.filter(subtest => subtest.has_measures===true).forEach(subtest => {
      subtest.measures.forEach(measure => {
        if (!measure.percentile && !measure.outcome && measure.tScore) {
          // If both percentile and outcome are missing, log an error
          validationErrors.push(`${subtest.name}" (${assessment.name}), measure "${measure.measureName}" has a score value, but needs a percentile or an outcome and is missing both. Add a percentile or outcome, or remove the score to exclude the measure.`);
        }

        // Validate percentile value if it exists
        if (measure.percentile !== null && measure.percentile !== '') {
          if (String(measure.percentile).includes('>') || String(measure.percentile).includes('<')){
            validationErrors.push(`Subtest "${subtest.name}" (${assessment.name}), measure "${measure.measureName}": Percentiles reported as "<" or ">" (<16%, >99 etc.) must be put in the "Other Result" column, and "Percentile" can be left blank.`);
          }
          else {
            if (isNaN(measure.percentile) || measure.percentile < 0 || measure.percentile >= 100) {
              validationErrors.push(`Subtest "${subtest.name}" (${assessment.name}), measure "${measure.measureName}" has an invalid percentile value. Percentile must be a number greater than or equal to 0 and less than 100.`);
            }
          }
        }
        // Validate tScore value if it exists
        if (measure.tScore !== null && measure.tScore !== '') {
          if (isNaN(measure.tScore)) {
            validationErrors.push(`Subtest "${subtest.name}" (${assessment.name}), measure "${measure.measureName}" has an invalid Score value. Score must be a numeric value.`);
          }
        }
      })
    });

    return validationErrors;
  };


  hasComposites = (assessmentName) => {
    if (assessmentName in this.props.compositeDirectory){
      return true;
    }
    return false;
  };

  formIsValid = () => {
    const { assessments } = this.state
    const validationErrors = []
    assessments.forEach(assessment => {
      validationErrors.push(this.validateAssessment(assessment.name))
    })
    return validationErrors.length === 0;
  }

  // return only assessment subtest/measures/composites with new/updated values
  formatDataForSave = (selectedAssessment) => {
    const assessment = this.state.assessments.find(assessment => assessment.name === selectedAssessment)
    const existingAssessmentData = this.props.assessmentData.find(assessment => assessment.name === selectedAssessment)
    const formattedSubtests = []
    assessment.subtests.forEach(subtest => {
      const existingSubtestData = existingAssessmentData ? existingAssessmentData.subtests.find(s => s.name === subtest.name): null
      if (subtest.has_measures === true){
        const formattedMeasures = []
        subtest.measures.forEach(measure => {
          const existingMeasureData = existingSubtestData ? existingSubtestData.measures.find(m => m.measureName === measure.measureName): null
          if (measure.percentile || measure.outcome || (existingMeasureData && (JSON.stringify(existingMeasureData) !== JSON.stringify(measure)))){
            formattedMeasures.push(measure)
          }
        })
        if (formattedMeasures.length > 0) {
            formattedSubtests.push({...subtest, measures: formattedMeasures})
        }
      }
      else {
        if (subtest.percentile || subtest.outcome || (existingSubtestData && (JSON.stringify(existingSubtestData) !== JSON.stringify(subtest)))){
          formattedSubtests.push(subtest)
        }
      }
    })

    assessment['subtests'] = formattedSubtests

    if (this.hasComposites(assessment.name)){
      const formattedComposites = []
      assessment.composites.forEach(composite => {
        const existingCompositeData = existingAssessmentData ? existingAssessmentData.composites.find(comp => comp.name === composite.name): null
        if (composite.percentile || composite.outcome || (existingCompositeData && (JSON.stringify(existingCompositeData) !== JSON.stringify(composite)))){
          formattedComposites.push(composite)
        }
      })
      assessment['composites'] = formattedComposites

    }

    return assessment
  }

  handleSave = () => {
    const { selectedAssessment } = this.state
    const { updateAssessmentData } = this.props
    // Add your logic here
    const validationErrors = this.validateAssessment(selectedAssessment);
    if (validationErrors.length > 0) {
      this.setState({ validationErrors }, () => {
        if (this.dialogContentRef.current) {
          this.dialogContentRef.current.scrollTop = this.dialogContentRef.current.scrollHeight;
        }
      });
      return;
    }

    const formattedData = this.formatDataForSave(selectedAssessment)

    // send only the data for the new assessment to be added
    updateAssessmentData({assessment: formattedData})

    localStorage.removeItem('openAssessment');
    localStorage.removeItem('selectedAssessment');
    localStorage.removeItem('selectedAssessmentData');
    this.setState({ selectedAssessment: null }, this.handleCloseAssessment);
  };

  handleCloseAssessment = () => {
    this.setState({ openAssessment: false });
  }

  openAssessmentFromList = (assessment) => {
    this.setState({selectedAssessment:assessment.name, openAssessment:true}, this.saveAssessmentsToLocalStorage)
  }

  handleCancel = () => {
    const { selectedAssessment } = this.state
    const { assessmentData, subtestDirectory, compositeDirectory } = this.props

    localStorage.removeItem('openAssessment');
    localStorage.removeItem('selectedAssessment');
    localStorage.removeItem('selectedAssessmentData');

    // if the assessment data is already stored in report data/backend, cancel leaves the data unchanged.
    // otherwise, updates state removing the new assessment data without saving
    if (assessmentData.some(assessment => assessment.name === selectedAssessment)){
      this.setState({ selectedAssessment: null, assessments: this.getAssessmentState(assessmentData, subtestDirectory, compositeDirectory), validationErrors: [] }, this.handleCloseAssessment);
    } else {
      this.setState({ selectedAssessment: null, assessments: this.state.assessments.filter(assessment => assessment.name!==this.state.selectedAssessment), validationErrors: [] }, this.handleCloseAssessment);
    }
  };

  handleDeleteAssessment = () => {
    const { selectedAssessment } = this.state
    const { deleteAssessment } = this.props

    deleteAssessment(selectedAssessment)
    this.setState({ assessments: this.state.assessments.filter(assessment => assessment.name !== selectedAssessment), selectedAssessment: null });
  };

  handleClickAway = () => {
    this.setState({ deleteConfirmationOpen: false });
  };

  renderAssessmentsList = () => {
    const { assessments } = this.state;

    if (assessments.length === 0) {
      return null; // Don't render the section if there are no assessments
    }

    return (
      <div style={styles.assessmentListContainer}>
        {this.state.deleteConfirmationOpen && <div style={styles.overlay}></div>}
        {assessments.map((assessment, index) => (
          <Box style={styles.assessmentListOverlay}>
            <Typography variant="body2" >
              {assessment.name}
            </Typography>
            <div>
              <Button onClick={() => this.openAssessmentFromList(assessment)} color="primary"><Typography variant="body2" style={{color:'#3b3b3b'}}>Edit</Typography></Button>
              <IconButton
                aria-label="delete assessment"
                onClick={() => this.setState({deleteConfirmationOpen: true, selectedAssessment: assessment.name})}
                sx={styles.deleteButton}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </Box>
        ))}
        {this.state.deleteConfirmationOpen &&
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <Alert
              onClose={() => this.setState({ deleteConfirmationOpen: false })}
              severity="warning"
              style={styles.centeredAlert}  // Align contents horizontally
              action={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button size="small" color="inherit" variant="outlined" style={{ margin: '0 5px' }} onClick={() => this.handleDeleteAssessment()}>
                    DELETE
                  </Button>
                  <Button variant="outlined" color="inherit" size="small" style={{ margin: '0 5px' }} onClick={() => this.setState({ deleteConfirmationOpen: false })}>
                    BACK
                  </Button>
                </div>
              }
            >
              <p style={{ margin: '0 10px' }}>Are you sure you want to delete {this.state.selectedAssessment}?</p>
            </Alert>
          </ClickAwayListener>
        }
      </div>
    );
  };

  assessmentsUpdated = (selectedAssessment, assessments, existingAssessmentData) => {
    const assessmentData = assessments.find(assessment => assessment.name === selectedAssessment);
    const existingAssessment = existingAssessmentData.find(assessment => assessment.name === selectedAssessment);

    if (!existingAssessment) {
      // If there's no existing data, consider the assessment as updated if it has any subtests or composites with data.
      return assessmentData.subtests.some(subtest => subtest.percentile || subtest.tScore || subtest.outcome || subtest.measures?.some(m => m.tScore || m.percentile || m.outcome)) ||
             assessmentData.composites?.some(composite => composite.percentile || composite.tScore || composite.outcome);
    }

    return (
      assessmentData.subtests.some(subtest => {
        const existingSubtest = existingAssessment.subtests.find(s => s.name === subtest.name);
        if (subtest.has_measures === true) {
          return subtest.measures.some(measure => {
            const existingMeasure = existingSubtest ? existingSubtest.measures.find(m => m.measureName === measure.measureName): undefined;
            return (!existingMeasure && (measure.tScore || measure.percentile || measure.outcome)) ||
                   (existingMeasure &&
                     (measure.percentile !== existingMeasure.percentile ||
                     measure.outcome !== existingMeasure.outcome ||
                     measure.tScore !== existingMeasure.tScore)
                   );
          });
        }
        else {
          return (!existingSubtest && (subtest.tScore || subtest.percentile || subtest.outcome)) ||
                 (existingSubtest &&
                   (subtest.percentile !== existingSubtest.percentile ||
                   subtest.tScore !== existingSubtest.tScore ||
                   subtest.outcome !== existingSubtest.outcome)
                 );
        }

      })
    ) || (assessmentData.composites ? (
      assessmentData.composites.some(composite => {
        const existingComposite = existingAssessment.composites.find(c => c.name === composite.name);
        return (!existingComposite && (composite.percentile || composite.tScore || composite.outcome)) ||
               (existingComposite &&
                 (composite.percentile !== existingComposite.percentile ||
                 composite.tScore !== existingComposite.tScore ||
                 composite.outcome !== existingComposite.outcome)
               )
      })
    ): false)
  };

  renderAssessmentGrid = (assessment) => {
    const { validationErrors } = this.state
    const { name } = assessment;

    const rows = this.getGridRows(name);

    let percentileAutoCompute = false;
    if (this.state.assessments.find(a => a.name === name).subtests.some(subtest => subtest.scoreType && Object.keys(SCORE_DISTRIBUTION_PARAMETERS).includes(subtest.scoreType))) {
      percentileAutoCompute = true
    }

    const headerRowHeight = 56;
    const rowHeight = 40; // Define the height of each row
    var gridHeight = rowHeight * rows.length + headerRowHeight

    let compositeGridHeight = null;
    if (this.hasComposites(name)) {
      compositeGridHeight = this.getCompositeGridRows(name).length * rowHeight + headerRowHeight
    }

    let hideFooter = false;
    if (rows.length < 100){
      hideFooter = true
    }

    const columns = [
      {
        field: 'testName',
        headerName: 'Subtest/Measure',
        editable: false,
        flex: 3,
        renderCell: (params) => {
          const indentLevel = params.row.indentLevel || 0; // Adjust the property name as needed
          const isBold = params.row.isBold || false; // Add a property to conditionally bold text
          return (
            <div style={{
              paddingLeft: indentLevel * 20,
              fontWeight: isBold ? 'bold' : 'normal' // Conditionally set font weight
            }}>
              {params.value}
            </div>
          );
        },
      },
      { field: 'score', headerName: 'Score (scaled, T, etc.)', editable: true, flex: 1.5 },
      { field: 'percentile', headerName: 'Percentile', editable: true, flex: 1 },
      { field: 'outcome', headerName: 'Other Result (>16%, etc.)', editable: true, flex: 2 }
    ];

    const compositeColumns = [
      { field: 'testName', headerName: 'Index/Composite', editable: false, flex: 3},
      { field: 'score', headerName: 'Score (scaled, T, etc.)', editable: true, flex: 1.5 },
      { field: 'percentile', headerName: 'Percentile', editable: true, flex: 1 },
      { field: 'outcome', headerName: 'Other Result (>16%, etc.)', editable: true, flex: 2 }
    ];

    return (
        <Dialog
          style={{marginTop:'50px'}}
          open={this.state.openAssessment}
          disableEscapeKeyDown={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              width: '60%', // Set the dialog to take up 50% of the screen width
              maxWidth: 'none', // Disable the default maxWidth
              overflowX: 'hidden', // Ensure no overflow issues on the parent container
              maxHeight:'90%',
            }
          }}
        >
          <DialogContent
            ref={this.dialogContentRef}
            style={{
              width: '100%',
              maxWidth: '100%', // Ensure content does not exceed the dialog width
              overflow: 'auto', // Handle any overflow within the dialog content
              padding: '20px', // Add some padding if needed
              boxSizing: 'border-box', // Include padding in the element's width and height
            }}
          >
            <div style={styles.gridContainer}>
              <div style={styles.customHeader}>
                <Typography sx={styles.assessmentTitle}>
                  {name}
                </Typography>
              </div>
              {percentileAutoCompute &&
                <Typography style={{marginBottom:'5px', marginLeft:'5px', textAlign: 'left'}} variant="body2">
                  <span style={{ fontWeight:'bold' }}>IMPORTANT:</span> Percentiles will be automatically computed when a score is entered, using a normal score conversion. <span style={{ textDecoration: 'underline', fontWeight:'bold' }}>Be aware that in certain edge cases, if the composite/subtest/measure data is not normally distributed, this calculation may result in an incorrect percentile.</span> Please use your clinical judgment and re-enter the correct percentile should such a case arise.
                </Typography>
              }
              <div>
                {this.hasComposites(name) &&
                  <div ref={this.gridRef} style={{ overflowY: 'auto' }}>
                    <DataGrid
                      columns={compositeColumns}
                      rows={this.getCompositeGridRows(name)}
                      style={{ height: compositeGridHeight, width:'100%' }} // Set the grid height dynamically
                      onCellKeyDown={(params, event) => this.handleCellKeyDown(params, event, this.getCompositeGridRows(name), compositeColumns)}
                      getCellClassName={(params) => `cell-${encodeString(params.field)}-${encodeString(params.id)}`}
                      processRowUpdate={(oldRow, newRow) => this.processCompositeRowUpdate(oldRow, newRow, name)}
                      hideFooter={hideFooter}
                      disableRowSelectionOnClick
                      disableColumnMenu
                      rowHeight={rowHeight}
                      sx={{
                         '& .MuiDataGrid-columnHeaderTitle': styles.columnHeader,
                         '& .MuiDataGrid-cell': styles.cellBorder,
                         '& .MuiDataGrid-row': {
                           borderBottom: '.5px solid rgba(224, 224, 224, 1)', // Add bottom border to each row
                         }
                       }}
                      componentsProps={{
                        columnHeaders: {
                          style: styles.columnHeader,
                        },
                        cell: {
                          disableColumnMenu: true
                        },
                        columnMenu: null,
                      }}
                    />
                  </div>
                }
                <div ref={this.gridRef} style={{ overflowY: 'auto' }}>
                  <DataGrid
                    columns={columns}
                    rows={rows}
                    style={{ height: gridHeight, maxHeight:'1000px', width: '100%' }} // Set the grid height dynamically
                    onCellKeyDown={(params, event) => this.handleCellKeyDown(params, event, rows, columns)}
                    getCellClassName={(params) => `cell-${encodeString(params.field)}-${encodeString(params.id)}`}
                    processRowUpdate={(oldRow, newRow) => this.processRowUpdate(oldRow, newRow, name)}
                    hideFooter={hideFooter}
                    disableRowSelectionOnClick
                    disableColumnMenu
                    rowHeight={rowHeight}
                    isCellEditable={(params) => params.row.isEditable===true}
                    sx={{
                       '& .MuiDataGrid-columnHeaderTitle': styles.columnHeader,
                       '& .MuiDataGrid-cell': styles.cellBorder,
                       '& .MuiDataGrid-row': {
                         borderBottom: '.5px solid rgba(224, 224, 224, 1)', // Add bottom border to each row
                       }
                     }}
                    componentsProps={{
                      cell: {
                        disableColumnMenu: true
                      },
                      columnMenu: null,
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ width: '100%' }}>
            {validationErrors.length > 0 && (
              <div style={{ color: 'red', marginTop: '30px', marginLeft: '15%', marginRight: '15%' }}>
                Please fix the following errors before continuing:
                <ul>
                  {validationErrors.map((error, index) => (
                    <li style={{ textAlign: 'left', marginBottom: '10px', fontSize: 14 }} key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions
          style={{
            position: 'sticky',
            bottom: 0,
            backgroundColor: 'white', // Ensure the background is white so it covers content when sticky
            padding: '0px 24px', // Add padding to match default MUI button styling
            boxSizing: 'border-box', // Ensure padding is accounted for
          }}
        >
          <Button
            aria-label="cancel update"
            variant="text"
            onClick={this.handleCancel}
            style={{ marginRight: 'auto' }}
          >
            <p style={{ color: "black" }}>Cancel</p>
          </Button>
          <Button
            onClick={this.handleSave}
            color="primary"
            autoFocus
            disabled={!this.assessmentsUpdated(this.state.selectedAssessment, this.state.assessments, this.props.assessmentData)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderAssessmentDropdown = () => {
    const availableAssessments = this.getAvailableAssessments();
    return (
      <Autocomplete
        id="assessment-dropdown"
        options={Object.keys(availableAssessments).flatMap(domain => availableAssessments[domain])}
        groupBy={(option) => {
          for (const domain of Object.keys(availableAssessments)) {
            if (availableAssessments[domain].includes(option)) {
              return domain;
            }
          }
          return '';
        }}
        getOptionLabel={(option) => option.label}
        value={undefined}
        onChange={(event, newValue) => this.handleAssessmentChange(newValue)}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        renderInput={(params) => (
          <TextField {...params} label="Select an assessment" variant="outlined" fullWidth />
        )}
        style={{ flex: 1, maxWidth:'60%' }}
        renderOption={(props, option) => <MenuItem {...props} key={option.name}>{option.label}</MenuItem>}
      />
    );
  };

  render() {
    const { assessments, loading, selectedAssessment } = this.state;
    const { user } = this.props;

    // Step 1: Handle the loading state
    if (loading || !user.isLoaded || !user.user.email ) {
      return (
        <Layout>
          <div style={{ padding: '40px', textAlign: 'left' }}>
            <Typography sx={styles.subHeaderText}>
              Loading...
            </Typography>
          </div>
        </Layout>
      );
    }

    // Step 2: Main rendering logic
    return (
      <Layout>
        <div style={styles.root}>
          <Typography sx={styles.headerText}>
            Add New Test Results
          </Typography>

          <div style={styles.assessmentMenuDiv}>
            {this.renderAssessmentDropdown()}
          </div>

          {selectedAssessment && assessments.filter(assessment => assessment.name===selectedAssessment).map((assessment, index) => this.renderAssessmentGrid(assessment))}

          {this.renderAssessmentsList()}
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNavigateHook(AssessmentGrid));
