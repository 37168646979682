import { getDefaultHeaders } from './util';

// fetch all rating scales and corresponding measures for each form type
export const fetchRatingScaleDirectory= () => {
  return (dispatch, getState) => {
    dispatch({type: 'FETCH_RATING_SCALE_DIRECTORY_START'});

    let url = '/api/rating_scale_directory';
    return fetch(url, {
      headers: getDefaultHeaders(),
    })
      .then(res => res.json())
      .then(data => dispatch({type: 'FETCH_RATING_SCALE_DIRECTORY', data }));
  }
}
