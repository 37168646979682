import { combineReducers } from "redux";

import auth from "./auth";
import appendixData from "./appendixData";
import compositeDirectory from "./compositeDirectory";
import evaluation from "./evaluation";
import evaluationList from "./evaluationList";
import reportDownload from "./reportDownload";
import reportGeneration from './reportGeneration';
import reportInputs from './reportInputs';
import reportUpload from "./reportUpload";
import resetPassword from "./resetPassword";
import subtestDirectory from "./subtestDirectory";
import ratingScaleDirectory from "./ratingScaleDirectory";
import reportSection from './reportSection';
import user from "./user";

const naApp = combineReducers({
  auth,
  appendixData,
  compositeDirectory,
  evaluation,
  evaluationList,
  reportUpload,
  reportDownload,
  subtestDirectory,
  ratingScaleDirectory,
  reportGeneration,
  reportInputs,
  reportSection,
  resetPassword,
  user
});

//This allows us to clear the state when a user logs out
const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_START') {
    state = undefined;
  }
  return naApp(state, action);
}

export default rootReducer;
