import React, { Component } from "react";
import { connect } from "react-redux";

import withNavigateHook from '../../shared/withNavigateHook';

import Layout from '../../shared/Layout';

import { reportGeneration } from '../../actions';

import { logout } from '../../actions/auth';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { Checkbox, Dialog, DialogContent, DialogActions, FormControlLabel } from '@mui/material'


const styles = ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    textAlign: 'center',
    marginLeft: '350px',
    overflow: 'hidden',
    marginBottom: '36px'
  },
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  assessmentMenuDiv: {
    marginTop: '30px',
    display: 'flex',
    alignItems: 'center',
    width: '70%',
    marginLeft: '5%',
    marginRight:'5%',
    marginBottom:'5px'
  },
  subtestMenuDiv: {
    display: 'flex',
    alignText: 'center',
    marginBottom: '5px'
  },
  paper: {
    marginTop:'40px',
    marginLeft:'5%',
    marginRight:'5%',
    width: '70%',
    alignItems: 'center',
    display: 'inline-block'
  },
  submitCompletePaper: {
    marginTop: 32,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign:'center',
    padding: '16px 24px 24px',
    width: '400px',
    marginLeft:'auto',
    marginRight:'auto'
  },
  assessmentTitle: {
    textAlign: 'center',
    marginBottom: '5px',
    marginTop: '40px',
    fontFamily:'Raleway',
    fontSize: 18,
  },
  assessmentsAddedTitle: {
    textAlign: 'center',
    marginBottom: '5px',
    marginTop: '5px',
    fontFamily:'Raleway',
    fontSize: 18,
  },
  assessmentsAddedAssessment: {
    fontFamily:'Raleway',
    fontSize: 16,
  },
  assessmentsAddedSubtest: {
    fontFamily:'Raleway',
    fontSize: 16,
  },
  subtestTitle: {
    fontFamily:'Raleway',
    fontSize: 16,
    // marginRight: '10px'
  },
  subtestMeasureTitle: {
    fontFamily:'Raleway',
    fontSize: 16,
    // marginRight: '10px'
  },
  subtestRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10
  },
  addSubtestIcon: {
    marginLeft: '20px'
  },
  deleteButton: {
    marginLeft: 10
  },
  subtestDeleteButton: {
    marginLeft: 2
  },
  headerText: {
    textAlign: 'center',
    marginLeft: '25%',
    marginRight: '25%',
    marginTop: '20px',
    marginBottom: '24px',
    fontFamily:'Comfortaa',
    fontSize: 40,
    fontWeight: 200
  },
  subHeaderText: {
    textAlign: 'center',
    marginLeft: '25%',
    marginRight: '25%',
    marginTop: '20px',
    marginBottom: '24px',
    fontFamily:'Raleway',
    fontSize: 30,
    fontWeight: 200
  },
  redirectText: {
    fontFamily:'Raleway',
    fontSize: 18,
    marginBottom: '30px'
  },
  redirectButton: {
    marginTop: 8,
    background:'#E74C3C',
    textTransform:'none'
  },
  centeredSnackbar: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1400,
  },
  progressWrapper: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1400,
  },
  progress: {
    color: 'white',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1300,
  },
  stickyFooter: {
    position: 'fixed',
    bottom: 0,
    width: 'calc(100% - 350px)', //adjust for margin + padding
    backgroundColor: 'white',
    padding: '50px 20px',
    zIndex: 1000, // Ensure the footer is above other content
    display: 'flex',
    justifyContent: 'center', // Center the buttons horizontally
  },
});

class GeneratorFormReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appendixData:[],
      ratingScaleData: [],
      evaluationNoteData: [],
      submitted: false,
      loading: true,
      notificationOpen: false,
      notificationMessage: '',
      alertSeverity: '',
      isSubmitting: false,
      sessionExpired: false,
      openSubmitConfirmation: false,
      deidentifyChecked: false,
      outputUsageChecked: false
    };
  }

  componentDidMount() {
    this.setState({ sessionExpired: false, appendixData: this.props.assessmentData, ratingScaleData:this.props.ratingScaleData, evaluationNoteData:this.props.notes, loading: false });
  }

  componentDidUpdate(prevProps) {
    if (this.props.generating && !prevProps.generating) {
      console.log("ComponentDidUpdate - generation successfully queued, setting state:", this.props.message); // Add this line
      this.props.setGeneratingState(true)
      this.setState({
        notificationOpen: true,
        notificationMessage: this.props.message,
      });
    }
  }

  handleDeidentifyChange = (event) => {
    this.setState({ deidentifyChecked: event.target.checked });
  };

  handleOutputUsageChange = (event) => {
    this.setState({ outputUsageChecked: event.target.checked });
  };

  onCloseConfirmation = () => {
    this.setState({openSubmitConfirmation:false, outputUsageChecked:false, deidentifyChecked: false})
  }


  redirectToHome = () => {
    this.props.navigation('/home');
  }

  handleClickGenerate = () => {
    this.setState({openSubmitConfirmation: true})
  }
  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ submitted: true });

    //Submit all data
    try {
       await this.props.generateReport({evalId: this.props.evalId});
       this.setState({ submitted: true, notificationOpen: true, notificationMessage: 'Report generation in progress!', alertSeverity: "success" }, () => {
         localStorage.removeItem('phiFields');
         localStorage.removeItem('currentStep');
         localStorage.removeItem('selectedAssessment');
         localStorage.removeItem('openAssessment');
         localStorage.removeItem('selectedAssessmentData');
         localStorage.removeItem('selectedRatingScale');
         localStorage.removeItem('openScale');
         localStorage.removeItem('selectedRatingScaleData');
       });
     } catch (error) {
      if (error === 'Authentication error') {
        this.setState({ sessionExpired: true });
      }
      else {
        this.setState({ notificationOpen: true, notificationMessage: 'There was an error submitting your report. Please refresh the page and try again', alertSeverity:"error" });
      }
    } finally {
      this.setState({ isSubmitting: false, openSubmitConfirmation: false });
    }
  };

  redirectToLogin = () => {
    this.props.logout()
    this.props.navigation('/login')
  }

  uploadComplete = () =>
    this.state.submitted===true

  renderSubmitConfirmation = () => {
    const { openSubmitConfirmation, deidentifyChecked, outputUsageChecked } = this.state
    const canGenerate = deidentifyChecked && outputUsageChecked

    return (
      <Dialog
        open={openSubmitConfirmation}
        onClose={this.onCloseConfirmation}
        disableEscapeKeyDown={true}
        PaperProps={{
          style: {
            width: '60%', // Set the dialog to take up 50% of the screen width
            maxWidth: 'none', // Disable the default maxWidth
            marginLeft:'400px',
            overflow: 'hidden', // Ensure no overflow issues on the parent container
          },
        }}
      >
         <DialogContent
           style={{
             width: '100%',
             maxWidth: '100%', // Ensure content does not exceed the dialog width
             overflow: 'auto', // Handle any overflow within the dialog content
             padding: '20px', // Add some padding if needed
             boxSizing: 'border-box', // Include padding in the element's width and height
           }}
         >
           <Typography variant="h6" gutterBottom>
             By checking each of these boxes, I acknowledge and agree to the following:
           </Typography>
           <FormControlLabel
             control={
               <Checkbox
                 checked={deidentifyChecked}
                 onChange={this.handleDeidentifyChange}
                 color="primary"
               />
             }
             label={
               <Typography variant="body1">
                 De-Identification: I have personally reviewed the data I am submitting and ensure that it contains no Protected Health Information (PHI) or any other personally identifying information (PII). I understand that if such information remains in the data, I will indemnify and hold harmless neuroaide, Inc. (“neuroaide”) and its affiliates from any claims, losses, liabilities, or expenses arising from the presence of such information.
               </Typography>
             }
           />
           <FormControlLabel
              style={{marginTop:'15px'}}
             control={
               <Checkbox
                 checked={outputUsageChecked}
                 onChange={this.handleOutputUsageChange}
                 color="primary"
               />
             }
             label={
               <Typography variant="body1">
                 Report Output: I understand that the report generated by neuroaide is a draft report only and is intended to assist me in my professional practice. I will not distribute, share, or otherwise use the report without thorough review, editing, and professional judgment. I am solely responsible for the final report and any actions taken based on that report.
               </Typography>
             }
           />
         </DialogContent>
         <DialogActions>
           <Button onClick={this.onCloseConfirmation}>
             Cancel
           </Button>
           <Button
             onClick={this.handleSubmit}
             color="primary"
             variant="contained"
             disabled={!canGenerate} // Button is disabled until both checkboxes are checked
           >
             Submit
           </Button>
         </DialogActions>
       </Dialog>
    )
  }

  render() {
    const rootStyle = {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      textAlign: 'center',
      marginLeft: this.props.generating ? '0': '350px',
      overflow: 'hidden',
      marginBottom: '36px'
    }

    if (this.state.loading===true){
      return (
        <Layout>
          <div style={{ padding: '40px', textAlign:'left'}}>
            <Typography sx={styles.subHeaderText}>
              Loading...
            </Typography>
          </div>
        </Layout>
      )
    }

    return (
      <Layout skipLogout={this.state.sessionExpired}>
        {this.state.isSubmitting && (
          <>
            <div style={styles.overlay}></div>
            <div style={styles.progressWrapper}>
              <CircularProgress style={styles.progress} size={100} />
            </div>
          </>
        )}

        {this.renderSubmitConfirmation()}

        {this.state.sessionExpired && <div style={styles.overlay}></div>}
        <div style={rootStyle}>
          {this.uploadComplete() ? (
            <div style={styles.main}>
              <Paper sx={styles.submitCompletePaper}>
                <Typography sx={styles.redirectText}>
                  Thank You!
                  <br />
                  <br />
                  This Report will take approximately 10 to minutes to generate.
                  <br />
                  <br />
                  Once it is complete, it will be available for download on your Report Dashboard on the home page.
                </Typography>
                <div style={styles.redirectButton}>
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    onClick={this.redirectToHome}
                    styles={styles.redirectButton}
                  >
                    <Typography>
                      Return to homepage
                    </Typography>
                  </Button>
                </div>
              </Paper>
            </div>
          ) : (
            <>
              <Typography sx={styles.headerText} style={{ width: '80%', marginRight: '10%', marginLeft: '10%', marginBottom: '20px' }}>
                Review Your Information
              </Typography>
              <div style={{width: '80%', marginBottom: '40px', textAlign:'center'}}>
                <Paper elevation={3} style={{paddingBottom:'20px', marginBottom:'20px'}}>
                  <Typography sx={styles.subHeaderText}>
                    Assessment Results
                  </Typography>
                  {this.state.appendixData.map(assessment => (
                    <div>
                      <Typography sx={styles.assessmentTitle}>
                        {assessment.name}
                      </Typography>
                      <div style={{ width: '90%', marginLeft:'20px' }}>
                        {assessment.has_composites === true
                          && assessment.composites.filter(composite => composite.percentile || composite.outcome).length>0
                          && assessment.composites.filter(composite => composite.percentile || composite.outcome).map(composite =>
                          <Grid container key={composite.name} direction="row" justifyContent="flex-start" alignItems="center" spacing={1} sx={{ marginTop:1, marginBottom: 1 }}>
                            <Grid item xs={4}>
                              <Typography sx={styles.subtestTitle}>
                                {composite.name}
                              </Typography>
                            </Grid>
                            <React.Fragment>
                              <Grid item xs={2}>
                                <TextField
                                  label="T-score"
                                  variant="outlined"
                                  fullWidth
                                  value={composite.tScore || ''}
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <TextField
                                  label="Percentile"
                                  variant="outlined"
                                  fullWidth
                                  value={composite.percentile || ''}
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <TextField
                                  label="Other subtest outcome"
                                  variant="outlined"
                                  fullWidth
                                  value={composite.outcome || ''}
                                  disabled
                                />
                              </Grid>
                            </React.Fragment>
                          </Grid>
                        )}
                        {assessment.subtests.filter(subtest => subtest.percentile || subtest.outcome || subtest.measures.filter(measure => measure.percentile || measure.outcome).length>0).map(subtest => (
                            <Grid container key={subtest.name} direction="row" justifyContent="flex-start" alignItems="center" spacing={1} sx={{ marginTop:1, marginBottom: 1 }}>
                              <Grid item xs={4}>
                                {!(subtest.has_measures===true && subtest.name === assessment.name) &&
                                  <Typography sx={styles.subtestTitle}>
                                    {subtest.name}
                                  </Typography>
                                }
                              </Grid>
                              {subtest.has_measures===true && subtest.measures.map(measure => (
                                <React.Fragment key={measure.measureName}>
                                  <Grid container key={subtest.name} direction="row" justifyContent="flex-start" alignItems="center" spacing={1} sx={{ marginTop: .5, marginBottom: .5 }}>
                                    <Grid item xs={4}>
                                      <Typography style={styles.subtestMeasureTitle}>
                                        {measure.measureName}
                                      </Typography>
                                    </Grid>
                                      <Grid item xs={2}>
                                        <TextField
                                          label="T-score"
                                          variant="outlined"
                                          fullWidth
                                          value={measure.tScore || ''}
                                          disabled
                                        />
                                      </Grid>
                                      <Grid item xs={2}>
                                        <TextField
                                          label="Percentile"
                                          variant="outlined"
                                          fullWidth
                                          value={measure.percentile || ''}
                                          disabled
                                        />
                                      </Grid>
                                      <Grid item xs={3}>
                                        <TextField
                                          label="Other subtest outcome"
                                          variant="outlined"
                                          fullWidth
                                          value={measure.outcome || ''}
                                          disabled
                                        />
                                      </Grid>
                                    </Grid>
                                  </React.Fragment>
                              ))
                            }
                            {subtest.has_measures !== true && (
                              <React.Fragment>
                                <Grid item xs={2}>
                                  <TextField
                                    label="T-score"
                                    variant="outlined"
                                    fullWidth
                                    value={subtest.tScore || ''}
                                    disabled
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <TextField
                                    label="Percentile"
                                    variant="outlined"
                                    fullWidth
                                    value={subtest.percentile || ''}
                                    disabled
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <TextField
                                    label="Other subtest outcome"
                                    variant="outlined"
                                    fullWidth
                                    value={subtest.outcome || ''}
                                    disabled
                                  />
                                </Grid>

                              </React.Fragment>
                            )}
                          </Grid>
                        ))}
                      </div>
                    </div>
                  ))}
                </Paper>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  onClick={() => this.props.setCurrentStep('add-assessment-results')}
                >
                  Edit Assessment Results
                </Button>
              </div>
              <div style={{ width: '80%', marginBottom: '40px' }}>
                <Paper elevation={3} style={{ paddingBottom: '20px', marginBottom: '20px' }}>
                  <Typography sx={styles.subHeaderText}>
                    Rating Scale Results
                  </Typography>
                  {this.state.ratingScaleData.map(ratingScale => (
                    <div key={ratingScale.name}>
                      <Typography sx={styles.assessmentTitle}>
                        {ratingScale.name}
                      </Typography>
                      <div style={{ width: '90%', marginLeft: '20px', textAlign: 'center' }}>
                        {ratingScale.forms.map(form => (
                          <div key={form.rater} style={{ marginBottom: '50px' }}>
                            <Typography sx={styles.assessmentTitle}>
                              {form.rater} Form
                            </Typography>
                            {form.measures.map(measure => (
                              <Grid key={measure.name} container direction="row" justifyContent="flex-start" spacing={1} alignItems="center" sx={{ marginTop: 1, marginBottom: 1 }}>
                                <Grid item xs={4}>
                                  <Typography style={styles.subtestTitle}>{measure.name}: </Typography>
                                </Grid>
                                <Grid item>
                                  <TextField
                                    label="T-score"
                                    variant="outlined"
                                    fullWidth
                                    value={measure.tScore || ''}
                                    disabled
                                  />
                                </Grid>
                                <Grid item>
                                  <TextField
                                    label="Percentile"
                                    variant="outlined"
                                    fullWidth
                                    value={measure.percentile || ''}
                                    disabled
                                  />
                                </Grid>
                              </Grid>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </Paper>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  onClick={() => this.props.setCurrentStep('add-rating-scale-results')}
                >
                  Edit Rating Scale Results
                </Button>
              </div>
              <div style={{ width: '80%', marginBottom: '40px' }}>
                <Paper elevation={3} style={{ paddingBottom: '20px', marginBottom: '20px' }}>
                  <Typography sx={styles.subHeaderText}>
                    Report Sections & Notes
                  </Typography>
                  {this.state.evaluationNoteData.map(section => section.reportSectionTitle).map(noteSection => (
                    <div key={noteSection}>
                      <Typography sx={styles.assessmentTitle}>
                        {noteSection}
                      </Typography>
                      <TextField
                        multiline
                        value={this.state.evaluationNoteData.find(s => s.reportSectionTitle === noteSection).notes || ""}
                        style={{ width: '80%', marginLeft: '5%', marginRight: '5%', marginBottom: '20px' }}
                        disabled
                      />
                    </div>
                  ))}
                </Paper>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  style={{marginBottom: '75px'}}
                  onClick={() => this.props.setCurrentStep('add-evaluation-notes')}
                >
                  Edit Report Sections & Notes
                </Button>
              </div>
              <div style={styles.stickyFooter}>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  onClick={this.handleClickGenerate}
                >
                  Generate Report
                </Button>
              </div>
            </>
          )}
        </div>
        <Snackbar
          open={this.state.notificationOpen}
          autoHideDuration={8000}
          onClose={() => this.setState({ notificationOpen: false })}
        >
          <Alert onClose={() => this.setState({ notificationOpen: false })} severity={this.state.alertSeverity}>
            {this.state.notificationMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
          style={styles.centeredSnackbar}
          open={this.state.sessionExpired}
          onClose={() => this.setState({ sessionExpired: false })}
        >
          <Alert
            onClose={() => this.setState({ sessionExpired: false })}
            severity="warning"
            action={
              <Button color="inherit" size="small" onClick={() => this.redirectToLogin()}>
                Login
              </Button>
            }
          >
            Your session has expired. Do not worry, all of your information is stored! Click to log back in.
          </Alert>
        </Snackbar>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    generating: state.reportGeneration.generating,
    message: state.reportGeneration.message,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    generateReport: evalId => dispatch(reportGeneration.generateReport(evalId)),
    logout: () => dispatch(logout()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withNavigateHook(GeneratorFormReview));
