import React, { Component } from "react";
import { connect } from "react-redux";

import withNavigateHook from '../shared/withNavigateHook';

import { Navigate } from "react-router-dom";

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';

import Layout from '../shared/Layout'

const styles = ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    textAlign: 'center',
    overflow: 'hidden',
  },
  header: {
    marginLeft: '20%',
    marginRight: '20%',
    marginBottom: '24px',
    fontFamily:'Comfortaa',
    fontSize: 48,
    fontWeight: 200
  },
  headerTextDiv: {
    textAlign: 'center',
    marginTop: '20%',
    marginBottom: '20%'
  },
  headerText: {
    fontSize: 48,
    fontFamily:'Comfortaa',
  },
  startButton: {
    background:'#F8444F',
    textTransform: 'none',
    flex: '1 1 auto', // Grow, Shrink, Basis
    minWidth: '100px',
    width: '275px'
  },
  buttonText: {
    fontFamily:'Raleway',
    fontSize: 22,
  },
  startButtonDiv: {
    marginTop: '24px'
  },
});


class Home extends Component {

  showAdmin = () => {
    if (
      this.props.user &&
      this.props.user.isLoaded===true &&
      this.props.user.user.is_superuser===true
    ){
      return true
    }
    return false
  }

  userLoaded = () => {
    return (
      this.props.user &&
      this.props.user.isLoaded &&
      this.props.user.profile
    )
  }

  handleToReportGenerator = () => {
    this.props.navigation('/generatereport');
  }
  handleToReportUploader = () => {
    this.props.navigation('/uploadreport');
  }
  handleToReportDashboard = () => {
    this.props.navigation('/reportdashboard');
  }
  handleToAdminHome = () => {
    this.props.navigation('/adminhome');
  }

  render() {
      if (this.userLoaded()){
        if (this.props.user.profile.has_reset_password===false) {
          return <Navigate to='/set_password' />
        }
      }

      return(
        <Layout>
          <div style={styles.root}>
            <div style={styles.header}>
              <div style={styles.headerTextDiv}>
                <Typography style={styles.headerText}>
                  Welcome to neuroaide!
                </Typography>
              </div>
              <div style={styles.startButtonDiv}>
                <ButtonGroup
                  spacing="10px"
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    maxWidth: '100%',
                    gap: '2px'
                  }}
                >
                  { // TAB TO UPLOAD EXISTING REPORT
                    // <Button
                    //   type="submit"
                    //   variant="contained"
                    //   sx={styles.startButton}
                    //   onClick={this.handleToReportUploader}
                    // >
                    //   <Typography style={styles.buttonText}>
                    //     Upload Existing Report
                    //   </Typography>
                    // </Button>
                  }
                  <Button
                    type="submit"
                    variant="contained"
                    sx={styles.startButton}
                    onClick={this.handleToReportGenerator}
                  >
                    <Typography style={styles.buttonText}>
                      Generate New Report
                    </Typography>
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={styles.startButton}
                    onClick={this.handleToReportDashboard}
                  >
                    <Typography style={styles.buttonText}>
                      Report Dashboard
                    </Typography>
                  </Button>
                  {this.showAdmin() === true &&
                    <Button
                      type="submit"
                      variant="contained"
                      sx={styles.startButton}
                      onClick={this.handleToAdminHome}
                    >
                      <Typography style={styles.buttonText}>
                        Admin
                      </Typography>
                    </Button>
                  }
                </ButtonGroup>
              </div>
            </div>
          </div>
        </Layout>
      );
  }
};

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(withNavigateHook(Home));
