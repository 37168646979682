import React, { Component } from "react";
import { connect } from "react-redux";

import withNavigateHook from '../../shared/withNavigateHook';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';

import Layout from '../../shared/Layout'

const styles = ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    textAlign: 'center',
    overflow: 'hidden',
  },
  header: {
    marginLeft: '20%',
    marginRight: '20%',
    marginBottom: '24px',
    fontFamily:'Comfortaa',
    fontSize: 48,
    fontWeight: 200
  },
  headerTextDiv: {
    textAlign: 'center',
    marginTop: '20%',
    marginBottom: '20%'
  },
  headerText: {
    fontSize: 48,
    fontFamily:'Comfortaa',
  },
  startButton: {
    background:'#F8444F',
    textTransform: 'none',
    flex: '1 1 auto', // Grow, Shrink, Basis
    minWidth: '100px',
    width: '275px'
  },
  buttonText: {
    fontFamily:'Raleway',
    fontSize: 22,
  },
  startButtonDiv: {
    marginTop: '24px'
  }
});


class AdminHome extends Component {

  showDashboard = () => {
    if (
      this.props.user &&
      this.props.user.isLoaded===true &&
      this.props.user.user.is_superuser===true
    ){
      return true
    }
    return false
  }

  handleToAdminGenerator = () => {
    this.props.navigation('/adminassessmentuploader');
  }

  render() {
      return(
        <Layout>
          <div style={styles.root}>
            <div style={styles.header}>
              <div style={styles.headerTextDiv}>
                <Typography style={styles.headerText}>
                  Admin Tools
                </Typography>
              </div>
              <div style={styles.startButtonDiv}>
                <ButtonGroup
                  spacing="10px"
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    maxWidth: '100%',
                    gap: '2px'
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={styles.startButton}
                    onClick={this.handleToAdminGenerator}
                  >
                    <Typography style={styles.buttonText}>
                      Generate Report
                    </Typography>
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
        </Layout>
      );
  }
};

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(withNavigateHook(AdminHome));
