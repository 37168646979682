import { getDefaultHeaders } from './util';

export const uploadReport = report => {
  return (dispatch, getState) => {
    dispatch({ type: 'REPORT_UPLOAD_START' });
    let body = JSON.stringify(report);

    return fetch("/api/report_upload/", {
      headers: getDefaultHeaders(),
      method: "POST",
      body,
    })
    .then(res => {
      if (res.status === 200) {
          return res.json().then(data => {
            return dispatch({ type: 'REPORT_UPLOAD_SUCCESS', data });
          })
        } else if (res.status === 400) {
          res.json().then(data => {
            dispatch({ type: 'REPORT_UPLOAD_ERROR' });
          });
        } else {
          dispatch({ type: 'REPORT_UPLOAD_ERROR' });
        }
    });
  };
};
