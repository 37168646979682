const initialState = {
  loading: false,
  evals: {'evalList':[]}
};

export default function evaluationList(state=initialState, action) {
  switch (action.type) {
    case 'FETCH_EVALUATION_LIST_START':
      return {
        loading: true,
        evals: {'evalList':[]}
      };

    case 'FETCH_EVALUATION_LIST':
      return {
        loading: false,
        evals: action.data,
      };

    default:
      return state;
  }
}
