import { getDefaultHeaders } from './util';

// fetch users eval in prgoress if exists
export const fetchEvaluation = () => {
  return (dispatch, getState) => {
    dispatch({type: 'FETCH_EVALUATION_START'});

    let url = '/api/evaluation';
    return fetch(url, {
      headers: getDefaultHeaders(),
    })
    .then(res => {
      if (res.status === 200) {
          return res.json().then(data => {
            return dispatch({ type: 'FETCH_EVALUATION_SUCCESS', data: data });
          })
        } else if (res.status === 403 || res.status === 401) {
          dispatch({ type: 'AUTHENTICATION_ERROR' });
        } else {
          dispatch({ type: 'FETCH_EVALUATION_ERROR' });
        }
    });
  }
}

export const createEvaluation = evalData => {
  return (dispatch, getState) => {
    dispatch({ type: 'CREATE_EVALUATION_START' });
    let body = JSON.stringify(evalData);
    return fetch("/api/evaluation", {
      headers: getDefaultHeaders(),
      method: "POST",
      body
    })
    .then(res => {
      if (res.status === 200) {
          return res.json().then(data => {
            return dispatch({ type: 'CREATE_EVALUATION_SUCCESS', data: data });
          })
      } else if (res.status === 403 || res.status === 401) {
        dispatch({ type: 'AUTHENTICATION_ERROR' });
      } else {
        dispatch({ type: 'CREATE_EVALUATION_ERROR' });
      }
    });
  };
};

export const updateAssessmentData = evalData => {
  return (dispatch, getState) => {
    dispatch({ type: 'UPDATE_ASSESSMENTS_START' });
    let body = JSON.stringify(evalData);
    return fetch("/api/evaluation", {
      headers: getDefaultHeaders(),
      method: "PUT",
      body,
    })
    .then(res => {
      if (res.status === 200) {
        console.log("SUCCESSFULLY UPDATED ASSESSMENTS");
        return res.json().then(data => {
          return dispatch({ type: 'UPDATE_ASSESSMENTS_SUCCESS', data: data });
        })
      } else if (res.status === 403 || res.status === 401) {
        dispatch({ type: 'AUTHENTICATION_ERROR' });
      } else {
        dispatch({ type: 'UPDATE_ASSESSMENTS_ERROR' });
      }
    })
  };
};

export const updateRatingScaleData = evalData => {
  return (dispatch, getState) => {
    dispatch({ type: 'UPDATE_RATING_SCALE_START' });
    let body = JSON.stringify(evalData);
    return fetch("/api/evaluation", {
      headers: getDefaultHeaders(),
      method: "PUT",
      body,
    })
    .then(res => {
      if (res.status === 200) {
        console.log("SUCCESSFULLY UPDATED RATING SCALES");
        return res.json().then(data => {
          return dispatch({ type: 'UPDATE_RATING_SCALE_SUCCESS', data: data });
        })
      } else if (res.status === 403 || res.status === 401) {
        dispatch({ type: 'AUTHENTICATION_ERROR' });
      } else {
        dispatch({ type: 'UPDATE_RATING_SCALE_ERROR' });
      }
    })
  };
};

export const updateNotes = evalData => {
  return (dispatch, getState) => {
    dispatch({ type: 'UPDATE_NOTES_START' });
    let body = JSON.stringify(evalData);
    return fetch("/api/evaluation", {
      headers: getDefaultHeaders(),
      method: "PUT",
      body,
    })
    .then(res => {
      if (res.status === 200) {
        console.log("SUCCESSFULLY UPDATED NOTES");
        return res.json().then(data => {
          return dispatch({ type: 'UPDATE_NOTES_SUCCESS', data: data });
        })
      } else if (res.status === 403 || res.status === 401) {
        dispatch({ type: 'AUTHENTICATION_ERROR' });
      } else {
        dispatch({ type: 'UPDATE_NOTES_ERROR' });
      }
    })
  };
};

export const deleteAssessment = (evalId, assessmentName) => {
  return (dispatch, getState) => {
    dispatch({ type: 'DELETE_ASSESSMENT_START' });
    let body = JSON.stringify({evalId: evalId, assessmentName: assessmentName});
    return fetch("/api/evaluation", {
      headers: getDefaultHeaders(),
      method: "DELETE",
      body,
    })
    .then(res => {
      if (res.status === 200) {
        console.log("SUCCESSFULLY DELETED ASSESSMENT");
        return res.json().then(data => {
          return dispatch({ type: 'DELETE_ASSESSMENT_SUCCESS', data: data });
        })
      } else if (res.status === 403 || res.status === 401) {
        dispatch({ type: 'AUTHENTICATION_ERROR' });
      } else {
        dispatch({ type: 'DELETE_ASSESSMENT_ERROR' });
      }
    })
  };
};

export const deleteRatingScale = (evalId, ratingScaleName) => {
  return (dispatch, getState) => {
    dispatch({ type: 'DELETE_RATING_SCALE_START' });
    let body = JSON.stringify({evalId: evalId, ratingScaleName: ratingScaleName});
    return fetch("/api/evaluation", {
      headers: getDefaultHeaders(),
      method: "DELETE",
      body,
    })
    .then(res => {
      if (res.status === 200) {
        console.log("SUCCESSFULLY DELETED RATING SCALE");
        return res.json().then(data => {
          return dispatch({ type: 'DELETE_RATING_SCALE_SUCCESS', data: data });
        })
      } else if (res.status === 403 || res.status === 401) {
        dispatch({ type: 'AUTHENTICATION_ERROR' });
      } else {
        dispatch({ type: 'DELETE_RATING_SCALE_ERROR' });
      }
    })
  };
};

export const updateDemographicData = evalData => {
  return (dispatch, getState) => {
    dispatch({ type: 'UPDATE_DEMOGRAPHICS_START' });
    let body = JSON.stringify(evalData);
    return fetch("/api/evaluation", {
      headers: getDefaultHeaders(),
      method: "PUT",
      body,
    })
    .then(res => {
      if (res.status === 200) {
        console.log("SUCCESSFULLY UPDATED DEMOGRAPHICS");
        return res.json().then(data => {
          return dispatch({ type: 'UPDATE_DEMOGRAPHICS_SUCCESS', data: data });
        })
      } else if (res.status === 403 || res.status === 401) {
        dispatch({ type: 'AUTHENTICATION_ERROR' });
      } else {
        dispatch({ type: 'UPDATE_DEMOGRAPHICS_ERROR' });
      }
    })
  };
};
