import { getDefaultHeaders } from './util';

// fetch dict of report sections associated with the completed subtests
export const fetchSubtestsByReportSection = () => {
  return (dispatch, getState) => {
    dispatch({type: 'FETCH_SUBTESTS_BY_SECTION_START'});

    let url = '/api/subtests_by_section';
    return fetch(url, {
      headers: getDefaultHeaders(),
    })
      .then(res => res.json())
      .then(data => dispatch({type: 'FETCH_SUBTESTS_BY_SECTION', data }));
  }
}
