import React, { Component } from "react";
import { connect } from "react-redux";

import { evaluation, subtestDirectory, compositeDirectory, ratingScaleDirectory } from '../../actions';

import Layout from '../../shared/Layout'

import Sidebar from './Sidebar';
import StartReport from './StartReport';
import AssessmentGrid from './AssessmentGrid';
import RatingScaleUploader from './RatingScaleUploader';
import EvaluationNotesUploader from './EvaluationNotesUploader';
import GeneratorFormReview from './GeneratorFormReview';

import { Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogActions } from '@mui/material';

const styles = ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    textAlign: 'center',
    overflow: 'hidden',
  },
  headerText: {
    textAlign: 'left',
    marginLeft: '25%',
    marginRight: '25%',
    marginTop: '5%',
    marginBottom: '24px',
    fontFamily:'Comfortaa',
    fontSize: 48,
    fontWeight: 200
  },
  overview_txt:{
    textAlign: 'left',
    marginBottom: '1%',
    marginLeft: '25%',
    marginRight: '25%',
    color: '#25404E',
    fontFamily:'Comfortaa',
  },
  logoutButton: {
    marginTop: '20px',
    marginLeft: '75%',
    background:'#E74C3C',
  },
  startButton: {
    background:'#E74C3C',
  },
  buttonText: {
    fontFamily:'Comfortaa',
      fontSize: 18,
      fontWeight: 400
  },
  startButtonDiv: {
    marginTop: '24px'
  },
  layoutStyle: {
    display: 'flex',
  },
  contentStyle: {
    flex: 1,
    padding: '20px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  progressWrapper: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1400,
  },
});


class ReportGenerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 'demographics',
      nextStepPendingConfirmation: null,
      reportData: {
        evalType: '',
        age: '',
        sex: '',
        pronouns: '',
      },
      dataUpdating: false,
      notesSaved: true,
      displayNavigationConfirmation: false,
      loaded: false,
      generating: false
    };

    this.setCurrentStep = this.setCurrentStep.bind(this);
    this.setDemographicData = this.setDemographicData.bind(this);
    this.updateAssessmentData = this.updateAssessmentData.bind(this);
    this.deleteAssessment = this.deleteAssessment.bind(this);
    this.updateRatingScaleData = this.updateRatingScaleData.bind(this);
    this.deleteRatingScale = this.deleteRatingScale.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
    this.setNoteSaveState = this.setNoteSaveState.bind(this);
    this.setDisplayNavigationConfirmation = this.setDisplayNavigationConfirmation.bind(this);
    this.setGeneratingState = this.setGeneratingState.bind(this);
  };

  componentDidMount() {

    // Fetch eval
    this.props.fetchEvaluation();

    // Fetch directories
    this.props.fetchSubtestDirectory();
    this.props.fetchCompositeDirectory();
    this.props.fetchRatingScaleDirectory();

    const savedStep = localStorage.getItem('currentStep');
    if (savedStep) {
      this.setState({ currentStep: JSON.parse(savedStep) });
    }
  }

  componentDidUpdate(prevProps) {
    const { reportData, dataUpdating } = this.state
    const { evaluation, evalLoaded, createdEval, assessmentDataLoaded, ratingScaleDataLoaded, notesLoaded, demographicDataLoaded, subtestDirectoryLoaded, compositeDirectoryLoaded, ratingScaleDirectoryLoaded } = this.props
    if (evalLoaded && subtestDirectoryLoaded && compositeDirectoryLoaded && ratingScaleDirectoryLoaded && !this.state.loaded){
      if (evaluation.reportInProgress === true) {
        this.setState({ reportData: { ...reportData, age: evaluation.age, sex: evaluation.sex,  evalType: evaluation.evalType, pronouns: evaluation.pronouns || null } })
      }
      this.setState({loaded: true})
    }
    // after assessment data updates, refetch the eval
    if (this.state.loaded){
      if (!prevProps.createdEval && createdEval){
        this.setState({dataUpdating: false, loaded: false })
        this.props.fetchEvaluation()
      }
      if (!prevProps.demographicDataLoaded && demographicDataLoaded){
        this.setState({dataUpdating: false, loaded: false })
        this.props.fetchEvaluation()
      }
      if(!prevProps.assessmentDataLoaded && assessmentDataLoaded){
        // refetch evaluation after udpating
        if (dataUpdating === true){
          this.setState({dataUpdating: false, loaded: false})
          this.props.fetchEvaluation()
        }
      }
      if (!prevProps.ratingScaleDataLoaded && ratingScaleDataLoaded){
        // refetch evaluation after udpating
        if (dataUpdating === true){
          this.setState({dataUpdating: false, loaded: false})
          this.props.fetchEvaluation()
        }
      }
      if (!prevProps.notesLoaded && notesLoaded){
        // refetch evaluation after udpating
        if (dataUpdating === true){
          this.setState({dataUpdating: false, loaded: false})
          this.props.fetchEvaluation()
        }
      }
    }
  }

  saveCurrentStepToLocalStorage = () => {
    const { currentStep } = this.state;
    localStorage.setItem('currentStep', JSON.stringify(currentStep));

  }

  setCurrentStep(step) {
    this.setState({ currentStep: step }, this.saveCurrentStepToLocalStorage);
  }

  // send to data bakcend to add or update assessments
  updateAssessmentData(data) {
      // add evalid to data before sending to server if it exists
      if (this.props.evaluation.reportInProgress){
        const evalId = this.props.evaluation.evalId
        data['evalId'] = evalId
        this.props.updateAssessmentData(data)
      }

      this.setState({
        dataUpdating: true
       }
     );
  }

  // send to data bakcend to add or update scales
  updateRatingScaleData(data) {
      // add evalid to data before sending to server if it exists
      if (this.props.evaluation.reportInProgress){
        const evalId = this.props.evaluation.evalId
        data['evalId'] = evalId
        this.props.updateRatingScaleData(data)
      }

      this.setState({
        dataUpdating: true
       }
     );
  }

  updateNotes(data) {
      // add evalid to data before sending to server if it exists
      if (this.props.evaluation.reportInProgress){
        const evalId = this.props.evaluation.evalId
        data['evalId'] = evalId
        this.props.updateNotes(data)
      }

      this.setState({
        dataUpdating: true
       }
     );
  }

  // send to bakcend to delete an assessment
  deleteAssessment(assessmentName) {
    if (this.props.evaluation.reportInProgress){
      const evalId = this.props.evaluation.evalId
      this.props.deleteAssessment(evalId, assessmentName)
    }

    this.setState({
      dataUpdating: true
     }
   );
  }

  // send to bakcend to delete an rating scale
  deleteRatingScale(ratingScaleName) {
    if (this.props.evaluation.reportInProgress){
      const evalId = this.props.evaluation.evalId
      this.props.deleteRatingScale(evalId, ratingScaleName)
    }

    this.setState({
      dataUpdating: true
     }
   );
  }

  setDemographicData(data) {
    // add evalid to data before sending to server if it exists
    if (this.props.evaluation.reportInProgress){
      const evalId = this.props.evaluation.evalId
      data['evalId'] = evalId
      this.props.updateDemographicData(data)
    } else {
      this.props.createEvaluation(data)
    }

    this.setState({
      dataUpdating: true,
      loaded: false
     }
   );
  }

  setNoteSaveState(savedState) {
    this.setState({
      notesSaved: savedState
     }
   );
  }

  setGeneratingState(generating) {
    this.setState({
      generating: generating
     }
   );
  }

  readyToGenerate = () => {
    const { evaluation } = this.props
    return (
      evaluation.assessments.length > 0 &&
      (
        evaluation.sectionsAndNotes.some(s => s.reportSectionName==='Background') &&
        evaluation.sectionsAndNotes.some(s => s.reportSectionName==='Behavioral Observations')
      )
      && this.state.notesSaved
    )
  }

  setDisplayNavigationConfirmation = (nextStep) => {
    this.setState({ displayNavigationConfirmation: true, nextStepPendingConfirmation: nextStep })
  }

  closeNavigationDialog = () => {
    this.setState({displayNavigationConfirmation: false})
  }

  continueNavigation = () => {
    this.setState({ displayNavigationConfirmation: false, notesSaved: true, currentStep: this.state.nextStepPendingConfirmation })
  }

  renderNavigationDialog = () => {
    const { displayNavigationConfirmation } = this.state
    return (
      <Dialog
       open={displayNavigationConfirmation}
       onClose={this.closeNavigationDialog}
       aria-labelledby="confirm-navigation-dialog"
      >
       <DialogContent>
         <DialogContentText style={{color:'black', fontFamily:'Raleway'}}>
           You have unsaved notes that will be discarded. Do you want to save them before continuing?
         </DialogContentText>
       </DialogContent>
       <DialogActions>
         <Button onClick={this.closeNavigationDialog}>
           Back To Notes
         </Button>
         <Button onClick={this.continueNavigation} color="error">
           Discard & Continue Without Saving
         </Button>
       </DialogActions>
      </Dialog>
    )
  }

  renderStep() {
    const { currentStep, reportData } = this.state;
    const { subtestDirectory, compositeDirectory, ratingScaleDirectory, evaluation } = this.props

    if (this.props.evaluation.reportInProgress === true) {
      switch (currentStep) {
        case 'demographics':
          return <StartReport reportInProgress={this.props.evaluation.reportInProgress} reportData={reportData} setDemographicData={this.setDemographicData} setCurrentStep={this.setCurrentStep} />;
        case 'add-assessment-results':
          return <AssessmentGrid assessmentData={evaluation.assessments} updateAssessmentData={this.updateAssessmentData} deleteAssessment={this.deleteAssessment} subtestDirectory={subtestDirectory} compositeDirectory={compositeDirectory} />;
        case 'add-rating-scale-results':
          return <RatingScaleUploader ratingScaleData={evaluation.ratingScales} updateRatingScaleData={this.updateRatingScaleData} deleteRatingScale={this.deleteRatingScale} ratingScaleDirectory={ratingScaleDirectory} />;
        case 'add-evaluation-notes':
          return <EvaluationNotesUploader sectionsAndNotes={evaluation.sectionsAndNotes} updateNotes={this.updateNotes} setNoteSaveState={this.setNoteSaveState}/>;
        case 'review-and-generate':
          return <GeneratorFormReview setGeneratingState={this.setGeneratingState} setCurrentStep={this.setCurrentStep} evalId={this.props.evaluation.evalId} assessmentData={evaluation.assessments} ratingScaleData={evaluation.ratingScales} notes={evaluation.sectionsAndNotes}/>;
        default:
          return <AssessmentGrid assessmentData={evaluation.assessments} updateAssessmentData={this.updateAssessmentData} deleteAssessment={this.deleteAssessment} subtestDirectory={subtestDirectory} compositeDirectory={compositeDirectory}/>;
      }
    }
    else {
      return <StartReport reportInProgress={this.props.evaluation.reportInProgress} createEvaluation={this.props.createEvaluation} reportData={reportData} setDemographicData={this.setDemographicData} setCurrentStep={this.setCurrentStep}/>;
    }

  }

  render() {
    const { reportData, currentStep, loaded, dataUpdating, notesSaved } = this.state
    const { evalLoading } = this.props
    // Step 1: Handle the loading state
    if (evalLoading || !this.props.evaluation || !loaded || dataUpdating) {
      console.log('loading')
      return (
        <Layout>
          <div style={styles.progressWrapper}>
            <CircularProgress size={100} />
          </div>
        </Layout>
      );
    }
      return(
        <Layout>
          <div style={styles.layoutStyle}>
            {this.renderNavigationDialog()}
            {this.props.evaluation.reportInProgress === true && !this.state.generating &&
              <Sidebar
                setCurrentStep={this.setCurrentStep}
                currentStep={currentStep}
                reportData={reportData}
                notesSaved={notesSaved}
                setDisplayNavigationConfirmation={this.setDisplayNavigationConfirmation}
                readyToGenerate={this.readyToGenerate()}
              />
            }
            <div className="content" style={styles.contentStyle}>
              {this.renderStep()}
            </div>
          </div>
        </Layout>
      );
  }
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    evaluation: state.evaluation.evaluation,
    evalLoading: state.evaluation.loading,
    evalLoaded: state.evaluation.loaded,
    creatingEval: state.evaluation.creating,
    createdEval: state.evaluation.created,
    assessmentDataLoaded: state.evaluation.assessmentDataLoaded,
    ratingScaleDataLoaded: state.evaluation.ratingScaleDataLoaded,
    notesLoaded: state.evaluation.notesLoaded,
    demographicDataLoaded: state.evaluation.demographicDataLoaded,
    subtestDirectory: state.subtestDirectory.subtests,
    subtestDirectoryLoaded: state.subtestDirectory.loaded,
    compositeDirectory: state.compositeDirectory.composites,
    compositeDirectoryLoaded: state.compositeDirectory.loaded,
    ratingScaleDirectory: state.ratingScaleDirectory.ratingScales,
    ratingScaleDirectoryLoaded: state.ratingScaleDirectory.loaded,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    fetchEvaluation: () => {
      return dispatch(evaluation.fetchEvaluation());
    },
    createEvaluation: (data) => {
      return dispatch(evaluation.createEvaluation(data));
    },
    updateAssessmentData: (data) => {
      return dispatch(evaluation.updateAssessmentData(data));
    },
    deleteAssessment: (evalId, assessmentName) => {
      return dispatch(evaluation.deleteAssessment(evalId, assessmentName));
    },
    updateRatingScaleData: (data) => {
      return dispatch(evaluation.updateRatingScaleData(data));
    },
    deleteRatingScale: (evalId, ratingScaleName) => {
      return dispatch(evaluation.deleteRatingScale(evalId, ratingScaleName));
    },
    updateNotes: (data) => {
      return dispatch(evaluation.updateNotes(data));
    },
    updateDemographicData: (data) => {
      return dispatch(evaluation.updateDemographicData(data));
    },
    fetchSubtestDirectory: () => {
      return dispatch(subtestDirectory.fetchSubtestDirectory());
    },
    fetchCompositeDirectory: () => {
      return dispatch(compositeDirectory.fetchCompositeDirectory());
    },
    fetchRatingScaleDirectory: () => {
      return dispatch(ratingScaleDirectory.fetchRatingScaleDirectory());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportGenerator);
