const initialState = {
  uploaded: false,
  uploading: false,
  message: '',
  error: '',
};

export default function reportInputs(state = initialState, action) {
  switch (action.type) {
    case 'REPORT_INPUTS_UPLOAD_START':
      return { ...state, uploading: true, error: '' };

    case 'REPORT_INPUTS_UPLOAD_ERROR':
      return { ...state, uploading: false, error: action.error };

    case 'REPORT_INPUTS_UPLOAD_SUCCESS':
      console.log("Reducer received success action with message:", action.message); // Add this line
      return { ...state, uploaded: true, uploading: false, message: action.message };

    default:
      return state;
  }
}
