const initialState = {
  isLoading: false,
  isLoaded: false,
  user: null,
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case 'USER_LOADING':
      return {...state, isLoading: true};

    case 'USER_LOADED':
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        user: action.user,
        profile: action.user.profile
      };

    default:
      return state;
  }
}
