import { stringify } from 'qs';

export const getDefaultHeaders = () => ({
  'Content-Type': 'application/json',
  'Authorization': `Token ${localStorage.getItem("token")}`,
});

export const fetchWithQuery = (path, query, options) => {
  return fetch(`${path}?${stringify(query)}`, options);
};
