import { getDefaultHeaders } from './util';

export const login = (username, password) => {
  return (dispatch, getState) => {
    dispatch({type: 'LOGIN_LOADING' });
    let headers = {"Content-Type": "application/json"};
    let body = JSON.stringify({username, password});

    return fetch("/api/auth/login/", {headers, body, method: "POST"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          console.log("Server Error!");
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          localStorage.setItem("token", res.data.token);
          dispatch({type: 'LOGIN_SUCCESSFUL', data: res.data });
          return res.data;
        } else if (res.status === 403 || res.status === 401 || res.status === 400) {
          localStorage.removeItem("token");
          dispatch({type: "AUTHENTICATION_ERROR", data: res.data});
          return res.data;
        } else {
          localStorage.removeItem("token");
          dispatch({type: "LOGIN_FAILED", data: res.data});
          return res.data;
        }
      })
  }
}

export const logout = () => {
  return (dispatch, getState) => {
    const headers = getDefaultHeaders();

    localStorage.removeItem("token");
    dispatch({type: 'LOGOUT_START'});

    return fetch("/api/auth/logout/", {
      headers,
      body: "",
      method: "POST",
    })
      .then(res => {
        if (res.status === 204) {
          return {status: res.status, data: {}};
        } else if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          console.log("Server Error!");
          throw res;
        }
      })
  }
}
