import React, {Component} from "react";
import {connect} from "react-redux";
import { resetPassword } from '../../actions';

import { Navigate } from "react-router-dom";

import { Button, Typography, Paper, Input } from "@mui/material";

const styles = ({
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  paper: {
    marginTop: "15%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px 24px 24px',
    width: '400px',
    maxWidth: '80%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  form: {
    width: '100%',
    marginTop: 36,
    textAlign: 'center'
  },
  submit: {
    marginTop: 8,
    background: '#E74C3C',
    textTransform: 'none'
  },
  submitText: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    fontWeight: 400
  },
  errorText: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    color: '#E74C3C',
  },
  resetText: {
    fontFamily: 'Comfortaa',
    fontSize: 32,
    fontWeight: 200
  },
  resetPasswordButton: {
    textTransform: 'none',
    background:'#F8444F',
    minWidth: '100px',
    width: '350px'
  },
  resetPasswordTextDiv: {
    textAlign: 'center',
    marginTop: '20%',
    marginBottom: '50px'
  },
  resetPasswordButtonText: {
    fontFamily:'Raleway',
    fontSize: 18,
  },
});

class PasswordResetLandingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      message: null,
      redirect: false,
      submitted: false,
      error: null
    };
  }

  handleSubmit = async e => {
    e.preventDefault();
    try {
       await this.props.sendResetPasswordEmail(this.state.email);
       if (this.props.message){
         this.setState({message: this.props.message})
       }
       else if (this.props.errorMessage){
        this.setState({ error: this.props.errorMessage})
       }
    } catch(error) {
      this.setState({error: this.props.errorMessage})
    }
    finally {
      this.setState({submitted: true})
    }
  }

  renderMessage = () => {
    return (
      <div style = {{marginTop: '10px'}}>
        {this.state.error ? <p style={{color: 'red'}}>{this.state.error}</p>: this.state.message}
      </div>
    );
  }

  render() {
    if (this.state.redirect){
      return <Navigate to='/login' />
    }

    return (
      <main style={styles.main}>
        <Paper sx={styles.paper}>
          <Typography sx={styles.resetText}>Reset Password</Typography>
          {this.state.submitted === true && this.renderMessage()}
          <form style={styles.form} onSubmit={this.handleSubmit}>
            <label htmlFor="email"></label>
            <Input
              type="email"
              placeholder="Enter your email"
              value={this.state.email}
              onChange={e => this.setState({ email: e.target.value, error: null, submitted: false })}
              fullWidth={true}
              required
            />
            <Button
              type="submit"
              size="large"
              variant="contained"
              sx={styles.submit}
              disabled={!this.state.email || this.props.isLoading || this.state.error || this.state.submitted}
            >
              {this.props.isLoading ? (
                <Typography sx={styles.submitText}>Sending...</Typography>
              ) : (
                this.state.submitted? (
                  <Typography sx={styles.submitText}>Resend Link</Typography>
                ): (
                  <Typography sx={styles.submitText}>Send Reset Link</Typography>
                )
              )}
            </Button>
          </form>
        </Paper>
        <div style={{textAlign:'center'}}>
          <Button
            type="submit"
            size="large"
            variant="contained"
            sx={styles.submit}
            onClick={() => this.setState({redirect: true})}
          >
            <Typography sx={styles.submitText}>Return to Login</Typography>
          </Button>
        </div>
      </main>
    )
  }
}

const mapStateToProps = state => {
  return {
    sending: state.resetPassword.isLoading,
    message: state.resetPassword.message,
    errorMessage: state.resetPassword.error
  };
}

const mapDispatchToProps = dispatch => {
  return {
    sendResetPasswordEmail: (email) => dispatch(resetPassword.sendResetPasswordEmail(email)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetLandingPage);
