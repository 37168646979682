const initialState = {
  loading: false,
  loaded: false,
  ratingScales: {}
};

export default function ratingScaleDirectory(state=initialState, action) {
  switch (action.type) {
    case 'FETCH_RATING_SCALE_DIRECTORY_START':
      return {
        loading: true,
        ratingScales: {},
      };

    case 'FETCH_RATING_SCALE_DIRECTORY':
      return {
        loading: false,
        loaded: true,
        ratingScales: action.data,
      };

    default:
      return state;
  }
}
