import React, { Component } from "react";
import { connect } from "react-redux";

import withNavigateHook from '../../shared/withNavigateHook';
import { ratingScaleDirectory } from '../../actions';
import Layout from '../../shared/Layout';

import { RATING_SCALE_FORM_TYPES, RATER_FORM_TYPE_MAPPINGS } from '../../shared/constants'

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import { DataGrid } from '@mui/x-data-grid';

import Papa from 'papaparse';


const styles = ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    textAlign: 'center',
    overflow: 'hidden',
    marginBottom: '36px'
  },
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  assessmentMenuDiv: {
    marginTop: '30px',
    display: 'flex',
    alignItems: 'center',
    width: '70%',
    marginLeft: '5%',
    marginRight:'5%',
    marginBottom:'10px'
  },
  subtestMenuDiv: {
    display: 'flex',
    alignText: 'center',
    marginBottom: '10px',
    marginTop: '10px'
  },
  paper: {
    marginTop:'40px',
    marginLeft:'5%',
    marginRight:'5%',
    width: '70%',
    alignItems: 'center',
    display: 'inline-block'
  },
  assessmentTitle: {
    textAlign: 'center',
    marginBottom: '15px',
    marginTop: '15px',
    fontFamily:'Raleway',
    fontSize: 20,
    marginLeft: 10
  },
  assessmentsAddedTitle: {
    textAlign: 'center',
    marginBottom: '15px',
    marginTop: '5px',
    fontFamily:'Raleway',
    fontSize: 24,
  },
  assessmentsAddedAssessment: {
    fontFamily:'Raleway',
    fontSize: 16,
  },
  assessmentsAddedSubtest: {
    fontFamily:'Raleway',
    fontSize: 14,
  },
  subtestTitle: {
    fontFamily:'Raleway',
    fontSize: 16,
    // marginRight: '10px'
  },
  subtestRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10
  },
  addSubtestIcon: {
    marginLeft: '20px'
  },
  deleteButton: {
    marginLeft: 10
  },
  subtestDeleteButton: {
    marginLeft: 2
  },
  headerText: {
    textAlign: 'center',
    marginLeft: '25%',
    marginRight: '25%',
    marginTop: '20px',
    marginBottom: '24px',
    fontFamily:'Comfortaa',
    fontSize: 48,
    fontWeight: 200
  },
  gridContainer: {
    width: '80%',
    margin: '20px auto',
  },
  customHeader: {
    backgroundColor: '#f5f5f5',
    height: '60px',
    padding: '10px',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '16px'
  },
  columnHeader: {
    fontWeight: 'bold',
  },
  cellBorder: {
    border: '.5px solid rgba(224, 224, 224, 1)', // You can customize the border color and style as needed
  }
});


class AdminRatingScaleUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRatingScale: null,
      ratingScales: [],
      validationErrors: [],
      onFormReview: null,
      loading: true,
      dataLoaded: false,
    };
    this.handleFileUpload = this.handleFileUpload.bind(this);
  }

  componentDidMount() {
    const { user } = this.props;

    this.props.fetchRatingScaleDirectory();
    const savedRatingScales = localStorage.getItem('ratingScaleData');
    if (savedRatingScales) {
      this.setState({ ratingScales: JSON.parse(savedRatingScales) });
    }

    let onFormReview = localStorage.getItem('onFormReview')
    if (onFormReview){
      onFormReview = JSON.parse(onFormReview)
      this.setState({onFormReview: onFormReview})
    }

    // Check if user is loaded and if the user is a demo account
    if (user && user.isLoaded && user.user.email) {
      this.setState({ loading: false });
    }
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    // Check if user prop has changed
    if (prevProps.user !== user && user.isLoaded) {
      this.setState({ loading: false });
    }
  }

  handleFileUpload(event) {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const data = results.data;
          this.setState({ data }, () => {
            const parsedData = this.parseUploadData(data);
            this.setState({
              ratingScales: parsedData,
              selectedRatingScale: null,
              dataLoaded: true,
            }, this.saveRatingScalesToLocalStorage);
          })
        },
        skipEmptyLines: true,
      });
    }
  }

  parseUploadData(data) {
    const assessmentDataParsed = data.reduce((acc, row) => {
      const assessmentName = row.rating_scale_name;
      if (!assessmentName){
        return acc
      }

      if (!acc[assessmentName]) {
        acc[assessmentName] = {
          name: assessmentName,
          forms: [],
          form_ids: []
        };
      }

      if (!acc[assessmentName].form_ids.includes(row.form_id)) {
        acc[assessmentName].forms.push({rater: row.rater, form_type: row.form_type, measures: [], selectedForm: null})
        acc[assessmentName].form_ids.push(row.form_id)
      }
      const measureDict = {
        name: row.rating_measure_name,
        percentile: row.percentile,
        tScore: row.t_score
      }
      const formIndex = acc[assessmentName].forms.findIndex(f => (f.rater === row.rater) && (f.form_type === row.form_type))
      acc[assessmentName].forms[formIndex].measures.push(measureDict)
      return acc;
    }, {});

    const assessmentList = Object.values(assessmentDataParsed);
    return assessmentList
  }

  saveRatingScalesToLocalStorage = () => {
    const { ratingScales } = this.state;
    localStorage.setItem('ratingScaleDataValidated', JSON.stringify(this.formIsValid()))
    localStorage.setItem('ratingScaleData', JSON.stringify(ratingScales));
  }

  getFormOptions = (ratingScaleName) => {
    const formOptions = []
    RATING_SCALE_FORM_TYPES.forEach((formType) => {
      if (this.props.ratingScaleDirectory[ratingScaleName][formType]){
        Object.keys(RATER_FORM_TYPE_MAPPINGS).forEach(rater => {
          if (RATER_FORM_TYPE_MAPPINGS[rater] === formType){
            formOptions.push(rater)
          }
        })
      }
    })
    return formOptions
  }

  getEmptyMeasuresList = (ratingScaleName, formType) => {
    const measuresList = []
    const measures = this.props.ratingScaleDirectory[ratingScaleName][formType]
    measures.forEach((measure) => {
      const measureDict = {name: measure['measureName'], percentile: null, tScore: null}
      measuresList.push(measureDict)
    })

    return measuresList
  };

  handleRatingScaleChange = (event, newValue) => {
    this.setState({ selectedRatingScale: newValue });
  };

  handleFormChange = (ratingScaleName, value) => {
    const updatedRatingScales = this.state.ratingScales.map(ratingScale => {
      if (ratingScale.name === ratingScaleName) {
        return {
          ...ratingScale,
          selectedForm: value, // Update selectedSubtest within the assessment
        };
      }
      return ratingScale;
    });
    this.setState({ ratingScales: updatedRatingScales });
  };

  handleDeleteForm = (ratingScaleName, rater) => {
    const updatedRatingScales = this.state.ratingScales.map(ratingScale => {
      if (ratingScale.name === ratingScaleName) {
        return {
          ...ratingScale,
          forms: ratingScale.forms.filter(form => form.rater !== rater)
        };
      }
      return ratingScale;
    });
    this.setState({ ratingScales: updatedRatingScales }, this.saveRatingScalesToLocalStorage);
  };

  handleDeleteRatingScale = (ratingScaleName) => {
    const updatedRatingScales = this.state.ratingScales.filter(ratingScale => ratingScale.name !== ratingScaleName);
    this.setState({ ratingScales: updatedRatingScales }, this.saveRatingScalesToLocalStorage);
  };

  validateRatingScales = () => {
    const { ratingScales } = this.state;
    let validationErrors = []; // To store validation errors

    ratingScales.forEach((ratingScale) => {
      // check if valid rating scale name
      if (!this.props.ratingScaleDirectory[ratingScale.name]){
        validationErrors.push(`INVALID Rating Scale name "${ratingScale.name}"`);
      }
      else {
        if (ratingScale.forms.length === 0) {
          // If an assessment has no subtests added, log an error
          validationErrors.push(`Rating Scale "${ratingScales.name}" has no forms added. Please add at least one form or remove the Rating Scale.`);
        }

        ratingScale.forms.forEach(form => {
          // check if form type is valid for the rating scale
          let validFormType = true
          if (!Object.keys(this.props.ratingScaleDirectory[ratingScale.name]).includes(form.form_type)){
            validationErrors.push(`Invalid form type: ${form.form_type} for Rating Scale "${ratingScale.name}"`)
            validFormType = false
          }
          var formFilled = false
          form.measures.forEach(measure => {
            if (validFormType) {
              // check for invalid measure name
              const validMeasure = Object.values(this.props.ratingScaleDirectory[ratingScale.name]).some(form => form.includes(measure.name))
              if (validMeasure === false){
                validationErrors.push(`INVALID Measure "${measure.name}" for Rating Scale "${ratingScale.name}"`);
              }
              if (validMeasure===true && !this.props.ratingScaleDirectory[ratingScale.name][form.form_type].includes(measure.name)){
                validationErrors.push(`Measure "${measure.name}" does not exist for form type "${form.form_type}" in Rating Scale "${ratingScale.name}". However, the measure is included in another form type for this rating scale.`);
              }
              var percentileIsValid = false

              // validate percentile value
              if (measure.percentile !== null && measure.percentile !== '') {
                formFilled = true
                const percentile = parseFloat(measure.percentile);
                if (isNaN(percentile) || percentile < 0 || percentile >= 100) {
                  validationErrors.push(`Rating Scale "${ratingScale.name}" ("${form.rater}") has an invalid percentile value. Percentile must be a greater than or equal to 0 and less than 100.`);
                }
                else {
                  percentileIsValid = true
                }
              }

              //validate t-score value
              if (measure.tScore !== null && measure.tScore !== '') {
                formFilled = true
                const tScore = parseFloat(measure.tScore);
                if (isNaN(tScore)) {
                  validationErrors.push(`Rating Scale "${ratingScale.name}" ("${form.rater}"), measure "${measure.name}" has an invalid T-Score value. T-Score must a numberic value.`);
                }
              }

              // add error if percentile exists but no t-score
              if (percentileIsValid===true && (measure.tScore === null || measure.tScore === '')){
                validationErrors.push(`Rating Scale "${ratingScale.name}" ("${form.rater}"), measure "${measure.name}" needs a T-Score value.`);
              }
            }
          })
          if (formFilled===false){
            validationErrors.push(`Rating Scale "${ratingScale.name}" ("${form.rater}") is empty. Please fill in the T-Score values for at least one item or remove the form.`);
          }
        });
      }
    });
    return validationErrors;
  };

  handleSubmit = () => {
    const validationErrors = this.validateRatingScales();

    if (validationErrors.length > 0) {
      this.setState({ validationErrors });
      return;
    }

    this.setState({ validationErrors: [] }, () => {
      localStorage.setItem('ratingScaleDataValidated', true);
      if (this.state.onFormReview===true){
        return this.props.navigation('/generatereport/review');
      }
      this.props.navigation('/generatereport/notes');
    });
  };

  formIsValid = () => {
    const validationErrors = this.validateRatingScales();
    if (validationErrors.length === 0) {
      return true
    }

    return false
  }

  assessmentDataValidated = () => {
    const appendixDataValidated = localStorage.getItem('appendixDataValidated')
    if (appendixDataValidated) {
      const validated = JSON.parse(appendixDataValidated)
      if (validated===false){
        return false
      }
      else {
        return true
      }
    }
    return false
  }

  redirectToAppendix = () => {
    this.props.navigation('/generatereport/appendix');
  }

  processRowUpdate = (newRow, oldRow, name, rater) => {
    this.setState(prevState => {
      const updatedRatingScales = [...prevState.ratingScales];
      const updatedRatingScaleIndex = updatedRatingScales.findIndex(ratingScale =>
        ratingScale.name===name && (ratingScale.forms.some(form => form.rater === rater && form.measures.some(measure => measure.name === oldRow.testName)))
      );

      if (updatedRatingScaleIndex !== -1) {
        const updatedRatingScale = updatedRatingScales[updatedRatingScaleIndex];
        const formIndex = updatedRatingScale.forms.findIndex(form => form.rater === rater);

        if (formIndex !== -1) {
          const measureIndex = updatedRatingScale.forms[formIndex].measures.findIndex(measure => measure.name === oldRow.testName);
          if (measureIndex !== -1){
            // Update measure
            updatedRatingScale.forms[formIndex].measures[measureIndex] = {
              ...updatedRatingScale.forms[formIndex].measures[measureIndex],
              percentile: newRow.percentile,
              tScore: newRow.score,
            };
            updatedRatingScales[updatedRatingScaleIndex] = updatedRatingScale;
          }
        }
        return { ratingScales: updatedRatingScales };
      }}, this.saveRatingScalesToLocalStorage)
    return newRow;
  };

  getGridRowsByForm = (ratingScaleName, ratingScaleIndex) => {
    var selectedRatingScale = this.state.ratingScales[ratingScaleIndex];
    if (!selectedRatingScale) {
      if (Object.keys(this.props.ratingScaleDirectory).includes(ratingScaleName)) {
        selectedRatingScale = this.props.ratingScaleDirectory[ratingScaleName]
      }
      else {
        return []
      }
    }

    let rowsByForm = [];
    if (selectedRatingScale.forms.length === 0){
      return rowsByForm
    }

    selectedRatingScale.forms.forEach(form => {
      const formMeasureList = []
      form.measures.forEach(measure => {
        formMeasureList.push({
          id: `${ratingScaleName}-${form.rater}-${measure['name']}`,
          testName: measure['name'],
          score: measure['tScore'] || null,
          percentile: measure['percentile'] || null,
        })
      })
      rowsByForm.push(formMeasureList)
    })
    return rowsByForm;
  }

  renderRatingScaleGrid = (ratingScale, ratingScaleIndex) => {
    const { name } = ratingScale;
    const formRows = this.getGridRowsByForm(name, ratingScaleIndex);

    const headerRowHeight = 56;
    const rowHeight = 35; // Define the height of each row
    const gridHeights = []
    formRows.forEach(form => {
      const gridHeight = rowHeight*form.length + headerRowHeight
      gridHeights.push(gridHeight)
    })

    const columns = [
      {
        field: 'testName',
        headerName: 'Measure/Index',
        flex: 2,
      },
      { field: 'score', headerName: 'T-Score', editable: true, flex: 1 },
      { field: 'percentile', headerName: 'Percentile', editable: true, flex: 1 },
    ];

    return (

      <div style={styles.gridContainer}>
        <Paper elevation={3} style={{padding:'20px'}}>
          <div style={styles.customHeader}>
            <Typography sx={styles.assessmentTitle}>
              {name}
              <IconButton
                aria-label="delete Rating Scale"
                onClick={() => this.handleDeleteRatingScale(name)}
                sx={styles.deleteButton}
              >
                <DeleteIcon />
              </IconButton>
            </Typography>
          </div>
          {ratingScale.forms.map((form, index) => (
            <div key={form.rater}>
              <Typography sx={styles.assessmentTitle}>
                {form.form_type} Form {form.rater !== 'Self' && `(${form.rater})`}
                <IconButton
                  aria-label="delete Form"
                  onClick={() => this.handleDeleteForm(ratingScale.name, form.rater)}
                  sx={styles.deleteButton}
                >
                  <DeleteIcon />
                </IconButton>
              </Typography>

              <div style={{ height: gridHeights[index], width: '100%' }}>
                <DataGrid
                  columns={columns}
                  rows={formRows[index]}
                  processRowUpdate={(newRow, oldRow) => this.processRowUpdate(newRow, oldRow, name, form.rater)}
                  hideFooter
                  disableRowSelectionOnClick
                  disableColumnMenu
                  rowHeight={rowHeight}
                  sx={{
                    '& .MuiDataGrid-columnHeaderTitle': styles.columnHeader,
                    '& .MuiDataGrid-cell': styles.cellBorder,
                    '& .MuiDataGrid-row': {
                      borderBottom: '.5px solid rgba(224, 224, 224, 1)', // Add bottom border to each row
                    }
                  }}
                  componentsProps={{
                    cell: {
                      disableColumnMenu: true
                    },
                    columnMenu: null,
                  }}
                />
              </div>
            </div>
          ))}
        </Paper>
      </div>

    );
  };

  render() {
    if (this.assessmentDataValidated()!==true){
      this.redirectToAppendix()
    }

    const { ratingScales, dataLoaded } = this.state

    const { user } = this.props;

    // Step 1: Handle the loading state
    if (this.state.loading===true || !user.isLoaded || !user.user.email) {
      return (
        <Layout>
          <div style={{ padding: '40px', textAlign: 'left' }}>
            <Typography sx={styles.subHeaderText}>
              Loading...
            </Typography>
          </div>
        </Layout>
      );
    }

    return (
      <Layout>
        <div style={styles.root}>
            <Typography sx={styles.headerText}>
              Upload Rating Scales
            </Typography>
            <div style={{textAlign:'center', margin: '10%'}}>
              <input
                type="file"
                accept=".csv"
                onChange={this.handleFileUpload}
              />
            </div>
            {dataLoaded && ratingScales.map((ratingScale, index) => this.renderRatingScaleGrid(ratingScale, index))}
            <div style={{width:'100%'}}>
              {this.state.validationErrors.length > 0 && (
                <div style={{ color: 'red', marginTop:'30px', marginLeft:'15%', marginRight: '15%'}}>
                  Please fix the following errors before continuing:
                  <ul>
                    {this.state.validationErrors.map((error, index) => (
                      <li style={{textAlign:'left', marginBottom:'10px', fontSize:14}} key={index}>{error}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div style= {{width:'100%', textAlign:'center', marginTop: '20px'}}>
              <Button onClick={this.handleSubmit} variant="contained" color="primary"  size="large">
                {this.state.onFormReview===true ? "Finish reviewing":"Continue"}
              </Button>
            </div>
          </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    ratingScaleDirectory: state.ratingScaleDirectory.ratingScales,
    ratingScaleDirectoryLoading: state.ratingScaleDirectory.loading,
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchRatingScaleDirectory: () => {
      return dispatch(ratingScaleDirectory.fetchRatingScaleDirectory());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNavigateHook(AdminRatingScaleUploader));
