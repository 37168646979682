const initialState = {
  loading: false,
  reportSections: {}
};

export default function reportSection(state=initialState, action) {
  switch (action.type) {
    case 'FETCH_SUBTESTS_BY_SECTION_START':
      return {
        loading: true,
        reportSections: {},
      };

    case 'FETCH_SUBTESTS_BY_SECTION':
      return {
        loading: false,
        reportSections: action.data,
      };

    default:
      return state;
  }
}
