const initialState = {
  uploaded: false,
  uploading: false,
};

export default function reportUpload(state=initialState, action) {
  switch (action.type) {

    case 'REPORT_UPLOAD_START':
      return { ...state, uploading: true };

    case 'REPORT_UPLOAD_ERROR':
      return { ...state, uploading: false };

    case 'REPORT_UPLOAD_SUCCESS':
      return { ...state, uploaded: true, uploading: false };

    default:
      return state;
  }
}
