import { getDefaultHeaders } from './util';

// fetch all subtests/assessments
export const fetchEvaluationList= () => {
  return (dispatch, getState) => {
    dispatch({type: 'FETCH_EVALUATION_LIST_START'});

    let url = '/api/evaluation_list';
    return fetch(url, {
      headers: getDefaultHeaders(),
    })
      .then(res => res.json())
      .then(data => dispatch({type: 'FETCH_EVALUATION_LIST', data }));
  }
}
