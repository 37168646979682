const initialState = {
  loading: false,
  loaded: false,
  evaluation: null,
  demographicDataLoaded: null,
  assessmentDataLoaded: null,
  ratingScaleDataLoaded: null,
  notesLoaded:null
};

export default function evaluation(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_EVALUATION_START':
      return { ...state, loading: true, loaded: false };

    case 'FETCH_EVALUATION_SUCCESS':
      return {
        ...state,
        loading: false,
        loaded: true,
        demographicDataLoaded: true,
        assessmentDataLoaded: true,
        ratingScaleDataLoaded: true, 
        notesLoaded:true,
        evaluation: action.data
      };

    case 'CREATE_EVALUATION_START':
      return { ...state, created: false };

    case 'CREATE_EVALUATION_SUCCESS':
      return { ...state, created: true };

    case 'UPDATE_DEMOGRAPHICS_START':
      return { ...state, demographicDataLoaded: false };

    case 'UPDATE_DEMOGRAPHICS_SUCCESS':
      return { ...state, demographicDataLoaded: true };

    case 'UPDATE_ASSESSMENTS_START':
      return { ...state, assessmentDataLoaded: false };

    case 'UPDATE_ASSESSMENTS_SUCCESS':
      return { ...state, assessmentDataLoaded: true};

    case 'DELETE_ASSESSMENT_START':
      return { ...state, assessmentDataLoaded: false };

    case 'DELETE_ASSESSMENT_SUCCESS':
      return { ...state, assessmentDataLoaded: true };

    case 'UPDATE_RATING_SCALE_START':
      return { ...state, ratingScaleDataLoaded: false };

    case 'UPDATE_RATING_SCALE_SUCCESS':
      return { ...state, ratingScaleDataLoaded: true};

    case 'DELETE_RATING_SCALE_START':
      return { ...state, ratingScaleDataLoaded: false };

    case 'DELETE_RATING_SCALE_SUCCESS':
      return { ...state, ratingScaleDataLoaded: true };

    case 'UPDATE_NOTES_START':
      return { ...state, notesLoaded: false };

    case 'UPDATE_NOTES_SUCCESS':
      return { ...state, notesLoaded: true};

    default:
      return state;
  }
}
