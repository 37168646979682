import { Document, Packer, Paragraph, TextRun} from 'docx';

import { getCognitiveTestingResults } from './formatAppendix'

import { saveAs } from 'file-saver';

const removeSectionHeaderAndEmptyLines = (section, sectionName)  => {
  // Split the text into an array of lines
  let lines = section.split('\n');

  // Find the index of the first non-empty line
  const firstNonEmptyLineIndex = lines.findIndex(line => line.trim() !== '');

  // Find the index of the last non-empty line
  const lastNonEmptyLineIndex = lines.slice().reverse().findIndex(line => line.trim() !== '');
  const actualLastNonEmptyLineIndex = lines.length - 1 - lastNonEmptyLineIndex;

  // Check if there's any non-empty line
  if (firstNonEmptyLineIndex === -1) return '';

  // Find the index of the line containing the textToRemove, where line length < 10
  const indexOfTextToRemove = lines.findIndex(line => line.includes(sectionName) && (line.trim().split(/\s+/).length < 10));

  // If sectionName is found, remove that line and subsequent empty lines
  if (indexOfTextToRemove !== -1) {
    // Remove the sectionName line
    lines.splice(indexOfTextToRemove, 1);

    // Remove empty lines immediately after the sectionName line, if any
    while (lines[indexOfTextToRemove] && lines[indexOfTextToRemove].trim() === '') {
      lines.splice(indexOfTextToRemove, 1);
    }
  }
  // Remove empty lines at the start and end
  const trimmedLines = lines.slice(firstNonEmptyLineIndex, actualLastNonEmptyLineIndex + 1);

  // Remove the first line and the empty line below it if the word count is less than 10
  const firstLineWords = trimmedLines[0].trim().split(/\s+/).length;
  if (firstLineWords < 10){
    trimmedLines.splice(0, 1);
  }
  if (trimmedLines.length > 0) {
    if (trimmedLines[0].trim() === '') {
      trimmedLines.splice(0, 1);
    }
  }
  // Rejoin the array back into a string
  return trimmedLines;
}

export const createReport = (evalId, reportData, subtestsBySection) => {
  let appendixData = null;
  if ('cognitiveTestResults' in reportData){
    appendixData = reportData['cognitiveTestResults']
    delete reportData['cognitiveTestResults']
  }
  const docContent = []
  Object.keys(reportData).forEach(section => {
    const sectionText = removeSectionHeaderAndEmptyLines(reportData[section], section)
    const sectionHeader = new Paragraph({
      children: [
        new TextRun({
          text: section,
          font: 'Arial',
          bold: true,
          size: 22
        }),
      ],
    })
    docContent.push(sectionHeader)
    sectionText.forEach(paragraph => {
      const sectionContent = new Paragraph({
        children: [
          new TextRun({
            text: paragraph,
            font: 'Arial',
            size: 22
          }),
        ],
      })

      docContent.push(sectionContent)
    })

    if ((['General Cognitive Abilities', 'Intellectual Functioning'].includes(section)) && appendixData !== null){
      const cognitiveTestResultTables = getCognitiveTestingResults(appendixData, subtestsBySection)
      cognitiveTestResultTables.forEach(table => {
        docContent.push(new Paragraph({}))
        docContent.push(table)
      })
    }
    docContent.push(new Paragraph({}))
  })

  const doc = new Document({
      sections: [
          {
            children: [
                ...docContent
            ],
          },
      ],
  });

  const filename = "neuroaide_eval_" + evalId.toString() + "_report.docx"

  Packer.toBlob(doc).then(blob => {
    saveAs(blob, filename);
  }).catch(error => {
    console.error("Error creating document:", error);
  });
};
