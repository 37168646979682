import React, {Component} from "react";

import {connect} from "react-redux";

import { resetPassword } from '../../actions';

import { Navigate } from "react-router-dom";

import { Button, TextField, Typography, Paper, Box } from "@mui/material";


class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      error: "",
      message: "",
      uidb64: '',
      token: '',
      resetSuccessful: false,
      redirect: false
    };

    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.resetSuccessful !== prevProps.resetSuccessful){
      this.setState({resetSuccessful: this.props.resetSuccessful})

      // Log user out if reset successful
      localStorage.removeItem("token");

    }
  }

  componentDidMount() {
    // Extract the URL path using window.location.pathname
    const path = window.location.pathname;

    // Split the path to get the uidb64 and token
    const parts = path.split('/');
    const uidb64 = parts[2]; // Extract the uidb64 (e.g., 'MTA1')
    const token = parts[3];  // Extract the token (e.g., '345ty234kl2j5y234o5p2345km6')

    // Update the state with uidb64 and token
    this.setState({ uidb64, token });
  }

  handleSubmit = (e) => {
   e.preventDefault();

   const { password, confirmPassword, uidb64, token } = this.state;

   // Check if passwords match
   if (password !== confirmPassword) {
     this.setState({ error: "Passwords do not match" });
     return;
   }

   // Validate the password
   const passwordError = this.validatePassword(password);
   if (passwordError) {
     this.setState({ error: passwordError });
     return;
   }

   this.props.resetPassword(uidb64, token, password)
 };

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleConfirmPasswordChange(event) {
    this.setState({ confirmPassword: event.target.value });
  }

  // Password validation logic
  validatePassword(password) {
    // Define the password constraints
    const isLongEnough = password.length >= 8; // At least 8 characters
    const isTooLong = password.length > 128;
    const hasUpperCase = /[A-Z]/.test(password); // At least one uppercase letter
    const hasLowerCase = /[a-z]/.test(password); // At least one lowercase letter
    const hasNumber = /[0-9]/.test(password); // At least one number
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password); // At least one special character
    const hasSpaces = password.includes(' ')

    if (!isLongEnough) return "Password must be at least 8 characters long.";
    if (isTooLong) return "Password must be less than 128 characters long.";
    if (!hasUpperCase) return "Password must contain at least one uppercase letter.";
    if (!hasLowerCase) return "Password must contain at least one lowercase letter.";
    if (!hasNumber) return "Password must contain at least one number.";
    if (!hasSpecialChar) return "Password must contain at least one special character.";
    if (hasSpaces) return "Password cannot contain spaces.";

    return null; // Password is valid
  }

  handleRedirect = () => {
    this.setState({redirect: true})
  }

  render() {
    const { password, confirmPassword, error, resetSuccessful, redirect } = this.state;
    const { resetError } = this.props

    if (redirect){
      return <Navigate to='/login' />
    }

    if (resetError){
      console.log(this.state)
      console.log(this.props)
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Paper elevation={3} style={{ padding: "40px", textAlign: "center", width: "400px" }}>
            <Typography style={{ marginBottom: "20px", fontFamily: 'Raleway' }}>
              {resetError}
            </Typography>
            <Button onClick={this.handleRedirect} type="submit" variant="contained" color="primary">
              Continue to login
            </Button>
          </Paper>
        </div>
      )
    }

    if (resetSuccessful){
      console.log(this.state)
      console.log(this.props)
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Paper elevation={3} style={{ padding: "40px", textAlign: "center", width: "400px" }}>
            <Typography variant="h4" style={{ marginBottom: "20px", fontFamily: 'Raleway' }}>
              Password successfully reset!
            </Typography>
            <Button onClick={this.handleRedirect} type="submit" variant="contained" color="primary">
              Continue to login
            </Button>
          </Paper>
        </div>
      )
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Paper elevation={3} style={{ padding: "40px", textAlign: "center", width: "400px" }}>
          <Typography variant="h4" style={{ marginBottom: "20px", fontFamily: 'Raleway' }}>
            neuroaide
          </Typography>

          <form onSubmit={this.handlePasswordReset}>
            <Box mb={2}>
              <TextField
                label="New Password"
                type="password"
                fullWidth
                value={password}
                onChange={this.handlePasswordChange}
              />
            </Box>
            <Box mb={2}>
              <TextField
                label="Confirm Password"
                type="password"
                fullWidth
                value={confirmPassword}
                onChange={this.handleConfirmPasswordChange}
              />
            </Box>

            {error && <Typography color="error">{error}</Typography>}

            <Box mt={2}>
              <Button onClick={this.handleSubmit} type="submit" variant="contained" color="primary">
                Reset Password
              </Button>
            </Box>
          </form>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    resetSuccessful: state.resetPassword.resetSuccessful,
    resetError: state.resetPassword.error,
    isLoading: state.resetPassword.isLoading,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    resetPassword: (uidb64, token, new_password) => {
      return dispatch(resetPassword.resetPassword(uidb64, token, new_password));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
