import { getDefaultHeaders } from './util';

export const generateReport = evalId => {
  return async (dispatch, getState) => {
    dispatch({ type: 'GENERATE_REPORT_START' });

    let body = JSON.stringify(evalId);

    try {
      const res = await fetch("/api/generate_report", {
        headers: getDefaultHeaders(),
        method: "POST",
        body,
      });

      if (res.status === 200) {
        const data = await res.json();
        console.log("Dispatching success with message:", data.message); // Add this line
        dispatch({ type: 'GENERATE_REPORT_SUCCESS', message: data.message });
      } else if (res.status === 401) {
        dispatch({ type: 'GENERATE_REPORT_ERROR', error: 'Authentication error' });
        return Promise.reject('Authentication error');
      } else if (res.status === 400) {
        dispatch({ type: 'GENERATE_REPORT_ERROR', error: 'Server error' });
        return Promise.reject('Server error');
      } else {
        dispatch({ type: 'GENERATE_REPORT_ERROR', error: 'Unknown error' });
        return Promise.reject('Unknown error');
      }
    } catch (error) {
      dispatch({ type: 'GENERATE_REPORT_ERROR', error: 'Network error' });
      return Promise.reject('Network error');
    }
  };
};
