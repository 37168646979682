const initialState = {
  submitting: false,
  generating: false,
  message: '',
  error: '',
};

export default function generateReport(state = initialState, action) {
  switch (action.type) {
    case 'GENERATE_REPORT_START':
      return { ...state, submitting: true, error: '' };

    case 'GENERATE_REPORT_ERROR':
      return { ...state, submitting: false, error: action.error };

    case 'GENERATE_REPORT_SUCCESS':
      console.log("Reducer received success action with message:", action.message); // Add this line
      return { ...state, generating: true, submitting: false, message: action.message };

    default:
      return state;
  }
}
