import React, { useState } from 'react';

const Sidebar = ({ setCurrentStep, currentStep, reportData, notesSaved, setDisplayNavigationConfirmation, readyToGenerate }) => {
  const [hoveredStep, setHoveredStep] = useState(null);

  const sidebarStyle = {
    paddingTop: '100px',
    width: '350px',
    backgroundColor: '#fafafa',
    padding: '10px',
    boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
    height: 'calc(100vh - 64px)', // Full height of the viewport
    position: 'fixed',
    top: '64px', // Stick to the top of the viewport
    zIndex: 1200,
    bottom: 0,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between', // This ensures the content is spaced out with space at the bottom
  };

  const menuItemStyle = (step) => {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding:'10px 20px',
      cursor: 'pointer',
      backgroundColor: currentStep === step ? '#ededed': (hoveredStep === step ? '#f1f1f1' : 'transparent'),
      transition: 'background-color 0.3s, box-shadow 0.3s',
    }
  }

  const textContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
  };

  const generateButtonStyle = {
    marginTop: 'auto',
    marginBottom: '50px',
    backgroundColor: currentStep === 'review-and-generate' ? '#C0392B' : '#E74C3C', // Darker shade if selected
    color: '#fff',
    paddingTop: '15px',
    paddingBottom: '15px',
    borderRadius: '20px',
    textAlign: 'center',
    cursor: readyToGenerate ? 'pointer' : 'not-allowed',
    opacity: readyToGenerate ? 1 : 0.6,
    transition: 'background-color 0.3s, opacity 0.3s',
    ...(readyToGenerate && hoveredStep === 'review-and-generate' && currentStep !== 'review-and-generate' && {
      backgroundColor: '#C0392B', // Highlight on hover if ready and not already selected
    })
  };


  return (
    <div className="sidebar" style={sidebarStyle}>
      <div>
        <div style={{marginTop: '10%'}}/>
        <div
          style={menuItemStyle('demographics')}
          onClick={() => notesSaved ? setCurrentStep('demographics'): setDisplayNavigationConfirmation('demographics')}
          onMouseEnter={() => setHoveredStep('demographics')}
          onMouseLeave={() => setHoveredStep(null)}
        >
          <div style={textContainerStyle}>
            <p style={{ margin:0, fontWeight: 'bold' }}>{reportData.evalType} evaluation</p>
            <p style={{ margin:0 }}>{reportData.sex} {reportData.age} years old</p>
          </div>
            <p style={{ marginLeft: 'auto', marginRight: '5%', color: '#2a2a2a', cursor: 'pointer', textDecoration: hoveredStep==='demographics' ?  'underline':'none' }}>
              Edit
            </p>
        </div>
        <div
          style={menuItemStyle('add-assessment-results')}
          onClick={() => notesSaved ? setCurrentStep('add-assessment-results'): setDisplayNavigationConfirmation('add-assessment-results')}
          onMouseEnter={() => setHoveredStep('add-assessment-results')}
          onMouseLeave={() => setHoveredStep(null)}
        >
          <div style={textContainerStyle}>
            <p style={{fontWeight: 'bold'}}>Test Results</p>
          </div>
          <p style={{ marginLeft: 'auto', marginRight: '5%', color: '#E74C3C', cursor: 'pointer', textDecoration: hoveredStep==='add-assessment-results' ? 'underline':'none' }}>
            + Add test
          </p>
        </div>
        <div
          style={menuItemStyle('add-rating-scale-results')}
          onClick={() => notesSaved ? setCurrentStep('add-rating-scale-results'): setDisplayNavigationConfirmation('add-rating-scale-results')}
          onMouseEnter={() => setHoveredStep('add-rating-scale-results')}
          onMouseLeave={() => setHoveredStep(null)}
        >
          <div style={textContainerStyle}>
            <p style={{fontWeight: 'bold'}}>Rating Scale Results</p>
          </div>
          <p style={{ marginLeft: 'auto', marginRight: '5%', color: '#E74C3C', cursor: 'pointer', textDecoration: hoveredStep==='add-rating-scale-results' ? 'underline':'none' }}>
            + Add scale
          </p>
        </div>
        <div
          style={menuItemStyle('add-evaluation-notes')}
          onClick={() => setCurrentStep('add-evaluation-notes')}
          onMouseEnter={() => setHoveredStep('add-evaluation-notes')}
          onMouseLeave={() => setHoveredStep(null)}
        >
          <div style={textContainerStyle}>
            <p style={{fontWeight: 'bold'}}>Evaluation Notes</p>
          </div>
          <p style={{ marginLeft: 'auto', marginRight: '5%', color: '#2a2a2a', cursor: 'pointer', textDecoration: hoveredStep==='add-evaluation-notes' ? 'underline':'none' }}>
            Edit
          </p>
        </div>
      </div>
      <div
        style={generateButtonStyle}
        onClick={() => {
          if (readyToGenerate) {
            setCurrentStep('review-and-generate');
          }
        }}
        onMouseEnter={() => setHoveredStep('review-and-generate')}
        onMouseLeave={() => setHoveredStep(null)}
      >
        Review and Generate
      </div>
    </div>
  );
};

export default Sidebar;
